import React, { useState, useMemo, useEffect } from 'react'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { useForm } from 'react-hook-form'
import { useRouteLoaderData } from 'react-router-dom'
import TextField from 'src/app/components/form-inputs/TextField'
import SelectField from 'src/app/components/form-inputs/SelectField'
import PhoneField from 'src/app/components/form-inputs/PhoneField'
import countryList from 'react-select-country-list'
import EmailField from 'src/app/components/form-inputs/EmailField'
import ImageField from 'src/app/components/form-inputs/ImageField'
import CheckField from 'src/app/components/form-inputs/CheckField'
import { Can } from 'src/app/routing/AppRoutes'


export const Preferences: React.FC = ({ viewOnly, user, updateMutation }) => {
    const [loading, setLoading] = useState(false)
    const form = useForm({
        mode: 'all',
        defaultValues: user
    })

    const { handleSubmit, reset } = form

    const [update, result] = updateMutation()

    const submit = (data) => {
        setLoading(true)
        update(data)
    }

    useEffect(() => {
        setLoading(false)
    }, [result]);

    const countries = useMemo(() => countryList().getData(), [])

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Preferences</h3>
                </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={handleSubmit((data) => submit(data))} noValidate className='form'>
                    <div className='card-body border-top p-9'>


                        <div className='row mb-6'>
                            <label className='col-lg-5 col-form-label fw-bold fs-6'>Job Type</label>

                            <div className='col-lg-7 fv-row'>
                                <TextField form={form}
                                    fieldName={'jobType'}
                                    label='Full time' hideLabel={true} newVersion={true} disabled={viewOnly} className='form-control form-control-lg form-control-solid mb-3 mb-lg-0' />
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-5 col-form-label fw-bold fs-6'>Job Function</label>

                            <div className='col-lg-7 fv-row'>
                                <TextField form={form}
                                    fieldName={'jobFunction'}
                                    label='Software, Product' hideLabel={true} newVersion={true} disabled={viewOnly} className='form-control form-control-lg form-control-solid mb-3 mb-lg-0' />
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-5 col-form-label fw-bold fs-6'>Company industry</label>

                            <div className='col-lg-7 fv-row'>
                                <TextField form={form}
                                    fieldName={'companyIndustry'}
                                    label='All' hideLabel={true} newVersion={true} disabled={viewOnly} className='form-control form-control-lg form-control-solid mb-3 mb-lg-0' />
                            </div>
                        </div>



                        <div className='row mb-6'>
                            <label className='col-lg-5 col-form-label fw-bold fs-6'>Salary Range</label>

                            <div className='col-lg-7'>
                                <div className='row'>
                                    <div className='col-lg-6 fv-row'>
                                        <TextField form={form}
                                            registerOptions={{
                                                min: {
                                                    value: 0,
                                                    message: 'Min: 0'
                                                }
                                            }}
                                            fieldName={'minSalary'}
                                            label='Minimum Salary' hideLabel={true} newVersion={true} disabled={viewOnly} className='form-control form-control-lg form-control-solid mb-3 mb-lg-0' />
                                    </div>

                                    <div className='col-lg-6 fv-row'>
                                        <TextField form={form}
                                            fieldName={'optimalSalary'}
                                            label='Optimal Salary' hideLabel={true} newVersion={true} disabled={viewOnly} className='form-control form-control-lg form-control-solid' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-5 col-form-label fw-bold fs-6'>Preferred Location</label>

                            <div className='col-lg-7 fv-row'>
                                <TextField form={form}
                                    fieldName={'preferredLocation'}
                                    label='Tel Aviv + 30 minutes drive' hideLabel={true} newVersion={true} disabled={viewOnly} className='form-control form-control-lg form-control-solid mb-3 mb-lg-0' />
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-5 col-form-label fw-bold fs-6'>Work Environment</label>

                            <div className='col-lg-7 fv-row'>
                                <TextField form={form}
                                    fieldName={'workEnvironment'}
                                    label='Hybrid' hideLabel={true} newVersion={true} disabled={viewOnly} className='form-control form-control-lg form-control-solid mb-3 mb-lg-0' />
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-5 col-form-label fw-bold fs-6'>Career Goals</label>

                            <div className='col-lg-7 fv-row'>
                                <TextField form={form}
                                    fieldName={'careerGoals'}
                                    label='Skill development, Work-Life Balance' hideLabel={true} newVersion={true} disabled={viewOnly} className='form-control form-control-lg form-control-solid mb-3 mb-lg-0' />
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-5 col-form-label fw-bold fs-6'>Work Schedule</label>

                            <div className='col-lg-7 fv-row'>
                                <TextField form={form}
                                    fieldName={'workSchedule'}
                                    label='Regular hours ' hideLabel={true} newVersion={true} disabled={viewOnly} className='form-control form-control-lg form-control-solid mb-3 mb-lg-0' />
                            </div>
                        </div>

                        
                    </div>

                    {!viewOnly && <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Save Changes'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>}
                </form>
            </div>
        </div>
    )
}