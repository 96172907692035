import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { candidateApi } from "../rtk/candidates.api";
import { assessmentApi } from "../rtk/assessments.api";
import { positionApi } from "src/rtk/positions.api";
import { userApi } from "src/rtk/users.api";
import { departmentApi } from "src/rtk/departments.api";
import { taskApi } from "src/rtk/tasks.api";
import { organizationApi } from "src/rtk/organizations.api";
import { authApi } from "src/rtk/auth.api";
import { pinnedDataApi } from "src/rtk/pinneddata.api";
import { examsApi } from "src/rtk/exams.api";
import { challengesApi } from "src/rtk/challenges.api";
import { drushimsApi } from "src/rtk/drushim.api";
import { companiesApi } from "src/rtk/companies.api";
import { contactsApi } from "src/rtk/contacts.api";


export const store = configureStore({
    reducer: {
        [candidateApi.reducerPath]: candidateApi.reducer,
        [assessmentApi.reducerPath]: assessmentApi.reducer,
        [positionApi.reducerPath]: positionApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [departmentApi.reducerPath]: departmentApi.reducer,
        [taskApi.reducerPath]: taskApi.reducer,
        [organizationApi.reducerPath]: organizationApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [pinnedDataApi.reducerPath]: pinnedDataApi.reducer,
        [examsApi.reducerPath]: examsApi.reducer,
        [challengesApi.reducerPath]: challengesApi.reducer,
        [drushimsApi.reducerPath]: drushimsApi.reducer,
        [companiesApi.reducerPath]: companiesApi.reducer,
        [contactsApi.reducerPath]: contactsApi.reducer,

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
        .concat(candidateApi.middleware)
        .concat(assessmentApi.middleware)
        .concat(positionApi.middleware)
        .concat(userApi.middleware)
        .concat(departmentApi.middleware)
        .concat(taskApi.middleware)
        .concat(organizationApi.middleware)
        .concat(authApi.middleware)
        .concat(pinnedDataApi.middleware)
        .concat(examsApi.middleware)
        .concat(challengesApi.middleware)
        .concat(drushimsApi.middleware)
        .concat(companiesApi.middleware)
        .concat(contactsApi.middleware)
});

setupListeners(store.dispatch);