import { LoginSocialGithub, LoginSocialGoogle, LoginSocialLinkedin, LoginSocialMicrosoft } from "reactjs-social-login";
import { FcGoogle } from "react-icons/fc";

const SocialLogin = (props) => {
    const { chooseSocialLogin, callback, size, icon, children, disable } = props;
    const Goolge = () => {
        return (
            <div>
                {!disable? (<LoginSocialGoogle
                client_id="895967328262-o7o5vc9hhq0gc6o7mq67pf8s0hmnicc0.apps.googleusercontent.com"
                onReject={(e) => console.log(e)}
                onResolve={(e) => callback(chooseSocialLogin, e.data?.credential)}
                isOnlyGetToken
                typeResponse={'idToken'}
                ux_mode={''}
              >
                {children ?? (icon ?? <FcGoogle size={size} />)}

              </LoginSocialGoogle>): (children?? (<></>))}
              
            </div>
          );
    }

    const Github = () => {
        return (
            <div>
              <LoginSocialGithub
                client_id="<github_client_id>"
                isOnlyGetToken
                onReject={(e) => console.log(e)}
                onResolve={(e) => callback(chooseSocialLogin, e)}
                isOnlyGetCode
                typeResponse={'idToken'}
              >
                {icon}
              </LoginSocialGithub>
            </div>
          );
    }

    const LinkedIn = () => {
        return (
            <div>
              <LoginSocialLinkedin
                client_id="<linkedin_client_id>"
                isOnlyGetToken
                onReject={(e) => console.log(e)}
                onResolve={(e) => callback(chooseSocialLogin, e)}
              >
                {icon}
              </LoginSocialLinkedin>
            </div>
          );
    }

    const Microsoft = () => {
        return (
            <div>
              <LoginSocialMicrosoft
                client_id="<microsoft_client_id>"
                isOnlyGetToken
                onReject={(e) => console.log(e)}
                onResolve={(e) => callback(chooseSocialLogin, e)}
              >
                {icon}
              </LoginSocialMicrosoft>
            </div>
          );
    }

  return (
    <>
    {(() => {
        switch (chooseSocialLogin) {
        case 'Google':
            return Goolge();
        case 'LinkedIn':
            return LinkedIn();
        case 'Github':
            return Github();
        case 'Microsoft':
            return Microsoft();
        default:
            return null;
        }
    })()}
    </>
  );
};

export default SocialLogin;