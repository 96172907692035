/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData, useRouteLoaderData } from "react-router-dom"
import DataTable from 'src/app/components/DataTable'

import { skipToken } from "@reduxjs/toolkit/query"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button, Tag } from '@chakra-ui/react';
import { Toolbar } from 'src/_metronic/layout/components/toolbar/Toolbar'
import { ToolbarReports } from 'src/_metronic/layout/components/toolbar/toolbars'
import { Page } from 'src/app/components/Page'
import { PositionsChart } from 'src/app/components/charts/PositionsChart'
import { colors } from 'src/app/libs/config'
import Row from 'src/app/components/temp/Row'
import { Can } from 'src/app/routing/AppRoutes'
import DepartmentTag from 'src/app/components/Tags/DepartmentTag'
import StatusTag from 'src/app/components/Tags/StatusTag'
import { useGetPositionsQuery } from 'src/rtk/positions.api'
import RoundedProgressCard from 'src/app/components/charts/RoundedProgressCard'
import moment from "moment";
import { useGetCandidateJobsQuery, useGetCandidatesQuery } from 'src/rtk/drushim.api'
import ShowCandidateResume from '../drushim/ShowCandidateResume'
// import ManagePosition from './components/ManagePosition'

const columnHelper = createColumnHelper()

let breadCrumbs: Array<PageLink> = [

]


export const JobCandidateReportPage = ({ }) => {
    const { candidates, job } = useRouteLoaderData('job');


    const { report } = useLoaderData();

    const candidate = candidates.find(can => can.candidateId === report.candidateId);


    // breadCrumbs = [
    //     {
    //         title: 'Jobs',
    //         path: '/management/jobs',
    //         isSeparator: false,
    //         isActive: false,
    //     },
    //     {
    //         isSeparator: true,
    //     },
    //     {
    //         title: job.title,
    //         path: '/management/jobs/' + job.id,
    //         isSeparator: false,
    //         isActive: false,
    //     },
    //     {
    //         isSeparator: true,
    //     },
    //     {
    //         title: candidate.candidateName
    //     },
    //     {
    //         title: 'Report',
    //         path: '',
    //         isSeparator: true,
    //         isActive: true,
    //     },
    // ]

    return (
        <>
            <Page breadcrumbs={breadCrumbs} title={`${candidate.candidateName} - ${candidate.candidateTitle}`} subheader={`${job.companyName}. ${job.title}`}>
                <div key='toolbar' style={{ display: 'inline-grid', gridAutoFlow: 'column', gap: '10px' }}>
                    <a href={job.link? job.link : `https://drushim.co.il/job/${job.externalId}/${job.externalSubId}/`} className="btn btn-info fs-6" target='_blank'><i className="bi bi-app-indicator fs-3 me-2"></i> Link to Job</a>
                    <ShowCandidateResume candidate={{ id: candidate.candidateId, name: candidate.candidateName }} buttonName={'Show Resume'} classNames={"btn btn-light-primary"}>
                        <button key={'button'} className="btn btn-danger fs-6"><span class="svg-icon svg-icon-1"><i className="bi bi-app-indicator fs-3 me-2"></i> Show CV</span></button>
                    </ShowCandidateResume>
                </div>
                <div key={'card'}>
                    <div className='card-body py-3'>

                        <div className='table-responsive'>

                            <table className='table align-middle gs-0 gy-4'>

                                <thead>
                                    <tr className='fw-bold  bg-light user-select-none text-uppercase'>
                                        <th className='min-w-150px ps-6 rounded-start'>Requirement</th>
                                        <th className='min-w-80px'>Must/Advantage</th>
                                        <th className='min-w-80px'>Qualified</th>
                                        <th className='min-w-100px'>Score</th>
                                        <th className='min-w-220px'>Comments</th>
                                        <th className='min-w-70px rounded-end'>Weight</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {report.summary.qualifications.map((qualification) => (
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                            {qualification.requirement}
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center text-uppercase'>
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                            {qualification.must && 'Must'}
                                                            {!qualification.must && 'Advantage'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                    <span className="badge badge-light-primary text-uppercase">
                                                        {qualification.qualified && 'Yes'}
                                                        {!qualification.qualified && 'No'}
                                                    </span>
                                                </span>
                                            </td>
                                            <td className='text-end'>
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className="badge badge-info pt-3 pb-3 fs-7 w-50px text-center" style={{ 'display': 'ruby-text' }}>{qualification.score.toFixed(1)}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex  flex-shrink-0'>
                                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                        {qualification.comments}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex  flex-shrink-0'>
                                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                        {qualification.weight}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>))}
                                </tbody>

                            </table>
                            <span className="btn btn-bg-success text-uppercase text-white"> Qualification Score: {(report.summary.overall_score).toFixed(1)} </span>
                        </div>
                    </div>
                </div>

            </Page>
            <Page className={'mt-10'}>
                <div key={'card'}>
                    <div className='card-body py-3 mt-10'>

                        <div className='table-responsive'>

                            <table className='table align-middle gs-0 gy-4'>

                                <thead>
                                    <tr className='fw-bold  bg-light user-select-none text-uppercase'>
                                        <th className='min-w-150px ps-6 rounded-start'>Element</th>
                                        <th className='min-w-180px'>Score</th>
                                        <th className='min-w-220px rounded-end'>Comments</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {report.attractivenessSummary?.qualifications?.map((qualification) => (
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                            {qualification.aspect}
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className='text-end'>
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className="badge badge-info pt-3 pb-3 fs-7 w-50px text-center" style={{ 'display': 'ruby-text' }}>{qualification.score.toFixed(1)}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                    {qualification.justification}
                                                </span>
                                            </td>
                                        </tr>))}
                                </tbody>

                            </table>

                        </div>

                    </div>
                    <div className='card-footer border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className="btn btn-bg-success text-uppercase text-white"> Position Attractiveness: {(report.trueMatchScore).toFixed(1)} </span>
                        </h3>
                    </div>
                </div>
            </Page>

            <Page className='mt-10' title='Qualification highlights:' subheader={`${report.summary.overall_summary} Match Score: ${(report.overallScore).toFixed(1)}`}>
            <div key='toolbar' style={{ display: 'inline-grid', gridAutoFlow: 'column', gap: '10px' }}>
                <button className="btn btn-info fs-6"><i className="bi bi-app-indicator fs-3 me-2"></i> Send to Company</button>
            </div>
            </Page>
        </>
    )
}


export default JobCandidateReportPage