/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, Suspense, useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, ILayout, useLayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Await, Link, useLoaderData, useRouteLoaderData } from "react-router-dom"

import {
    useGetCandidatePositionsQuery
} from 'src/rtk/positions.api'

import { skipToken } from "@reduxjs/toolkit/query"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button } from '@chakra-ui/react';
import { colors } from 'src/app/libs/config'
import DataTable from 'src/app/components/DataTable'
import Moment from 'react-moment'
import { LoadingContext } from 'src/providers/Loading/LoadingContext'
import AddCandidateExam from './components/AddCandidateExamModal'
import { CandidateExams } from 'src/app/modules/exams/CandidateExams'
import { Can } from 'src/app/routing/AppRoutes'

const columnHelper = createColumnHelper()

export const CandidateExamsPage = ({ }) => {
    const { candidate } = useRouteLoaderData("candidateProfile");
    const { candidatePositions, positions } = useLoaderData()

    const breadCrumbs: Array<PageLink> = [
        {
            title: 'Management',
            path: '#',
            isSeparator: false,
            isActive: false
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false
        },
        {
            title: 'Candidates',
            path: '/management/candidates',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false
        },
        {
            title: `${candidate.firstName} ${candidate.lastName}`,
            path: `/management/candidates/${candidate.id}/profile`,
            isSeparator: false,
            isActive: false,
        },
        {
            title: 'Exam',
            path: '#',
            isSeparator: true,
            isActive: true,
        },
    ]

    return (<CandidateExams title={'Profile'} candidate={candidate} candidatePositions={candidatePositions} queryParams={{
                candidateId: candidate.id,
                page: 1,
                pageSize: 10
            }}>
        <div key="actions">
            <Can I={'create'} a={'Exam'}>
                <AddCandidateExam
                    candidateId={candidate.id}
                    candidatePositions={candidatePositions}
                    positions={positions}
                    trigger={
                        <Button
                            colorScheme='blue'
                            fontSize='12px'
                        >
                            Add Position
                        </Button>
                    }
                />
            </Can>
        </div>
    </CandidateExams>)
}
