/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData, useParams } from "react-router-dom"
import DataTable from 'src/app/components/DataTable'

import { skipToken } from "@reduxjs/toolkit/query"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button } from '@chakra-ui/react';
import { Toolbar } from 'src/_metronic/layout/components/toolbar/Toolbar'
import { ToolbarReports } from 'src/_metronic/layout/components/toolbar/toolbars'
import { Page } from 'src/app/components/Page'
import { PositionsChart } from 'src/app/components/charts/PositionsChart'
import { colors } from 'src/app/libs/config'
import Row from 'src/app/components/temp/Row'
import { Can } from 'src/app/routing/AppRoutes'
import DepartmentTag from 'src/app/components/Tags/DepartmentTag'
import StatusTag from 'src/app/components/Tags/StatusTag'
import { useGetAllAssessmentsQuery } from 'src/rtk/exams.api'
import RoundedProgressCard from 'src/app/components/charts/RoundedProgressCard'
import moment from "moment";
import ManageExam from './components/ManageExam'
import Moment from 'react-moment'
import ExamAddCandidate from './components/ExamAddCandidate'
import { useGetClonesQuery } from 'src/rtk/exams.api'


const columnHelper = createColumnHelper()

const breadCrumbs: Array<PageLink> = [
    {
        title: 'Management',
        path: '#',
        isSeparator: false,
        isActive: false
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: true,
    },
    {
        title: 'Exams',
        path: '/management/exams',
        isSeparator: false,
        isActive: false
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: true,
    },
]

export const ExamPage = ({ }) => {
    const { exam, exams, departments, overview } = useLoaderData();

    const { assessmentTemplateId } = useParams()

    const colmns = [
        columnHelper.accessor('receiverName',{
            header: 'Name',
            cell: (info) => (
                <div className='d-flex'>
                    <div>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {info.getValue()}
                        </a>
                        <span className='text-muted d-block fw-semibold'>{info.row.original.receiverEmail}</span>
                    </div>
                </div>
            )
        }),
        columnHelper.accessor("created", {
            header: 'Date Link Sent',
            cell: (info) => <>
                <Moment format="DD/MM/YYYY HH:mm">{info.getValue()}</Moment> (<Moment fromNow>{info.getValue()}</Moment>)
            </>
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            cell: (info) => info.getValue()
        }),
        columnHelper.accessor('score', {
            header: 'Exam Score From 100',
            cell: (info) => `${info.getValue()?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) ?? 0}`
        }),
        columnHelper.accessor('receiverId', {
            header: 'Details',
            enableSorting: false,
            cell: (info) => {
                return (
                    <Link key={info.getValue()} to={`/management/candidates/${info.getValue()}/profile`}>
                        <Button size='sm' color='#A1A5B7'>
                        Go to profile
                        </Button>
                    </Link>
                )
            }
        })
    ];

    return (
        <>
            <Page breadcrumbs={breadCrumbs} title={`${exam.data[0].name}'s Copies`} columns={colmns} dataSourceQueryFn={useGetClonesQuery} queryParams={{
                parentExamId: assessmentTemplateId
            }}>
                <div key='toolbar'>
                    <Can a={'Exam'} I={'create'}>
                    <ManageExam exam={exam?.data[0]} inputMapper={(value) => {
                        const val = {...value}
                        val.challenges = val.challenges.map((challenge) => ({
                            id: challenge.id,
                            order: challenge.order,
                            configurationId: challenge.configurationId,
                            name: challenge.name,
                            reference: challenge.selectedBranch
                        }))
                        return val;
                    }} />
                    </Can>
                </div>
                <div key='middle'>
                <Row>
						<RoundedProgressCard
							title='Yet to start'
							color={colors.textMG}
							progress={
								(overview.yetToStart / (overview.total)) *
								100
							}
							count={overview.yetToStart}
						/>
						<RoundedProgressCard
							title='In Progress'
							color={colors.link}
							progress={
								(overview.inProgress /
									(overview.total)) *
								100
							}
							count={overview.inProgress}
						/>
						<RoundedProgressCard
							title='Completed'
							color={colors.success}
							progress={
								(overview.completed / (overview.total)) *
								100
							}
							count={overview.completed}
						/>

						
                        <PositionsChart
                            title='Departments'
                            data={departments?.map((item, idx) => item.name)}
                            color={[colors.link, colors.success, colors.warning, "#FF9F9F"]}
                            amount={
                                departments
                                    ? departments?.map((item, idx) => item.value)
                                    : [0, 0, 0, 0]
                            }
                            values={
                                departments
                                    ? departments?.map((item, idx) => item.value)
                                    : [0, 0, 0, 0]
                            }
                        />
					</Row>
                </div>
            </Page>
        </>
    )
}


export default ExamPage