import React from 'react';
import SelectField from './SelectField';

const ActiveField = ({ label, h, form, fieldName, registerOptions, required, ...props }) => {
    if (!registerOptions) {
		registerOptions = {}
	}

	if (required) {
        registerOptions['validate'] = (value) => {
            if (value == null) {
              return `${label} is required`
            }
            return true
          }
	}

    const options = [{label: 'Active', value: true}, {label: 'Inactive', value: false}]
	return (
		<SelectField form={form} fieldName={fieldName} label={label ?? 'Status'} {...props} registerOptions={registerOptions} options={options} keyName={'label'} valueName={'value'} />
	);
};

export default ActiveField;
