import {
	Heading,
	Stack,
	Flex,
	CircularProgress,
	CircularProgressLabel,
	Progress,
	Text,
} from '@chakra-ui/react';
import React from 'react';
import Container from '../Container';
import { colors } from 'src/app/libs/config';

const ProgressChart = ({ title, level, progress, color, tag, specialCenter }) => {
	return (
		<Container p='24px' flex={1}>
			<Stack spacing={2} justify='space-between' flex={1}>
				<Flex>
					<Heading size='md' color='#B5B5C3' fontWeight='600'>
						{title}
					</Heading>
				</Flex>
				<Flex justify='flex-end' flex={1} pt={4}>
					<CircularProgress
						size='100px'
						value={progress}
						color={`${color}.500`}
						thickness='6px'
					>
						<CircularProgressLabel color='#B5B5C3' fontSize='22px'>
							{specialCenter? specialCenter : `${progress.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}%`}
						</CircularProgressLabel>
					</CircularProgress>
				</Flex>
				<Stack flex={1} justify='flex-end'>
					{tag ? (
						<Text color={colors.text} textAlign="center" fontSize="15px" fontWeight="medium">{tag}</Text>
					) : (
						<>
							{/* <Text fontWeight='600' fontSize='14px' color='#B5B5C3'>
								{level}
							</Text> */}
							<Progress
								w='60%'
								value={progress}
								colorScheme={color}
								size='sm'
								borderRadius='4px'
								color='red'
							/>
						</>
					)}
				</Stack>
			</Stack>
		</Container>
	);
};

export default ProgressChart;
