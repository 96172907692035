/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Flex,
	Stack,
	Box,
} from "@chakra-ui/react";
import { dimentions } from "src/app/libs/config";
import {
	useAddPositionMutation,
	useUpdatePositionMutation,
	useGetTeammatesQuery
} from "src/rtk/positions.api";
import { useGetAllAssessmentTemplatesQuery } from "src/rtk/exams.api";
import {
	useGetDepartmentsQuery,
	useCreateDepartmentMutation
} from "src/rtk/departments.api";
import SelectField from "src/app/components/form-inputs/SelectField";
import { useForm } from "react-hook-form";
import TextField from "src/app/components/form-inputs/TextField";
import TextAreaField from "src/app/components/form-inputs/TextAreaField";
import Row from "src/app/components/temp/Row";
import DepartmentField from "src/app/components/form-inputs/DepartmentField";
import { useGetExamsQuery } from "src/rtk/exams.api";


const ManagePosition = ({position, ...props}) => {
	const { isOpen, onOpen, onClose } = useDisclosure("");

	const form  = useForm({
		mode: 'all',
		defaultValues : {
			status: 'opened'
		}
	});

	let [trigger, result] = [];

	const options = [{label: 'Opened', value: 'opened'}, {label: 'Closed', value: 'closed'}, {label: 'Filled', value: 'filled'}];

	const { handleSubmit, reset } = form;

	if (position) {
		[trigger, result] = useUpdatePositionMutation();
	} else {
		[trigger, result] = useAddPositionMutation();
	}

	const setData = (data) => {
		trigger(data);
	}

	const close = () => {
		reset({});
		onClose();
	};

	useEffect(() => {
		if (position) {
			reset(position);
		}
	}, [position, reset])

	useEffect(() => {
		if (result?.isSuccess && !result?.isLoading) {
			close();
		}
	}, [result?.isLoading]);

	return (
		<>
			<Button colorScheme='green' px={8} onClick={onOpen}>
				{position? 'Edit Position' : 'Add Position'}
			</Button>

			<Modal isOpen={isOpen} onClose={close} size='2xl'>
				<ModalOverlay />
				<ModalContent p={4} borderRadius={dimentions.borderRadius.lg}>
					<ModalHeader>{position? 'Edit Position' : 'Add Position'}</ModalHeader>
					<ModalCloseButton />
					<ModalBody borderRadius={dimentions.borderRadius.lg}>
						<Flex>
							<Stack w='100%' spacing={6} as='form' onSubmit={handleSubmit((data) => setData(data))}>
								<Row>
									<Flex flex={1}>
										<TextField fieldName={'title'} form={form} label={'Position Name'} registerOptions={{
											required: 'Position name is required'
										}}/>
									</Flex>
									<Flex flex={1}>
										<SelectField form={form} fieldName={'status'} options={options} keyName={'label'} valueName={'value'} label={'Job Status'} ignoreCaseSensitive registerOptions={{
											required: 'Job Status is Required'
										}} width={'270px'}  />
									</Flex>
								</Row>
								<Row>
									<Flex flex={1}>
										<TextField fieldName={'jobOwner'} form={form} label={'Job Owner'} registerOptions={{
											required: 'Job Owner is Required'
										}} />
									</Flex>
									<Flex flex={1}>
										<SelectField fieldName={'examId'} form={form} keyName={'name'} valueName={'id'} registerOptions={{
											required: 'Exam is required'
										}} width={'270px'} label={'Exam'} query={useGetExamsQuery} queryFilters={{page: 0, size: 1000, purpose: 'select'}}/>
									</Flex>
								</Row>
								<Row>
								<Flex flex={1}>
										<SelectField fieldName={'recruiterId'} form={form} keyName={'name'} valueName={'id'} registerOptions={{
											required: 'Recruiter is required'
										}} width={'270px'} label={'Recruiter'} keyInterpolation={(value) => `${value.firstName} ${value.lastName}`} query={useGetTeammatesQuery} queryFilters={{
											roles: ['Recruiter']
										}}/>
									</Flex>
								</Row>
								<Row>
									<Flex flex={1}>
										<DepartmentField form={form} fieldName={'departmentId'} />
									</Flex>
								</Row>

								<Flex>
									<TextAreaField fieldName={'description'} form={form} label={'Description'} registerOptions={{
										required: 'Description is required'
									}} />
								</Flex>
								<Box>
									<Button
										size='md'
										borderRadius={dimentions.borderRadius.sm}
										colorScheme='blue'
										type='submit'
									>
										Save
									</Button>
								</Box>
							</Stack>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ManagePosition;
