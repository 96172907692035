/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, PageLink } from 'src/_metronic/layout/core'

import { Link, useLoaderData, useLocation, useParams, useRouteLoaderData } from "react-router-dom"


import { Avatar, Flex, Stack, Text, Button, Tag } from '@chakra-ui/react'
import { colors, dimentions } from 'src/app/libs/config'
import { Can } from 'src/app/routing/AppRoutes'
import { useGetIframeQuery } from 'src/rtk/challenges.api'
import { BeatLoader } from 'react-spinners'
import ReactMarkdown from "react-markdown"
import ChallengeSubmit from './components/ChallengeSubmit'
import GenericPopover from 'src/app/components/GenericPopover'
import TimeCountdown from 'src/app/components/TimeCountdown'
import ContentLoader from 'react-content-loader'
import { LoadingContext } from 'src/providers/Loading/LoadingContext'


export const ChallengeEnvironementPage = ({breadCrumbs}: {breadCrumbs?: any}) => {
    const { challenge } = useRouteLoaderData("challenge")
    const { exam } = useRouteLoaderData("exam")
    const { examId, challengeId } = useParams()
	const { setLoading } = useContext(LoadingContext)

    const {
		data: iframeSrc,
		isLoading: iframeSrcIsLoading,
		isError: iframeSrcIsError,
		isSuccess: iframeSrcIsSuccess,
	} = useGetIframeQuery({ examId, challengeId });
    
    const [iframeLoading, setIframeLoading] = useState(true)

    const iframeRef = useRef(null)

	useEffect(() => {
		setLoading(iframeLoading)
	}, [iframeLoading])

    return (
        <>
			<PageTitle>
                Environment
            </PageTitle>
				<Flex
					h='64px'
					// w='full'
					justify='space-between'
					px={4}
					py={3}
					bgColor='var(--bs-body-bg)'
					align='center'
					borderRadius={dimentions.borderRadius.lg}
					color={'var(--bs-gray-900)'}
					flex={1}
					marginBottom={5}
				>
					<Text marginBottom={0}>
						Time Left:{" "}
						<TimeCountdown
							range={
								challenge?.data[0]?.challenge.configuration?.timeLimit ?? "00:30:00"
							}
						/>{" "}
					</Text>
					<Text marginBottom={0}>
						{`Challenge Time: 
						${
							challenge?.data[0]?.challenge.configuration?.timeLimit ?? "00:30:00"
						}`}
					</Text>
					<Flex align='center' gap={6}>
						<GenericPopover iconName={"BsKeyboardFill"}>
							<div>Stackoverflow Search</div>
							<div>
								<Tag>CTRL</Tag>+<Tag>ALT</Tag>+<Tag>S</Tag>
							</div>
						</GenericPopover>
						<GenericPopover
							data={challenge?.data[0]?.challenge.description}
						/>
						<ChallengeSubmit examId={examId} challengeId={challengeId} />
					</Flex>
				</Flex>
				<Flex flex={1} align='center' flexDir='column'>
					{iframeSrc?.value && (
						<iframe
							src={iframeSrc?.value}
							style={{
								// flex: 1,
								width: "100%",
								height: "calc(100vh - 2.8rem - 185px)",
								// backgroundColor: "gray",
							}}
							ref={iframeRef}
							onLoad={() => setIframeLoading(false)}
						></iframe>
					)}
				</Flex>
        </>
    )
}
