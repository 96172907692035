/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData, useLocation, useNavigate, useNavigation, useRouteLoaderData } from "react-router-dom"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button } from '@chakra-ui/react';
import { colors } from 'src/app/libs/config'
import TimeAgo from './TimeAgo'
import RenderTextWithLineBreaks from '../../drushim/RenderTextWithLineBreaks'
import Markdown from 'react-markdown'
import { useApplyToJobMutation } from 'src/rtk/drushim.api'


const columnHelper = createColumnHelper()

export const MatchPage = ({ match }) => {

    const [applyToJob, result] = useApplyToJobMutation()

    const handleApply = () => {
        applyToJob({matchId: match.id})
    }

    return (
        <div className='card mb-5 mb-xl-10'>
            <div id='kt_account_profile_details'>
            <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center">
                        <img src={match.companyLogo} alt={`${match.companyName} Logo`} className="me-2 w-40px h-40px" />
                        <span className="fw-bold fs-3">{match.companyName}</span>
                    </div>
                    <div>
                        {
                            match.status < 500 && <span className="btn btn-primary" onClick={handleApply}>Apply</span>
                        }
                    </div>
                </div>
                <h5 className="card-title mb-3">{match.title}</h5>
                <div className="row mb-3">
                    <div className="col-auto d-flex align-items-center">
                        <i className="bi bi-rocket me-2"></i>
                        <small className="text-muted">Matching score {match.score.toFixed(0)}</small>
                    </div>
                    <div className="col-auto d-flex align-items-center">
                        <i className="bi bi-clock me-2"></i>
                        <small className="text-muted"><TimeAgo date={match.date} /></small>
                    </div>
                    <div className="col-auto d-flex align-items-center">
                        <i className="bi bi-geo-alt me-2"></i>
                        <small className="text-muted">{match.locations.join(', ')}</small>
                    </div>
                </div>
                <p className="card-text bg-light p-3 rounded">
                    <Markdown>{match.matchExplanation}</Markdown>
                </p>
                
                    {/* <RenderTextWithLineBreaks text={match.description} /> */}
                    <Markdown>{match.fullDescription}</Markdown>
                    
                {/* <h6 className="mt-4">About the Company</h6>
                <p className="card-text">
                    [Generated text from GPT, this part can be added later]
                </p> */}
            </div>
        </div>
        </div>
    )
}
