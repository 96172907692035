/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData } from "react-router-dom"
import DataTable from 'src/app/components/DataTable'

import { skipToken } from "@reduxjs/toolkit/query"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button } from '@chakra-ui/react';
import { Toolbar } from 'src/_metronic/layout/components/toolbar/Toolbar'
import { ToolbarReports } from 'src/_metronic/layout/components/toolbar/toolbars'
import { Page } from 'src/app/components/Page'
import { PositionsChart } from 'src/app/components/charts/PositionsChart'
import { colors } from 'src/app/libs/config'
import Row from 'src/app/components/temp/Row'
import { Can } from 'src/app/routing/AppRoutes'
import DepartmentTag from 'src/app/components/Tags/DepartmentTag'
import StatusTag from 'src/app/components/Tags/StatusTag'
import { useGetAllAssessmentTemplatesQuery } from 'src/rtk/exams.api'
import RoundedProgressCard from 'src/app/components/charts/RoundedProgressCard'
import moment from "moment";
import ManageExam from './components/ManageExam'
import { useGetExamsQuery } from 'src/rtk/exams.api'


const columnHelper = createColumnHelper()

const breadCrumbs: Array<PageLink> = [
    {
        title: 'Management',
        path: '#',
        isSeparator: false,
        isActive: false
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: true,
    },
]

export const ManagementExamsPage = ({ }) => {
    const { exams, departments, overview } = useLoaderData();

    const colmns = [
        columnHelper.accessor('name', {
            header: 'Name',
        }),
        columnHelper.accessor("created", {
            header: 'Created',
            cell: (info) => moment(info.getValue()).format("MMM DD, YYYY")
        }),
        // columnHelper.display({
        //     header: 'Position',
        //     cell: (info) => '--'
        // }),
        columnHelper.accessor("departmentId", {
            header: 'Department',
            cell: (info) => {
                return (
                    <DepartmentTag>{info.getValue()}</DepartmentTag>
                )
            },
        }),
        columnHelper.accessor('active',{
            header: 'Status',
            cell: (info) => {
                return (
                    <StatusTag>{info.getValue()}</StatusTag>
                )
            },
        }),
        columnHelper.display({
            header: 'Duration Time',
            
            cell: (info) => '--'
        }),
        columnHelper.accessor('id', {
            header: 'Details',
            enableSorting: false,
            cell: (info) => {
                return (
                    <Link key={info.getValue()} to={info.getValue()}>
                        <Button size='sm' color='#A1A5B7'>
                            Go to Exam
                        </Button>
                    </Link>
                )
            }
        })
    ];


    return (
        <>
            <Page breadcrumbs={breadCrumbs} title={'Exams'} columns={colmns} dataSourceQueryFn={useGetExamsQuery}>
                <div key='toolbar'>
                    <Can a={'Exam'} I={'create'}>
                        <ManageExam />
                    </Can>
                </div>
                <div key='middle'>
                <Row>
						<RoundedProgressCard
							title='Yet to start'
							color={colors.textMG}
							progress={
								(overview.yetToStart / (overview.total)) *
								100
							}
							count={overview.yetToStart}
						/>
						<RoundedProgressCard
							title='In Progress'
							color={colors.link}
							progress={
								(overview.inProgress /
									(overview.total)) *
								100
							}
							count={overview.inProgress}
						/>
						<RoundedProgressCard
							title='Completed'
							color={colors.success}
							progress={
								(overview.completed / (overview.total)) *
								100
							}
							count={overview.completed}
						/>

						
                        <PositionsChart
                            title='Departments'
                            data={departments?.map((item, idx) => item.name)}
                            color={[colors.link, colors.success, colors.warning, "#FF9F9F"]}
                            amount={
                                departments
                                    ? departments?.map((item, idx) => item.value)
                                    : [0, 0, 0, 0]
                            }
                            values={
                                departments
                                    ? departments?.map((item, idx) => item.value)
                                    : [0, 0, 0, 0]
                            }
                        />
					</Row>
                </div>
            </Page>
        </>
    )
}


export default ManagementExamsPage