import axios from 'axios';

export const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
    async ({ url = "", method, body, params, headers, validateStatus }, { signal }) => {
      try {
        if (params) {
          for (const [key, value] of Object.entries(params)) {
            if (Array.isArray(value)) {
              params[key] = value.join(",")
            }
          }
        }

        const result = await axios.request({
          url: baseUrl + url,
          method,
          data: body,
          headers: headers,
          params,
          signal,
        });
        if (validateStatus) {
          validateStatus({ ...result, ok: result.status >= 200 && result.status < 300 }, result.data)
        }
        return { data: result.data };
      } catch (axiosError) {
        let err = axiosError;
        if (validateStatus) {
          validateStatus({ ...err, ok: false }, {})
        }
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    };