import React, { useEffect, useState } from "react";

const TimeCountdown = ({ range }) => {
	const [time, setTime] = useState(range);

	useEffect(() => {
		const countdown = setInterval(() => {
			if (time) {
				const parts = time.split(":");
				let hours = parseInt(parts[0], 10);
				let minutes = parseInt(parts[1], 10);
				let seconds = parseInt(parts[2], 10);

				if (hours === 0 && minutes === 0 && seconds === 0) {
					clearInterval(countdown);
				} else {
					if (seconds === 0) {
						if (minutes === 0) {
							hours--;
							minutes = 59;
						} else {
							minutes--;
						}
						seconds = 59;
					} else {
						seconds--;
					}

					const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
						.toString()
						.padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

					setTime(formattedTime);
				}
			}
		}, 1000);

		return () => {
			clearInterval(countdown);
		};
	}, [time, range]);

	return <>{time}</>;
};

export default TimeCountdown;
