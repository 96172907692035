/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from 'src/_metronic/helpers'
import { Link, useLoaderData, useLocation, useParams } from 'react-router-dom'
import { Dropdown1 } from 'src/_metronic/partials'
import { useUserAbilities } from 'src/app/libs/ability'
import { ProfileHeader } from 'src/app/modules/profile/ProfileHeader'
import { useAuth } from '../auth'
import { useGetUserQuery } from 'src/rtk/users.api'
import { Can } from 'src/app/routing/AppRoutes'

const UserProfileHeaderWrapper: React.FC = () => {
  const location = useLocation()
  const { candidateId, examId } = useParams()
  const { data: user } = useGetUserQuery()
  const { currentUser } = useAuth()
  const { can } = useUserAbilities()

  return (
    <ProfileHeader hide={examId} user={user ?? currentUser}>
        {/* <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/account/profile` && 'active')
                }
                to={`/account/profile`}
              >
                Profile
              </Link>
            </li>
            <Can I={'solve'} a={'Challenge'}>
                <li className='nav-item'>
                <Link
                    className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/account/matches` && 'active')
                    }
                    to={`/account/matches`}
                >
                    Matches
                </Link>
                </li>
            </Can>
            <Can I={'solve'} a={'Challenge'}>
                <li className='nav-item'>
                <Link
                    className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/account/exams` && 'active')
                    }
                    to={`/account/exams`}
                >
                    Exams
                </Link>
                </li>
            </Can> */}
    </ProfileHeader>
  )
}

export { UserProfileHeaderWrapper }
