import React, { useState, useEffect } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Flex,
	Stack,
	Grid,
	Box,
	Checkbox,
	Image,
	Text,
} from "@chakra-ui/react";
import { colors, dimentions } from "src/app/libs/config";
import { useCreateCandidateAndAttachPositionMutation } from "src/rtk/positions.api";
import { toast } from 'react-toastify';
import TextField from "src/app/components/form-inputs/TextField";
import EmailField from "src/app/components/form-inputs/EmailField";
import { useForm } from "react-hook-form";

const ExamAddCandidate = ({ positionId }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

    const form = useForm({
		mode: 'all',
        defaultValues: {
            positionId: positionId
        }
	});


	const { handleSubmit, reset } = form;

	const [createCandidateAndAttachPosition, result] = useCreateCandidateAndAttachPositionMutation();

	const handleSubmitFn = (data) => {
		createCandidateAndAttachPosition(data);
	};

	const close = () => {
        reset({})
		onClose();
	};

	useEffect(() => {
		if (result?.isSuccess && !result?.isLoading) {
			toast.success("Candidate Added!", {
				position: toast.POSITION.TOP_RIGHT,
				theme: 'colored',
				pauseOnHover: false,
				pauseOnFocusLoss: false

		  	});
			close();
		}
	}, [result]);

	return (
		<>

			<Button colorScheme='green' px={8} onClick={onOpen}>
				Add Candidate
			</Button>

			<Modal isOpen={isOpen} onClose={onClose} size='2xl'>
				<ModalOverlay />
				<ModalContent p={4} borderRadius={dimentions.borderRadius.lg}>
					<ModalHeader>Add Candidate</ModalHeader>
					<ModalCloseButton onClick={close} />
					<ModalBody borderRadius={dimentions.borderRadius.lg}>
						<Flex>
							<Stack as='form' onSubmit={handleSubmit((data) => handleSubmitFn(data))}>
									<Stack w='100%' spacing={3}>
										<Flex flex={2} gap={4}>
                                        <TextField 
												form={form}
												registerOptions={{
													maxLength: {
														value: 20,
														message: 'Max length: 20'
													},
													required: 'First Name is required!'
												}}
												fieldName={'firstName'}
												label='First Name'
											/>
											<TextField
												form={form}
												registerOptions={{
													maxLength: 20,
													required: 'Last Name is required!'
												}}
												fieldName={'lastName'}
												label='Last Name'
											/>
										</Flex>

										<Flex flex={1}>
											<EmailField
												form={form}
												registerOptions={
													{
														required: {
															value: true,
															message: "Please enter your email address",
													  	}
													}
												}
												fieldName={'email'}
											/>
										</Flex>
										</Stack>
								<Flex flex={1} pt={6} h='64px'>
								<Box>
									<Button
										type='submit'
										isLoading={result?.isLoading}
										size='md'
										borderRadius={dimentions.borderRadius.sm}
										colorScheme='blue'
									>
										Save
									</Button>
								</Box>
							</Flex>
							</Stack>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ExamAddCandidate;
