/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Flex,
	Stack,
	Box,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { dimentions } from "src/app/libs/config";
import {
	useCreateCandidateMutation
} from "src/rtk/drushim.api";
import { useForm } from "react-hook-form";
import Row from "src/app/components/temp/Row";


const UploadResume = ({...props}) => {
	const { isOpen, onOpen, onClose } = useDisclosure("");
    const {getRootProps, getInputProps} = useDropzone({noClick: true})

	
	let [trigger, result] = useCreateCandidateMutation();

	const fileInputRef = useRef(null); // Create a ref for the file input

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === "application/pdf" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
            console.log("file selected:", file.name);

			trigger(file);

            // You can then prepare this file to be sent to the server
        } else {
            alert("Please select a supported file.");
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click(); // Programmatically click the file input
    };




	const setData = (data) => {
		trigger(data);
	}

	const close = () => {
		// reset({});
		onClose();
	};

	useEffect(() => {
		if (result?.isSuccess && !result?.isLoading) {
			close();
		}
	}, [result?.isLoading]);

	return (
		<>
			<Button colorScheme='green' px={8} onClick={handleButtonClick}>
				Upload Candidate Resume
			</Button>

			<div>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }} // Hide the file input
                accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={handleFileChange}
            />
        </div>
		</>
	);
};

export default UploadResume;
