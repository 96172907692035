import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./base.api";
import { toast } from "react-toastify";

const tags = ["Candidates", "Jobs", "JobCandidates"];
export const drushimsApi = createApi({
	reducerPath: "drushimsApi",
	baseQuery: axiosBaseQuery({
        baseUrl: "",
    }),
	tagTypes: tags,
	endpoints: (builder) => ({
		getCandidates: builder.query({
			query: ({ filter, page = 1, pageSize = 10, ids, sort, order, purpose }) => ({
				url: `/Candidate`,
				method: "GET",
				params: {
					page: page,
					ids: ids,
					pageSize: pageSize,
					...(filter && { filter }),
					...(sort && { sort: sort }),
					...(order && { order: order }),
					...(purpose && { purpose: purpose }),
				}
			}),
			providesTags: ["Candidates"],
		}),
		getCandidateJobs: builder.query({
			query: ({ candidateId, page = 1, pageSize = 10, filter, sort, order }) => ({
				url: `/Candidate/Matches`,
				method: "GET",
				params: {
					page: page,
					pageSize: pageSize,
					...(filter && { filter }),
					...(candidateId && { candidateId }),
					...(sort && { sort: sort }),
					...(order && { order: order })
				}
			}),
			providesTags: ["Candidates"],
		}),
		getCandidateJobReport: builder.query({
			query: ({ candidateId, jobId }) => ({
				url: `/Candidate/report`,
				method: "GET",
				params: {
					candidateId: candidateId,
					jobId: jobId
				}
			}),
			providesTags: ["Candidates"],
		}),
		getCandidateResume: builder.query({
			query: ({ candidateId }) => ({
				url: `/Candidate/Resume`,
				method: "GET",
				params: {
					candidateId: candidateId
				}
			}),
			providesTags: ["Candidates"],
		}),
        createCandidate: builder.mutation({
            query: (resumeFile) => {
				var bodyFormData = new FormData();
    			bodyFormData.append('file', resumeFile);
				console.log({ bodyFormData, resumeFile });
                return {
                    url: "/Candidate",
                    method: "POST",
					headers: {
						'Content-Type': 'multipart/form-data;'
					},
                    body: bodyFormData,
                    formData: true
                };
            },
            invalidatesTags: ["Candidates"],
        }),
		findCandidateJobs: builder.mutation({
            query: (candidateId) => {
                return {
                    url: "/Candidate/find",
                    method: "GET",
                    params: {
						candidateId: candidateId
					}
                };
            },
            invalidatesTags: ["Candidates"],
        }),
		getJobs: builder.query({
			query: ({ filter, page = 1, pageSize = 10, ids, sort, order, purpose }) => ({
				url: `/Job`,
				method: "GET",
				params: {
					page: page,
					ids: ids,
					pageSize: pageSize,
					...(filter && { filter }),
					...(sort && { sort: sort }),
					...(order && { order: order }),
					...(purpose && { purpose: purpose }),
				}
			}),
			providesTags: ["Jobs"],
		}),
		getJobCandidates: builder.query({
			query: ({ jobId, page = 1, pageSize = 10, filter }) => ({
				url: `/Job/Candidates`,
				method: "GET",
				params: {
					page: page,
					pageSize: pageSize,
					...(filter && { filter }),
					jobId: jobId
				}
			}),
			providesTags: ["JobCandidates"],
		}),
		deleteJobCandidates: builder.mutation({
			query: ({ jobId }) => ({
				url: `/Job/Candidates`,
				method: "DELETE",
				params: {
					jobId: jobId
				}
			}),
			providesTags: ["Jobs"],
		}),
		getJobCandidateReport: builder.query({
			query: ({ candidateId, jobId }) => ({
				url: `/Job/report`,
				method: "GET",
				params: {
					candidateId: candidateId,
					jobId: jobId
				}
			}),
			providesTags: ["Jobs"],
		}),
		findJobCandidates: builder.mutation({
            query: ({jobId, overridePrompt, additionalPrompt}) => {
                return {
                    url: "/Job/find",
                    method: "POST",
					body: {
						overridePrompt: overridePrompt,
						additionalPrompt: additionalPrompt
					},
                    params: {
						jobId: jobId
					},
					validateStatus: (response, body) => {
						if (response.ok) {
							toast.success("Searching for candidates", {
								position: toast.POSITION.TOP_RIGHT,
								theme: 'colored',
								pauseOnHover: false,
								pauseOnFocusLoss: false
							})
						}
						return response.ok;
					},
                };
            },
            invalidatesTags: ["Jobs", "JobCandidates"],
        }),
		deleteJobMatch: builder.mutation({
			query: ({ matchId }) => ({
				url: `/Job/Match`,
				method: "DELETE",
				params: {
					matchId: matchId
				},
				validateStatus: (response, body) => {
					if (response.ok) {
						toast.success("Match removed successfully", {
							position: toast.POSITION.TOP_RIGHT,
							theme: 'colored',
							pauseOnHover: false,
							pauseOnFocusLoss: false
						})
					}
					return response.ok;
				},
			}),
			invalidatesTags: ["JobCandidates"],
		}),
		updateJobMatchStatus: builder.mutation({
			query: ({ matchId, status }) => ({
				url: `/Job/Match`,
				method: "PATCH",
				params: {
					matchId: matchId,
					status: status
				},
				validateStatus: (response, body) => {
					if (response.ok) {
						toast.success("Match status updated successfully", {
							position: toast.POSITION.TOP_RIGHT,
							theme: 'colored',
							pauseOnHover: false,
							pauseOnFocusLoss: false
						})
					}
					return response.ok;
				},
			}),
			invalidatesTags: ["JobCandidates"],
		}),
		applyToJob: builder.mutation({
			query: ({ matchId }) => ({
				url: `/Candidate/Match/apply`,
				method: "PATCH",
				params: {
					matchId: matchId
				},
				validateStatus: (response, body) => {
					if (response.ok) {
						toast.success("Applied Successfully", {
							position: toast.POSITION.TOP_RIGHT,
							theme: 'colored',
							pauseOnHover: false,
							pauseOnFocusLoss: false
						})
					}
					return response.ok;
				},
			}),
			invalidatesTags: ["Candidates"],
		}),
	}),
});

export const {
	useGetCandidatesQuery,
	useGetCandidateJobsQuery,
	useGetCandidateJobReportQuery,
    useCreateCandidateMutation,
	useGetCandidateResumeQuery,
	useGetJobsQuery,
	useGetJobCandidatesQuery,
	useGetJobCandidateReportQuery,
	useFindJobCandidatesMutation,
	useFindCandidateJobsMutation,
	useDeleteJobCandidatesMutation,
	useDeleteJobMatchMutation,
	useUpdateJobMatchStatusMutation,
	useApplyToJobMutation
} = drushimsApi;
