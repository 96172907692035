import { Flex } from '@chakra-ui/react';
import React from 'react';

const MainRow = ({ children, ...props }) => {
	return (
		<Flex gap={4} {...props}>
			{children}
		</Flex>
	);
};

export default MainRow;
