import { useModal } from './ModalContext';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';

const CustomModal = () => {
  const { isOpen, closeModal, modalContent, modalTitle } = useModal();

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size='2xl' closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {modalContent}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;