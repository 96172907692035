/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData } from "react-router-dom"
import DataTable from 'src/app/components/DataTable'

import { skipToken } from "@reduxjs/toolkit/query"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button, Tag } from '@chakra-ui/react';
import { Toolbar } from 'src/_metronic/layout/components/toolbar/Toolbar'
import { ToolbarReports } from 'src/_metronic/layout/components/toolbar/toolbars'
import { Page } from 'src/app/components/Page'
import { PositionsChart } from 'src/app/components/charts/PositionsChart'
import { colors } from 'src/app/libs/config'
import Row from 'src/app/components/temp/Row'
import { Can } from 'src/app/routing/AppRoutes'
import DepartmentTag from 'src/app/components/Tags/DepartmentTag'
import StatusTag from 'src/app/components/Tags/StatusTag'
import { useGetPositionsQuery } from 'src/rtk/positions.api'
import RoundedProgressCard from 'src/app/components/charts/RoundedProgressCard'
import moment from "moment";
import { useCreateCompanyMutation, useGetCompaniesQuery, useUpdateCompanyMutation } from 'src/rtk/companies.api'
import { toast } from "react-toastify"
import ManageCompany from './ManageCompany'
import ManageEntity from 'src/app/components/ManageEntity'
// import ManagePosition from './components/ManagePosition'


const columnHelper = createColumnHelper()

const breadCrumbs: Array<PageLink> = [
    // {
    //     title: 'Management',
    //     path: '#',
    //     isSeparator: false,
    //     isActive: false
    // },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: true,
    },
]


export const ManageCompaniesPage = ({ }) => {
    const colmns = [
        columnHelper.accessor('logo', {
            header: 'Logo',
            cell: (info) => {
                return (
                    <Avatar size='md' src={info.getValue()} />
                )
            }
        }),
        columnHelper.accessor('name', {
            header: 'Name',
        }),
        columnHelper.accessor('taxId', {
            header: 'Tax Id',
        }),
        columnHelper.accessor('about', {
            header: 'About',
        }),
        columnHelper.accessor('website', {
            header: 'Website',
        }),
        columnHelper.accessor('address', {
            header: 'Address',
        }),
        columnHelper.accessor('contractDetails', {
            header: 'Contract Details',
        }),
        columnHelper.accessor('hiringFee', {
            header: 'Hiring Fee',
        }),
        columnHelper.accessor('paymentTerms', {
            header: 'Payment Terms',
        }),
        columnHelper.display({
            header: 'Contacts',
            cell: (info) => {
                return (
                    <Stack direction='row' spacing={2}>
                        {info.row.original.contacts && <div>{info.row.original.contacts?.map((contact, index) => contact.name).join(', ')}</div>}
                    </Stack>
                )
            }
        }),
        columnHelper.display({
            header: 'Actions',
            cell: (info) => {
                return (
                    <Stack direction='row' spacing={2}>
                        <ManageEntity entity={info.row.original} title={'Company'} updateMutation={useUpdateCompanyMutation} config={config} />
                    </Stack>
                )
            }
        })
    ];

    const config = {
        title: "Company",
        layout: {
          columns: 2,
          fields: [
            [{name: "name", colSpan: 1}, {name: "logo", colSpan: 1, rowSpan: 2, width: "250px"}],
            ["taxId"],
            [{name: "about", colSpan:2 }],
            [{name: "website", colSpan:2 }],
            ["address", "contractDetails"],
            ["hiringFee", "paymentTerms"],
            [{name: "contacts", colSpan:2}]
          ]
        },
        fields: {
          id: {
            type: "string",
            title: "ID",
            readOnly: true
          },
          name: {
            type: "string",
            title: "Name",
            required: true,
            validation: {
              required: "Name is required"
            }
          },
          logo: {
            type: "image",
            title: "Logo"
          },
          taxId: {
            type: "string",
            title: "Tax ID"
          },
          about: {
            type: "textarea",
            title: "About"
          },
          website: {
            type: "url",
            title: "Website",
            validation: {
              pattern: {
                value: /https?:\/\/.+/,
                message: "Enter a valid URL"
              }
            }
          },
          address: {
            type: "textarea",
            title: "Address"
          },
          contractDetails: {
            type: "textarea",
            title: "Contract Details"
          },
          hiringFee: {
            type: "number",
            title: "Hiring Fee",
            validation: {
              min: {
                value: 0,
                message: "Hiring Fee must be at least 0"
              }
            }
          },
          paymentTerms: {
            type: "number",
            title: "Payment Terms",
            validation: {
              min: {
                value: 0,
                message: "Payment Terms must be a positive number"
              }
            }
          },
          contacts: {
            type: "contactField",
            title: "Contacts"
          }
        }
      };



    return (
        <>
            <Page breadcrumbs={breadCrumbs} title={'Companies'} columns={colmns} dataSourceQueryFn={useGetCompaniesQuery}>
                <div key='toolbar'>
                   <ManageEntity title={'Company'} createMutation={useCreateCompanyMutation} config={config}/>
                </div>
            </Page>
        </>
    )
}


export default ManageCompaniesPage