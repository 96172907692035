/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Checkbox,
	Flex,
	Stack,
	Box,
} from "@chakra-ui/react";
import { dimentions } from "src/app/libs/config";
import { useAddAssessmentTemplatesMutation, useUpdateAssessmentTemplatesMutation } from "src/rtk/exams.api";
import { useGetAllChallengesQuery } from "src/rtk/tasks.api";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import { useForm } from "react-hook-form";
import Row from "src/app/components/temp/Row";
import SelectField from "src/app/components/form-inputs/SelectField";
import TextAreaField from "src/app/components/form-inputs/TextAreaField";
import TextField from "src/app/components/form-inputs/TextField";
import { useGetChallengesQuery } from "src/rtk/challenges.api";
import { useCreateExamMutation, useUpdateExamMutation } from "src/rtk/exams.api";


const ManageExam = ({ exam, inputMapper, ...props }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const form = useForm({
		mode: 'all',
		defaultValues : {
		}
	});


	const { handleSubmit, reset } = form;

	let [trigger, result] = [];

	if (exam) {
		[trigger, result] = useUpdateExamMutation();
	} else {
		[trigger, result] = useCreateExamMutation();
	}


	const setData = (data) => {
		trigger(data);
	}

	const close = () => {
		reset({});
		onClose();
	};

	useEffect(() => {
		if (exam) {
			if (inputMapper) {
				reset(inputMapper(exam))
			} else {
				reset(exam)
			}
		}
		else {
			reset({})
		}
	}, [exam, reset])

	useEffect(() => {
		if (result?.isSuccess && !result?.isLoading) {
			close();
		}
	}, [result?.isLoading]);

	const onChallengeClicked = (props, setSelected, isOpen, onClose) => {
		const [reference, setReference] = useState();

		useEffect(() => {
			if (!reference && props.data.reference) {
				setReference(props.data.reference);
			}
		}, [reference, props.data]);


		const modifyChallenge = (e) => {
			const tasks = JSON.parse(JSON.stringify(props.getValue()));
			const currentChallenge = tasks.filter((item) => item.id === props.data['id'])[0];
			currentChallenge.reference = reference;
			setSelected(tasks);
		};

		return (<Modal isOpen={isOpen} onClose={onClose} {...props} onContextMenu={(e) => e.preventDefault()}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Modify {props.data['name']} Challenge:</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack w='full' spacing={0.5}>

						<Checkbox
							colorScheme='blue'
							isChecked={reference === 'solution' ? true : false}
							onChange={(e) => setReference(e.target.checked ? 'solution' : 'problem')}
						>
							Fully Implemented
						</Checkbox>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button variant='ghost' mr={3} onClick={onClose}>
						Close
					</Button>
					<Button colorScheme='blue' onClick={modifyChallenge}>Save</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>)
	}

	return (
		<>
			<Button colorScheme='green' px={8} onClick={onOpen}>
				{exam ? 'Edit Exam' : 'Add Exam'}
			</Button>

			<Modal isOpen={isOpen} onClose={close} size='2xl'>
				<ModalOverlay />
				<ModalContent p={4} borderRadius={dimentions.borderRadius.lg}>
					<ModalHeader>{exam ? 'Edit Exam' : 'Add Exam'}</ModalHeader>
					<ModalCloseButton />
					<ModalBody borderRadius={dimentions.borderRadius.lg}>
						<Stack as='form' onSubmit={handleSubmit((data) => setData(data))}>
							<Row>
								<Flex flex={1} alignItems='flex-end'>
									<TextField label={'Name'} fieldName={'name'} form={form} required={true} />
								</Flex>
								<Flex flex={1} alignItems='flex-end' justify='center'>
									<TextField fieldName={'durationTime'} form={form} label={'Duration Time'} />
									{/* <TimePicker onChange={setDurationTime} disableClock={true} format={"h:m"} /> */}
								</Flex>
							</Row>
							<Row>
								<SelectField fieldName={'challenges'} form={form} multiselect={true} keyName={'name'} valueName={'id'} label={'Challenges'} query={useGetChallengesQuery}
									valueManipulation={(value) => value?.map((challenge, idx) => ({
										id: challenge.id,
										order: idx + 1,
										namge: challenge.name,
										referenceName: challenge.reference ?? 'problem',
										configurationId: value?.configurationId ?? "6afeea69-4560-4710-a238-764d3272c4f6" // For now
									}))}
									multiValueClick={onChallengeClicked}
									width={'500px'} queryFilters={{ page: 1, pageSize: 1000, purpose: 'select' }} registerOptions={{
										validate: (value) => value?.length > 0 || 'Please add at least one challenge'
									}} />
							</Row>
							<Flex pt='64px'>
								<TextAreaField form={form} label={'Exam Description'} fieldName={'description'} registerOptions={{
									required: 'Exam Description is required'
								}} />
							</Flex>
							<Box>
								<Button
									size='md'
									borderRadius={dimentions.borderRadius.sm}
									colorScheme='blue'
									type='submit'
								>
									Save
								</Button>
							</Box>
						</Stack>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ManageExam;
