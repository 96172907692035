import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'

export function MenuInner({logo}: {logo: string | undefined}) {
  const intl = useIntl()
  return (
    <>
      <div style={
        {'margin': 'auto auto'}
      } >
          <img src={logo} style={{height: '60px'}} />
      </div>
    </>
  )
}
