import {Suspense, useContext, useEffect} from 'react'
import {Outlet, useNavigation} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'

import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import { LoadingContext } from 'src/providers/Loading/LoadingContext'



const App = () => {
  const navigation = useNavigation()
  const { setLoading } = useContext(LoadingContext)
  useEffect(() => {
    if (navigation.state === 'loading') {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [navigation.state])

  return (
    <>
    <ToastContainer />
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
              <Outlet />
              <MasterInit />
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
    </>
    
  )
}

export {App}
