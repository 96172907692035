import { colors, dimentions } from 'src/app/libs/config';
import {
	CircularProgress,
	CircularProgressLabel,
	Flex,
	Text,
} from '@chakra-ui/react';
import React from 'react';

const RoundedProgressCard = ({ title, color, count, progress, ...props }) => {
	return (
		<div className={`card card-flush mb-5 mb-xl-10`}>

		<div className='card-header pt-5'>
			<div className='card-title d-flex flex-column'>
				<div className='d-flex align-items-center'>
					<span className='fw-bold text-dark me-2 text-gray-400 pt-1 fw-semibold fs-6'>{title}</span>
				</div>
			</div>
		</div>

		<div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
			<div className='d-flex flex-center me-5 pt-2'>
			<CircularProgress
					size='120px'
					thickness='3px'
					value={progress}
					color={color}
					borderRadius={dimentions.borderRadius.lg}>
					<CircularProgressLabel
						fontSize='1rem'
						color={colors.textMG}
						fontWeight='medium'>
						{count}
					</CircularProgressLabel>
				</CircularProgress>
			</div>
		</div>
	</div >
	);
};

export default RoundedProgressCard;
