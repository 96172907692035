import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./base.api";
import { toast } from "react-toastify";
import { assessmentApi } from "./assessments.api";
import { examsApi } from "./exams.api";

const tags = ["Teammates", "Admins", "Managers", "Employees", "Position", "Candidates", "CandidatePositions"];
export const positionApi = createApi({
    reducerPath: "positionApi",
    baseQuery: axiosBaseQuery({
        baseUrl: "",
    }),
    tagTypes: tags,
    endpoints: (builder) => ({
        getPositions: builder.query({
			query: ({ count, pageSize = 10, sort, order, status, search, page = 1 } = {}) => ({
				url: `/management/position`,
				method: "GET",
				params: {
					page: page,
					pageSize: pageSize,
					...(search && { search: search }),
					...(status && { status: status }),
					...(count && { count: count }),
					...(sort && { sort: sort }),
					...(order && { order: order }),
				},
			}),
			providesTags: ["Position"],
		}),
		getAPosition: builder.query({
			query: ({ ids } = {}) => ({
				url: `/management/position`,
				method: "GET",
				params: {	
					ids,
				},
			}),
			providesTags: ["Position"],
		}),
		getTeammates: builder.query({
			query: ({ count, status, search, page = 1, pageSize = 10, roles } = {}) => ({
				url: `/management/user`,
				method: "GET",
				params: {
					pageSize: pageSize,
					page: page,
					...(search && { search: search }),
					...(status && { status: status }),
					...(count && { count: count }),
					...(roles && { roles: roles }),
				},
			}),
			providesTags: ["Teammates"],
		}),
		addTeammates: builder.mutation({
			query: (body) => {
				return {
					url: "/management/user",
					method: "POST",
					body: body,
				};
			},
			invalidatesTags: ["Teammates"],
		}),
		updateTeammate: builder.mutation({
			query: (body) => {
				return {
					url: "/management/user",
					method: "PATCH",
					body: body,
					params: {
						id: body.id,
					},
				};
			},
			invalidatesTags: ["Teammates"],
		}),
		getSelf: builder.query({
			query: () => ({
				url: `/user`,
				method: "GET",
			}),
		}),
		getCandidates: builder.query({
			query: ({ page, search } = {}) => ({
				url: `/management/candidate`,
				method: "GET",
				params: {
					pageSize: 10,
					page: page - 1,
					search,
				},
			}),
			providesTags: ['Candidates']
		}),
		getPositionsOverview: builder.query({
			query: () => ({
				url: `/management/position/overview`,
				method: "GET",
			}),
			providesTags: ["Position"],
		}),
		addPosition: builder.mutation({
			query: (body) => ({
				url: `/management/position`,
				method: "POST",
				body: body,
				validateStatus: (response, body) => {
					if (response.ok) {
							toast.success("Position created successfully", {
							position: toast.POSITION.TOP_RIGHT,
							theme: 'colored',
							pauseOnHover: false,
							pauseOnFocusLoss: false
						})
					}
					return response.ok;
				},
			}),
			invalidatesTags: ["Position"],
		}),
		updatePosition: builder.mutation({
			query: (body) => ({
				url: `/management/position`,
				method: "PATCH",
				body: body,
				params: {
					id: body.id,
				},
			}),
			invalidatesTags: ["Position"],
		}),
		deleteTeammate: builder.mutation({
			query: ({ id } = {}) => ({
				url: `/management/user/`,
				method: "DELETE",
				params: {
					id,
				},
			}),
			invalidatesTags: ["Teammates"],
		}),
		getPositionsByJobTitle: builder.query({
			query: () => ({
				url: `/management/position/overview`,
				method: "GET",
				params: {
					groupBy: "job_title",
				},
			}),
		}),

		getPositionsEnum: builder.query({
			query: () => ({
				url: "/position",
				method: "GET",
			}),
		}),

		attachPositionToCandidate: builder.mutation({
			query: (body) => ({
				url: "/management/position/attach",
				body: body,
				method: "POST",
			}),
			invalidatesTags: (result, _, params) => ([{type: 'CandidatePositions', id: params.userId}])
		}),

		getCandidatePositions: builder.query({
			query: ({ candidateId, examId, page = 1, pageSize = 10 } = {}) => ({
				url: `/position`,
				method: "GET",
				params: {
					candidateId: candidateId,
					page: page,
					pageSize: pageSize,
					...(examId && {examId: examId}),
				},
			}),
            keepUnusedDataFor: 3600,
			providesTags: (result, error, args) => args ? [{ type: 'CandidatePositions', id: args?.candidateId}] : ['CandidatePositions'],
			
		}),

		createCandidateAndAttachPosition: builder.mutation({
			query: (body) => ({
				url: `/management/position/CreateAndAttach`,
				method: "POST",
				body: body
			}),
			invalidatesTags: ["Position", "Candidates"],
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				// dispatch(assessmentApi.util.invalidateTags(["Position"]));
				dispatch(examsApi.util.invalidateTags(["Position"]));
			},
		}),
    }),
});

export const {
    useGetPositionsQuery,
	useGetCandidatesQuery,
	useGetTeammatesQuery,
	useGetSelfQuery,
	useAddTeammatesMutation,
	useGetPositionsOverviewQuery,
	useAddPositionMutation,
	useDeleteTeammateMutation,
	useGetPositionsByJobTitleQuery,
	useGetPositionsEnumQuery,
	useUpdateTeammateMutation,
	useUpdatePositionMutation,
	useGetAPositionQuery,
	useAttachPositionToCandidateMutation,
	useGetCandidatePositionsQuery,
	useCreateCandidateAndAttachPositionMutation
} = positionApi;
