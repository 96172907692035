import { Tag } from "@chakra-ui/react";
import React from "react";

const StatusTag = ({ children }) => {
	return (
		<Tag colorScheme={children ? "green" : "red"}>
			{children ? "Active" : "Inactive"}
		</Tag>
	);
};

export default StatusTag;
