/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Flex,
	Stack,
	Box,
} from "@chakra-ui/react";
import { dimentions } from "src/app/libs/config";
import {
	useAddPositionMutation,
	useUpdatePositionMutation,
	useGetTeammatesQuery
} from "src/rtk/positions.api";
import { useGetAllAssessmentTemplatesQuery } from "src/rtk/exams.api";
import {
	useGetDepartmentsQuery,
	useCreateDepartmentMutation
} from "src/rtk/departments.api";
import SelectField from "src/app/components/form-inputs/SelectField";
import { useForm } from "react-hook-form";
import TextField from "src/app/components/form-inputs/TextField";
import TextAreaField from "src/app/components/form-inputs/TextAreaField";
import Row from "src/app/components/temp/Row";
import DepartmentField from "src/app/components/form-inputs/DepartmentField";
import { useGetExamsQuery } from "src/rtk/exams.api";
import RenderTextWithLineBreaks from './RenderTextWithLineBreaks'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import axios from "axios";
import { api } from "src/app/libs/api";
import { renderChildren } from "src/app/libs/utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAuth } from "../auth";
import { useGetCandidateResumeUrlMutation } from "src/rtk/candidates.api";




const ShowCandidateResume = ({ candidate, buttonName, children, color, classNames, ...props }) => {
	const { isOpen, onOpen, onClose } = useDisclosure("");
	const {auth} = useAuth();

	const close = () => {
		onClose();
	};

	const [get1ResumeRedirectUrl, getResumeRedirectUrlResult] = useGetCandidateResumeUrlMutation();


	const [resumeRedirectUrl, setResumeRedirectUrl] = React.useState(null);

	  async function getResumeRedirectUrl(candidateId) {
		const res = await get1ResumeRedirectUrl({candidateId});

		let url = `${api.baseUrl}Candidate/resume/${res.data.accessId}`;

		if (url.indexOf("https") === -1)
			url = `${window.location.origin}${url}`

		setResumeRedirectUrl(url);
	  }

	  useEffect(() => {
		if (isOpen)
		  getResumeRedirectUrl(candidate.id);
	  }, [isOpen]);

	return (
		<>
			{children ? 
			<OverlayTrigger
				placement="top"
				delay={{ show: 250, hide: 400 }}
				overlay={(props) => (
					<Tooltip id="button-tooltip" {...props}>
						Show Resume
					</Tooltip>
				)}
			>
				{renderChildren(children, 'button', {onClick: onOpen})[0]}
			</OverlayTrigger>
			
			 :
				<Button size='sm' color={color ?? '#A1A5B7'} className={classNames} onClick={onOpen}>
					{buttonName ?? 'Show'}
				</Button>
			}

			<Modal isOpen={isOpen} onClose={close} size={"6xl"}>
				<ModalOverlay />
				<ModalContent p={4} borderRadius={dimentions.borderRadius.lg}>
					<ModalHeader>{candidate.name}'s Resume</ModalHeader>
					<ModalCloseButton />
					<ModalBody borderRadius={dimentions.borderRadius.lg}>
						{ isOpen && 
						 resumeRedirectUrl &&
						<DocViewer documents={[{ uri: resumeRedirectUrl, fileName: `${candidate.name}` }]} prefetchMethod="GET" pluginRenderers={DocViewerRenderers} config={{
							header: {
								disableHeader: true,
								disableFileName: true
							}
						}}  style={{ width: '100%', height: '80vh', overflow: 'scroll' }} /> 
						}
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ShowCandidateResume;
