import React from 'react';

const TextShortener = ({ text }) => {
  const maxLength = 160;

  const shortenText = (text) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  };

  return <>{shortenText(text)}</>;
};

export default TextShortener;