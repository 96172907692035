import { Children, cloneElement } from 'react'

export const renderChildren = (children, key, props) => {
    const arrayChildren = Children.toArray(children);
    const filter = arrayChildren.filter((child) => {
        return child.key === `.$${key}`
    });

    if (filter.length === 0) {
        return <></>
    }

    return Children.map(filter, child => {
        if (!props)
            return child;
        return cloneElement(child, {...props})
    })
}