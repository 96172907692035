/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, PageLink } from 'src/_metronic/layout/core'

import { Link, useLoaderData, useParams, useRouteLoaderData } from "react-router-dom"


import { Avatar, Flex, Stack, Text, Button } from '@chakra-ui/react'
import { colors, dimentions } from 'src/app/libs/config'
import { Can } from 'src/app/routing/AppRoutes'
import { useCheckVsCodeAvailablityQuery } from 'src/rtk/challenges.api'
import { BeatLoader } from 'react-spinners'
import ReactMarkdown from "react-markdown"


export const ChallengeIntroPage = ({ breadCrumbs }: { breadCrumbs?: any }) => {
    const { challenge } = useRouteLoaderData("challenge")
    const { exam } = useRouteLoaderData("exam")
    const { examId, challengeId } = useParams()
    const [nextStepAvailable, setNextStepAvailable] = useState(false)
    const isVsCodeUpSuccessRef = useRef();

    const { data: isVsCodeUp, isLoading: isLoading, isError: isError, isSuccess: isSuccess } = useCheckVsCodeAvailablityQuery({
        examId, challengeId
    }, {
        pollingInterval: 1,
        skip: isVsCodeUpSuccessRef.current
    })

    useEffect(() => {
        isVsCodeUpSuccessRef.current = isSuccess;
        if (isSuccess) {
            setNextStepAvailable(true)
        }
    }, [isSuccess, setNextStepAvailable]);

    // const breadcrumbs: Array<PageLink> = breadCrumbs ?? [
    //     {
    //         title: 'Account',
    //         path: '#',
    //         isSeparator: false,
    //         isActive: false
    //     },
    //     {
    //         title: '',
    //         path: '',
    //         isSeparator: true,
    //         isActive: false
    //     },
    //     {
    //         title: 'Exams',
    //         path: '/account/exams',
    //         isSeparator: false,
    //         isActive: false
    //     },
    //     {
    //         title: '',
    //         path: '',
    //         isSeparator: true,
    //         isActive: false
    //     },
    //     {
    //         title: exam.assessmentTemplate.name,
    //         path: `/account/exams/${examId}`,
    //         isSeparator: false,
    //         isActive: false
    //     },
    //     {
    //         title: '',
    //         path: '',
    //         isSeparator: true,
    //         isActive: false
    //     }
    // ]


    return (
        <>
            <PageTitle>
                {challenge.data[0].challenge.name}
            </PageTitle>
            <KTCard flush={true}>
                <KTCardBody>
                    <ReactMarkdown>
                        {challenge?.data[0]?.challenge.description}
                    </ReactMarkdown>

                    <div className='d-flex flex-row-reverse'>
                        <Link to={`env`} state={{ containerAvailable: true }} style={{pointerEvents: `${nextStepAvailable? 'unset' : 'none'}`}}>
                            <Button isLoading={!nextStepAvailable} spinner={<BeatLoader size={8} color='gray' />} className='btn btn-light'>Let&apos;s Go!</Button>
                        </Link>
                    </div>

                </KTCardBody>
            </KTCard>
        </>
    )
}
