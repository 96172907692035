import { Grid } from '@chakra-ui/react';
import React, {Children} from 'react';

const Row = ({ children }) => (
	<Grid gridTemplateColumns={`repeat(${Children.toArray(children).length}, 1fr)`} gap={6}>
		{children}
	</Grid>
);

export default Row;
