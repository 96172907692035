/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData } from "react-router-dom"
import DataTable from 'src/app/components/DataTable'

import { skipToken } from "@reduxjs/toolkit/query"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button, Tag } from '@chakra-ui/react';
import { Toolbar } from 'src/_metronic/layout/components/toolbar/Toolbar'
import { ToolbarReports } from 'src/_metronic/layout/components/toolbar/toolbars'
import { Page } from 'src/app/components/Page'
import { PositionsChart } from 'src/app/components/charts/PositionsChart'
import { colors } from 'src/app/libs/config'
import Row from 'src/app/components/temp/Row'
import { Can } from 'src/app/routing/AppRoutes'
import DepartmentTag from 'src/app/components/Tags/DepartmentTag'
import StatusTag from 'src/app/components/Tags/StatusTag'
import { useGetPositionsQuery } from 'src/rtk/positions.api'
import RoundedProgressCard from 'src/app/components/charts/RoundedProgressCard'
import moment from "moment";
import { useFindCandidateJobsMutation, useGetCandidatesQuery } from 'src/rtk/drushim.api'
import UploadResume from './UploadResume'
import ShowCandidateResume from './ShowCandidateResume'
import { toast } from "react-toastify"
// import ManagePosition from './components/ManagePosition'


const columnHelper = createColumnHelper()

const breadCrumbs: Array<PageLink> = [
    // {
    //     title: 'Management',
    //     path: '#',
    //     isSeparator: false,
    //     isActive: false
    // },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: true,
    },
]


export const ManageDrushimCandidatesPage = ({ }) => {
    const { candidates } = useLoaderData();
    const [trigger, result] = useFindCandidateJobsMutation();
    
    const findJobs = (id) => {
        trigger(id)
    }

    useEffect(() => {
		if (result?.isSuccess && !result?.isLoading) {
			toast.success("Start searching for jobs", {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
                pauseOnHover: false,
                pauseOnFocusLoss: false
            })
		}
	}, [result?.isLoading]);

    const colmns = [
        columnHelper.accessor('name', {
            header: 'Name',
        }),
        columnHelper.accessor("title", {
            header: 'Title'
        }),
        columnHelper.accessor("phone", {
            header: 'Phone'
        }),
        columnHelper.accessor("email", {
            header: 'Email'
        }),
        columnHelper.accessor("highestMatchingScore", {
            header: 'Matching Score',
            cell: (info) => {
                try {
                    return (<span className="badge badge-light-primary">{(info.getValue()).toFixed(1)}</span>) 
                }
                catch (e) {
                    return 'N/A'
                }
            }
        }),
        columnHelper.accessor('id', {
            header: 'Jobs',
            enableSorting: false,
            cell: (info) => {
                return (
                    <Link key={info.getValue()} to={info.getValue()}>
                        <Button size='sm' colorScheme='blue' color='white'>
                            Show
                        </Button>
                    </Link>
                )
            }
        }),
        columnHelper.display({
            header: 'Action',
            enableSorting: false,
            cell: (info) => {
                return (
                    <Button size='sm' colorScheme='blue' color='white' onClick={() => findJobs(info.row.original.id)}>
                        Find Jobs
                    </Button>
                )
            }
        }),
        columnHelper.display({
            header: 'Resume',
            enableSorting: false,
            cell: (info) => {
                return (
                    // <Link key={info.getValue()} to={info.getValue()}>
                    //     <Button size='sm' color='#A1A5B7'>
                    //         Show
                    //     </Button>
                    // </Link>
                    <ShowCandidateResume candidate={info.row.original} />
                )
            }
        }),
        // columnHelper.accessor('recruiterName', {
        //     header: 'Recruiter Name'
        // }),
        // columnHelper.accessor("departmentId", {
        //     header: 'Department',
        //     cell: (info) => {
        //         return (
        //             <DepartmentTag>{info.getValue()}</DepartmentTag>
        //         )
        //     },
        // }),
        // columnHelper.accessor('candidates', {
        //     header: 'Associated Candidates',
        //     cell: (info) => `${info.getValue().length}`,
        //     enableSorting: false
        // }),
        // columnHelper.accessor('engagement', {
        //     header: 'Engagement',
        //     cell: (info) => `${info.getValue()??0}%`,
        //     enableSorting: false
        // }),
        // columnHelper.accessor('status', {
        //     header: 'Job Status',
        //     cell: (info) => (
        //         <Tag colorScheme='blue'>
        //             {info.getValue()}
        //         </Tag>
        //     )
        // }),
        // columnHelper.accessor('id', {
        //     header: 'Details',
        //     enableSorting: false,
        //     cell: (info) => {
        //         return (
        //             <Link key={info.getValue()} to={info.getValue()}>
        //                 <Button size='sm' color='#A1A5B7'>
        //                     Go to Position
        //                 </Button>
        //             </Link>
        //         )
        //     }
        // })
    ];


    return (
        <>
            <Page breadcrumbs={breadCrumbs} title={'Candidates'} columns={colmns} dataSourceQueryFn={useGetCandidatesQuery}>
                <div key='toolbar'>
                <UploadResume />
                </div>
            </Page>
        </>
    )
}


export default ManageDrushimCandidatesPage