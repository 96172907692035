import {BaseLoader} from "../../common/base.loader"
import { candidateApi } from '../../rtk/candidates.api'
import { assessmentApi } from '../../rtk/assessments.api'
import { positionApi } from "src/rtk/positions.api";
import { defer } from "react-router-dom";
import { examsApi } from "src/rtk/exams.api";


export class CandidatesLoader extends BaseLoader {
    listCandidates = async ({ request }) => {
        const candidatesLoader = this._loader(
            candidateApi.endpoints.getCandidates,
            request,
            {
                page: 1,
                pageSize: 10
            }
        );

        const candidatesSummaryLoader = this._loader(
            candidateApi.endpoints.getCandidatesOverview,
            request
        );

        const assessmentsOverviewLoader = this._loader(
            assessmentApi.endpoints.getPositionAssessmentsOverview,
            request,
            {

            }
        );

        const [candidates, candidatesSummary, assessmentsOverview] = await Promise.all([candidatesLoader, candidatesSummaryLoader, assessmentsOverviewLoader])
        return { candidates, candidatesSummary, assessmentsOverview };
    };

    getCandidate = async ({ request, params }) => {
        
        const getCandidateLoader = this._loader(
            candidateApi.endpoints.getCandidates,
            request,
            {
                page: 1,
                pageSize: 1,
                id: params.candidateId
            }
        );

        const [candidate] = await Promise.all([getCandidateLoader])
        
        return { candidate: candidate.data[0] };
    };

    getCandidatePositions = async ({ request, params }) => {

        const getCandidatePositionsLoader = this._loader(
            positionApi.endpoints.getCandidatePositions,
            request,
            {
                candidateId: params.candidateId,
                page: 1,
                pageSize: 10
            }
        );

        const getPositionsLoader = this._loader(
            positionApi.endpoints.getPositions,
            request,
            {
                page: 1,
                pageSize: 1000
            }
        );

        const [candidatePositions, positions] = await Promise.all([getCandidatePositionsLoader, getPositionsLoader])
        return { candidatePositions, positions }
    };


    getCandidateExam = async ({ request, params }) => {

        const getCandidateExamLoader = this._loader(
            examsApi.endpoints.getClones,
            request,
            {
                id: params.examId,
                page: 1,
                pageSize: 1
            }
        );

        const [exam] = await Promise.all([getCandidateExamLoader])
        return { exam: exam.data[0] }
    };

    getMyExam = async ({ request, params }) => {

        const getCandidateExamLoader = this._loader(
            examsApi.endpoints.getMyExam,
            request,
            {
                id: params.examId,
                page: 1,
                pageSize: 1
            }
        );

        const [exam] = await Promise.all([getCandidateExamLoader])
        return { exam: exam.data[0] }
    };

}