import { challengesApi } from "src/rtk/challenges.api";
import {BaseLoader} from "../../common/base.loader"


export class ChallengesLoader extends BaseLoader {
    getChallengeIntroData = async ({ request, params }) => {

        const challenge = await this._loader(
            challengesApi.endpoints.getChallenge,
            request,
            params.challengeId
        );


        if (challenge.data[0].status === 'Created') {
            await this._loader(
                challengesApi.endpoints.initChallenge,
                request,
                {
                    challengeId: params.challengeId,
                    examId: params.examId
                }
            );
        }
        else if (challenge.data[0].status === 'Finished') {
            throw Error('Challenge Already Submitted')
        }

        return { challenge }
    };

    checkIfVsCodeAvailable = async ({ request, params }) => {

        const isVsCodeAvailable = await this._loader(
            challengesApi.endpoints.checkVsCodeAvailablity,
            request,
            {
                challengeId: params.challengeId,
                examId: params.examId
            }
        );

        return { isVsCodeAvailable }
    };
}