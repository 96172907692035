import React, { useRef, useState } from "react";
import PhotoInput from "./PhotoInput";
import { Controller } from "react-hook-form";

const ImageField = ({ imagePlaceholder, width, form, fieldName, registerOptions, ...props }) => {
	
	const { getValues, control } = form;
	const register = form.register(fieldName, registerOptions);
	const { onChange, ref, ...rest } = register;

	const setImageData = (file) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = function () {
			const dataUrl = reader.result
			onChange({
				target: {
					name: fieldName,
					value: dataUrl
				}
			});
		}
	};

	const inputRef = useRef();

	return (
		<>
			<Controller
				name={fieldName}
				control={control}
				render={({ field, fieldState, formState}) => {
					return (
						<>
							<input
								type='file'
								ref={(e) => {
									ref(e)
									inputRef.current = e
								}
								}
								{...rest}
								style={{ display: "none" }}
								accept=".png, .jpg, .jpeg" 
								onChange={(e) => setImageData(e.target.files[0])}
							/>
							<PhotoInput
								w={width ?? '100px'}
								onClick={() => inputRef?.current?.click()}
								src={getValues(fieldName)}
								imagePlaceholder={'/media/avatars/blank.png'}
								{...props}
							/>
						</>
					)
				}
				}>

			</Controller>

		</>
	);
};

export default ImageField;
