import {BaseLoader} from "../../common/base.loader"
import { assessmentApi } from '../../rtk/assessments.api'
import { positionApi } from "src/rtk/positions.api";
import { defer } from "react-router-dom";
import { departmentApi } from "src/rtk/departments.api";
import { examsApi } from "src/rtk/exams.api";


export class PositionsLoader extends BaseLoader {
    getPositionsPageData = async ({ request, params }) => {
        const getPositionsLoader = this._loader(
            positionApi.endpoints.getPositions,
            request, 
            {
                page: 1,
                pageSize: 10
            }
        );

        const getPositionsOverviewLoader = this._loader(
            positionApi.endpoints.getPositionsOverview,
            request
        );

        const getPositionDepartmentsLoader = this._loader(
            departmentApi.endpoints.getPositionDepartments,
            request
        );

        const [ positions, positionsOverview, departments ] = await Promise.all([getPositionsLoader, getPositionsOverviewLoader, getPositionDepartmentsLoader])
        return { positions, positionsOverview, departments }
    };

    getPositionPageData = async ({ request, params }) => {
        const getPositionAssessmentsOverviewLoader = this._loader(
            examsApi.endpoints.getPositionExamsOverview,
            request, 
            {
                positionId: params.positionId
            }
        );

        const getPositionLoader = this._loader(
            positionApi.endpoints.getAPosition,
            request, 
            {
                ids: params.positionId
            }
        );

        const getClonedExamsLoader = this._loader(
            examsApi.endpoints.getClones,
            request,
            {
                page: 1,
                pageSize: 10,
                purpose: 'list',
                associatedId: params.positionId
            }
        );

        const [ positionAssessmentsOverview, position, exams ] = await Promise.all([getPositionAssessmentsOverviewLoader, getPositionLoader, getClonedExamsLoader])
        return { positionAssessmentsOverview, position, exams }
    };

    getMyPositions = async ({ request, params }) => {
        const getPositionsLoader = this._loader(
            positionApi.endpoints.getCandidatePositions,
            request,
            {
                page: 1,
                pageSize: 10
            }
        );


        const [ candidatePositions ] = await Promise.all([getPositionsLoader])
        return { candidatePositions }
    };
}