/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData, useRouteLoaderData } from "react-router-dom"
import DataTable from 'src/app/components/DataTable'

import { skipToken } from "@reduxjs/toolkit/query"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button, Tag } from '@chakra-ui/react';
import { Toolbar } from 'src/_metronic/layout/components/toolbar/Toolbar'
import { ToolbarReports } from 'src/_metronic/layout/components/toolbar/toolbars'
import { Page } from 'src/app/components/Page'
import { PositionsChart } from 'src/app/components/charts/PositionsChart'
import { colors } from 'src/app/libs/config'
import Row from 'src/app/components/temp/Row'
import { Can } from 'src/app/routing/AppRoutes'
import DepartmentTag from 'src/app/components/Tags/DepartmentTag'
import StatusTag from 'src/app/components/Tags/StatusTag'
import { useGetPositionsQuery } from 'src/rtk/positions.api'
import RoundedProgressCard from 'src/app/components/charts/RoundedProgressCard'
import moment from "moment";
import { useGetCandidateJobsQuery, useGetCandidatesQuery } from 'src/rtk/drushim.api'
import UploadResume from './UploadResume'
// import ManagePosition from './components/ManagePosition'


const columnHelper = createColumnHelper()

let breadCrumbs: Array<PageLink> = [

]


export const CandidateJobReportPage = ({ }) => {
    const { candidate, jobs } = useRouteLoaderData('candidate');
    const { report } = useLoaderData();

    const job = jobs.find(job => job.jobId === report.jobId);

    breadCrumbs = [
        {
            title: 'Candidates',
            path: '/management/drushim',
            isSeparator: false,
            isActive: false,
        },
        {
            isSeparator: true,
        },
        {
            title: candidate.name,
            path: '/management/drushim/' + candidate.id,
            isSeparator: false,
            isActive: false,
        },
        {
            isSeparator: true,
        },
        {
            title: job.title
        },
        {
            title: 'Report',
            path: '',
            isSeparator: true,
            isActive: true,
        },
    ]

    const colmns = [
        columnHelper.accessor('companyLogo', {
            header: '',
            cell: (info) => (
                <img src={info.row.original.companyLogo} />
            ),
        }),
        columnHelper.accessor('companyName', {
            header: 'Company Name',
        }),
        columnHelper.accessor("title", {
            header: 'Title'
        }),
        columnHelper.accessor("score", {
            header: 'Score',
            cell: (info) => {
                return (
                    <div className='progress w-100px w-xl-150px w-xxl-300px h-25px bg-light-primary'>
                        <div
                            className='progress-bar rounded bg-primary fs-7 fw-bold'
                            role='progressbar'
                            style={{ width: (info.getValue() / 10 * 100).toFixed(0) + '%' }}
                            aria-valuenow={info.getValue()}
                            aria-valuemin={0}
                            aria-valuemax={10}
                        >
                            {(info.getValue() / 10 * 100).toFixed(0)}%
                        </div>
                    </div>
                    // <RoundedProgressCard value={info.getValue()} />
                )
            }
        }),
        columnHelper.accessor('id', {
            header: 'Report',
            enableSorting: false,
            cell: (info) => {
                return (
                    <Link key={info.getValue()} to={info.getValue()}>
                        <Button size='sm' colorScheme='blue' color='white'>
                            Show
                        </Button>
                    </Link>
                )
            }
        }),
        columnHelper.accessor("link", {
            header: 'Link',
            cell: (info) => {
                return (
                    <a href={info.getValue()} target='_blank'>
                        <Button size='sm' color='#A1A5B7'>
                            Open
                        </Button>
                    </a>
                )
            }
        })]



    return (
        <>
            <Page breadcrumbs={breadCrumbs} title={`Report`}>
                <div key={'card'}>
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bold fs-1 mb-1'>Candidate Evaluation</span>
                            <span className='text-muted mt-1 fw-semibold fs-3'>{report.summary.overall_summary}</span>
                            <span className='text-muted mt-1 fw-semibold fs-3'>Match Score: {(report.overallScore).toFixed(1)}</span>
                        </h3>
                    </div>
                    <div className='card-body py-3'>

                        <div className='table-responsive'>

                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>

                                <thead>
                                    <tr className='fw-bold text-primary'>
                                        <th className='min-w-150px'>Requirement</th>
                                        <th className='min-w-80px'>Must/Advantage</th>
                                        <th className='min-w-80px'>Qualified (Yes/No)</th>
                                        <th className='min-w-100px'>Score</th>
                                        <th className='min-w-220px'>Comments</th>
                                        <th className='min-w-70px'>Weight</th>
                                        {/* <th className='min-w-100px text-end'>Actions</th> */}
                                    </tr>
                                </thead>

                                <tbody>
                                {report.summary.qualifications.map((qualification) => (
                                    <tr>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {/* <div className='symbol symbol-45px me-5'>
                                                    <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
                                                </div> */}
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                        {qualification.requirement}
                                                    </a>
                                                    {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                        HTML, JS, ReactJS
                                                    </span> */}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {/* <div className='symbol symbol-45px me-5'>
                                                    <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
                                                </div> */}
                                                <div className='d-flex justify-content-start flex-column'>
                                                    {/* <a href='#' className='text-dark fw text-hover-primary fs-6'>
                                                        Ana Simmons
                                                    </a> */}
                                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                        {qualification.must && 'Must'}
                                                        {!qualification.must && 'Advantage'}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        <span className="badge badge-light-primary">
                                                {qualification.qualified && 'Yes'}
                                                {!qualification.qualified && 'No'}
                                                    </span>
                                                    </span>
                                        </td>
                                        <td className='text-end'>
                                            <div className='d-flex flex-column w-100 me-2'>
                                                <div className='d-flex flex-stack mb-2'>
                                                    <span className='text-muted me-2 fs-7 fw-semibold'>{(qualification.score).toFixed(1)}</span>
                                                </div>
                                                <div className='progress h-6px w-100'>
                                                    <div
                                                        className='progress-bar bg-primary'
                                                        role='progressbar'
                                                        style={{ width: (qualification.score / 10 * 100).toFixed(0)+'%' }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex  flex-shrink-0'>
                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                        {qualification.comments}
                                                    </span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex  flex-shrink-0'>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                            {qualification.weight}
                                                        </span>
                                            </div>
                                        </td>
                                    </tr>))}
                                </tbody>

                            </table>

                        </div>

                    </div>
                    <div className='card-footer border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bold fs-3 mb-1'>Qualification Score: {(report.summary.overall_score).toFixed(1)}</span>
                        </h3>
                    </div>
                    <div className='card-body py-3 mt-10'>

                        <div className='table-responsive'>

                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>

                                <thead>
                                    <tr className='fw-bold text-primary'>
                                        <th className='min-w-150px'>Element</th>
                                        <th className='min-w-180px'>Score</th>
                                        <th className='min-w-220px'>Comments</th>
                                    </tr>
                                </thead>

                                <tbody>
                                {report.attractivenessSummary?.qualifications.map((qualification) => (
                                    <tr>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                {/* <div className='symbol symbol-45px me-5'>
                                                    <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
                                                </div> */}
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                        {qualification.aspect}
                                                    </a>
                                                    {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                        HTML, JS, ReactJS
                                                    </span> */}
                                                </div>
                                            </div>
                                        </td>

                                        <td className='text-end'>
                                            <div className='d-flex flex-column w-100 me-2'>
                                                <div className='d-flex flex-stack mb-2'>
                                                    <span className='text-muted me-2 fs-7 fw-semibold'>{(qualification.score).toFixed(1)}</span>
                                                </div>
                                                <div className='progress h-6px w-100'>
                                                    <div
                                                        className='progress-bar bg-primary'
                                                        role='progressbar'
                                                        style={{ width: (qualification.score / 10 * 100).toFixed(0) + '%' }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                {qualification.justification}
                                                    </span>
                                        </td>
                                    </tr>))}
                                </tbody>

                            </table>

                        </div>

                    </div>
                    <div className='card-footer border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bold fs-3 mb-1'>Position Attractiveness: {(report.trueMatchScore).toFixed(1)}</span>
                        </h3>
                    </div>
                </div>

            </Page>
        </>
    )
}


export default CandidateJobReportPage