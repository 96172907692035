import React, { useRef, useState } from "react";
import Select from "react-select";
import { useUpdateJobMatchStatusMutation } from "src/rtk/drushim.api";

const MatchStatusInput = ({ matchId, status, ...props }) => {
    const inputRef = useRef();

    const options = [
        { label: 'Allocated match', value: 100 },
        { label: 'Candidate invited', value: 200 },
        { label: 'Applied to job', value: 300 },
        { label: 'QF Screening', value: 400 },
        { label: 'Company Screening', value: 500 },
        { label: 'Company Interviews', value: 600 },
        { label: 'Declined by company', value: 700 },
        { label: 'Approved by company', value: 800 },
        { label: 'Contract Negotiation', value: 900 },
        { label: 'Hired', value: 1000 }
    ];

    const [updateJobMatchStatus, result] = useUpdateJobMatchStatusMutation();

    // Find the option that matches the initial status value
    const initialOption = options.find(option => option.value === status);

    const [selectedOption, setSelectedOption] = useState(initialOption);

    const onSelectChanged = (selectedOption) => {
        setSelectedOption(selectedOption);
        updateJobMatchStatus({ matchId, status: selectedOption.value });
    }

    return (
        <>
            <Select
                isLoading={!options}
                options={options}
                value={selectedOption}
                onChange={onSelectChanged}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
            />
        </>
    );
};

export default MatchStatusInput;
