import { Flex, Heading, Stack, Text } from "@chakra-ui/react"
import React from "react"
import Container from "./helpers/Container"
import SubHeading from "./helpers/SubHeading"
import Chart from 'react-apexcharts';
import { KTIcon } from "src/_metronic/helpers";
import { useThemeMode } from "src/_metronic/partials";

export const PositionsChart = ({ data, color, amount, title, values, total }) => {
	const options = {
		dataLabels: { enabled: false },
		legend: {
			show: false,
		},
		labels: data,
		sparkline: {
			enabled: true,
		},
		colors: color,
		plotOptions: {
			pie: {
				offsetX: -12,
				expandOnClick: true,
				donut: {
					size: "60%",
				},
			},
		},
	};
	const series = values;
	const { mode } = useThemeMode()


	return (
		<div className={`card card-flush mb-5 mb-xl-10`}>

			<div className='card-header pt-5'>
				<div className='card-title d-flex flex-column'>
					<div className='d-flex align-items-center'>
						<span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{total}</span>

						{/* <span className='badge badge-light-success fs-base'>
				<KTIcon iconName='arrow-up' className='fs-5 text-success ms-n1' /> 2.2%
			  </span> */}
					</div>
					<span className='text-gray-400 pt-1 fw-semibold fs-6'>{title}</span>
				</div>
			</div>

			<div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
				<div className='d-flex flex-center me-5 pt-2'>
					<Chart
						height={120}
						width={120}
						options={options}
						series={series}
						type='donut'
					/>
				</div>

				<div className='d-flex flex-column content-justify-center flex-row-fluid'>
					{data?.map((item, i) => (
						<>
							<div className='d-flex fw-semibold align-items-center'>
								<div className={`bullet w-8px h-3px rounded-2 me-3`} style={{ backgroundColor: color[i] }}></div>
								<div className='text-gray-500 flex-grow-1 me-4'>{item}</div>
								<div className='fw-bolder text-gray-700 text-xxl-end'>{amount[i]}</div>
							</div>
						</>
					))}
				</div>
			</div>
		</div >
	);
};
