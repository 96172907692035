/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from 'src/_metronic/helpers'
import { Link, useLoaderData, useLocation, useParams } from 'react-router-dom'
import { Dropdown1 } from 'src/_metronic/partials'
import { useGetCandidatesQuery } from 'src/rtk/candidates.api'
import { useUserAbilities } from 'src/app/libs/ability'
import { ProfileHeader } from 'src/app/modules/profile/ProfileHeader'

const ProfileHeaderWrapper: React.FC = () => {
  const location = useLocation()
  const { candidateId, examId } = useParams()
  const { can } = useUserAbilities()

  const { data: data, isError: error } = useGetCandidatesQuery({
    page: 1,
    pageSize: 1,
    id: candidateId
  })

  const [candidate, setCandidate] = useState(data.data[0])

  useEffect(() => {
    setCandidate(data.data[0])
  }, [data])

  return (
    <ProfileHeader user={candidate} hide={examId !== undefined} location={location}>
        <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/management/candidates/${candidateId}/profile` && 'active')
                }
                to={`/management/candidates/${candidateId}/profile`}
              >
                Profile
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === `/management/candidates/${candidateId}/exams` && 'active')
                }
                to={`/management/candidates/${candidateId}/exams`}
              >
                Exams
              </Link>
            </li>
    </ProfileHeader>
  )
}

export { ProfileHeaderWrapper }
