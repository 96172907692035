/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData, useMatches, useRouteLoaderData } from "react-router-dom"
import {
    useGetCandidatesQuery,
    useGetCandidatesOverviewQuery
} from 'src/rtk/candidates.api'
import { skipToken } from "@reduxjs/toolkit/query"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button } from '@chakra-ui/react';
import { colors } from 'src/app/libs/config'
import { ProfileDetails } from 'src/app/modules/profile/components/account/ProfileDetails'
import { Can } from 'src/app/routing/AppRoutes'
import { ProfilePage } from 'src/app/modules/profile/ProfilePage'
import { useUpdateCandidateMutation } from 'src/rtk/candidates.api'

const columnHelper = createColumnHelper()

export const CandidateProfilePage = ({ }) => {
    const { candidate } = useRouteLoaderData("candidateProfile");

    return (
        <>
            <ProfilePage user={candidate}>
                <Can I={'update'} a={'Candidate'} passThrough>
                    {(allowed) => <ProfileDetails viewOnly={!allowed} updateMutation={useUpdateCandidateMutation} user={candidate} />}
                </Can>
            </ProfilePage>
        </>
    )
}
