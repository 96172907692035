/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData, useParams } from "react-router-dom"
import DataTable from 'src/app/components/DataTable'

import { skipToken } from "@reduxjs/toolkit/query"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button, Tag } from '@chakra-ui/react';
import { Toolbar } from 'src/_metronic/layout/components/toolbar/Toolbar'
import { ToolbarReports } from 'src/_metronic/layout/components/toolbar/toolbars'
import { Page } from 'src/app/components/Page'
import { PositionsChart } from 'src/app/components/charts/PositionsChart'
import { colors } from 'src/app/libs/config'
import Row from 'src/app/components/temp/Row'
import { Can } from 'src/app/routing/AppRoutes'
import DepartmentTag from 'src/app/components/Tags/DepartmentTag'
import StatusTag from 'src/app/components/Tags/StatusTag'
import { useGetAllAssessmentsQuery } from 'src/rtk/exams.api'
import RoundedProgressCard from 'src/app/components/charts/RoundedProgressCard'
import moment from "moment";
import ManagePosition from './components/ManagePosition'
import Gauge from 'src/app/components/temp/Gauge'
import ExamAddCandidate from '../exams/components/ExamAddCandidate'
import { useGetClonesQuery } from 'src/rtk/exams.api'


const columnHelper = createColumnHelper()

const breadCrumbs: Array<PageLink> = [
    {
        title: 'Management',
        path: '#',
        isSeparator: false,
        isActive: false
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: true,
    },
    {
        title: 'Positions',
        path: '/management/positions',
        isSeparator: false,
        isActive: false
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: true,
    },
]


export const PositionPage = ({ }) => {
    const { positionAssessmentsOverview, position, exams } = useLoaderData();
    const { positionId } = useParams()

    const colmns = [
        columnHelper.accessor('receiverName',{
            header: 'Name',
            cell: (info) => (
                <div className='d-flex'>
                    <div>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {info.getValue()}
                        </a>
                        <span className='text-muted d-block fw-semibold'>{info.row.original.receiverEmail}</span>
                    </div>
                </div>
            )
        }),
        columnHelper.accessor("createdAt", {
            header: 'Created',
            cell: (info) => moment(info.getValue()).format("MMM DD, YYYY")
        }),
        columnHelper.accessor('status',{
            header: 'Status',
            cell: (info) => info.getValue()
        }),
        columnHelper.accessor('score', {
            header: 'Exam Score From 100',
            cell: (info) => `${info.getValue()?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) ?? 0}`
        }),
        columnHelper.accessor('receiverId', {
            header: 'Candidate Profile',
            cell: (info) => {
                return (
                    <Link key={info.getValue()} to={`/management/candidates/${info.getValue()}/profile`}>
                        <Button size='sm' color='#A1A5B7'>
                            Go to Profile
                        </Button>
                    </Link>
                )
            }
        })
    ];


    return (
        <>
            <Page breadcrumbs={breadCrumbs} enableRowPinning={true} title={position.data[0].title} columns={colmns} dataSourceQueryFn={useGetClonesQuery} dataGridTitle={'Candidates'} queryParams={
                { 
                    page: 0,
                    size: 10,
                    purpose: 'list',
                    associatedId: positionId
                }
            }>
                <div key='toolbar' className='d-inline-flex'>
                    <Can a={'Position'} I={'create'}>  
                            <div className='me-5'>
                                <ManagePosition position={position?.data[0]} className="pe-5" />
                            </div>
                            <ExamAddCandidate positionId={positionId} />
                    </Can>
                </div>
                <div key='middle'>
                    <Row>

							<RoundedProgressCard
								title='Yet to start'
								color='#ebebeb'
								progress={
									(positionAssessmentsOverview.yetToStart / positionAssessmentsOverview.total) * 100
								}
								count={positionAssessmentsOverview.yetToStart}
							/>

							<RoundedProgressCard
								title='In Progress'
								color={colors.link}
								progress={
									(positionAssessmentsOverview.inProgress / positionAssessmentsOverview.total) * 100
								}
								count={positionAssessmentsOverview.inProgress}
							/>


							<RoundedProgressCard
								title='Completed'
								color={colors.success}
								progress={
									(positionAssessmentsOverview.completed / positionAssessmentsOverview.total) * 100
								}
								count={positionAssessmentsOverview.completed}
							/>

						{/* <MarksChart marks={140} total={160} /> */}
						<Gauge inProgress={positionAssessmentsOverview?.inProgress + positionAssessmentsOverview.completed} total={positionAssessmentsOverview?.total} />
                    </Row>
                </div>
            </Page>
        </>
    )
}


export default PositionPage