import {
	Button,
	Flex,
	Grid,
	Heading,
	Progress,
	Stack,
	Tag,
	Text,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Box,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Container from "./Container";
import ProgressChart from "./charts/ProgressChart";
import { colors } from "src/app/libs/config";


const SummaryChallenge = ({ report, goals, isExpended }) => {
const scoreColore = (score) => {
		if (score <= 30) {
			return 'red';
		}
		else if (30 < score && score <= 70) {
			return 'yellow';
		}
		else if (70 < score && score <= 100) {
			return 'green';
		}
	};

	const scoreLevel = (score) => {

		if (score <= 30) {
			return 'Junior';
		}
		else if (30 < score && score <= 70) {
			return 'Mid Level';
		}
		else if (70 < score && score <= 100) {
			return 'Senior';
		}
	};

	const zip = (a, b) => a.map((k, i) => [k, b[i]]);

	const getOverall = (summaryText) => {
		if (summaryText) {
			const splitted = summaryText?.split('\n');
			console.log(splitted);
			return splitted[splitted.length - 1]
		}
		return ''
	}

	// const calculateCodingProficiencyScore = (syntaxErrorScore, logicalErrorScore, unitTestingScore) => ((100 - (syntaxErrorScore ?? 100)) * 0.25) + ((100 - (logicalErrorScore ?? 100)) * 0.5) + ((unitTestingScore ?? 100) * 0.25);

	/**
	 * Calculates the score based on the duration and ideal time.
	 * @param {string} duration - Completion time in the format "hours:minutes:seconds".
	 * @param {string} idealTime - Ideal time in the format "hours:minutes:seconds".
	 * @returns {number} The score.
	 */
	const calculateScore = (duration, idealTime) => {
		// Convert timespan to seconds for easier calculations
		const durationInSeconds = convertToSeconds(duration);
		const idealTimeInSeconds = convertToSeconds(idealTime);

		// If duration exceeds ideal time by more than 50%, return 0
		if (durationInSeconds > 1.5 * idealTimeInSeconds) {
			return 0;
		}

		// Otherwise, calculate the score
		let score = 100 * (1.5 - (durationInSeconds / idealTimeInSeconds));

		// Ensure the score is capped at 100
		score = Math.min(100, score);
		if (score < 50) {
			return 0;
		}
		return score;
	}

	/**
	 * Converts a time span in the format "hours:minutes:seconds" to seconds.
	 * @param {string} timeSpan - Time in the format "hours:minutes:seconds".
	 * @returns {number} The time in seconds.
	 */
	const convertToSeconds = (timeSpan) => {
		const [hours, minutes, seconds] = timeSpan.split(":").map(Number);
		return hours * 3600 + minutes * 60 + seconds;
	}

	const breakText = (text) => text?.split('\n').map(function (item) {
		return (
			<>
				{item}
				<br />
			</>)
	});


	const Section = ({ title, href, content, marks, total, isExpended, children }) => (
		<Accordion index={isExpended ? 0 : undefined} allowToggle>
			<AccordionItem>
				<AccordionButton >
					<Box as="span" flex='1' textAlign='left' >
						{title}
					</Box>
					<AccordionIcon />
				</AccordionButton>
				<AccordionPanel pb={4}>
					{children ? (<>{children}</>) : (
						<Text>
							{content ? breakText(content) : (<></>)}
						</Text>)}
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	);

	const Item = ({ name, value, marks, total }) => (
		<Grid gridTemplateColumns='2fr 4fr 1fr' gap={4}>
			<Text color='#7E8299' fontSize='15px' fontWeight='500'>
				{name}
			</Text>

			<Progress size='sm' borderRadius='8px' value={value} colorScheme='gray' />

			<Text color='#B5B5C3' fontSize='12px' fontWeight='600'>
				{marks}/{total} PTS
			</Text>
		</Grid>
	);

	const exam = (
		<Flex py={2}>
			<Stack spacing={4}>
				<Item name='Design' value={60} marks='50' total='90' />
				<Item name='Language knowledge' value={100} marks='350' total='350' />
				<Item name='Problem Solver' value={100} marks='350' total='350' />
				<Item name='Reliability' value={30} marks='8' total='28' />
			</Stack>
		</Flex>
	);

	const issueGroupStyle = {
		padding: '10px',
		margin: '10px 0',
		border: '1px solid #ddd',
		borderRadius: '8px',
		backgroundColor: '#f9f9f9',
	  };

	  const issueStyle = {
		padding: '5px',
		borderBottom: '1px solid #eee',
	  };
	  
	  

	  const IssueGroup = ({ title, issues }) => {
		if (issues.length === 0) {
		  return null;
		}
	  
		return (
		  <div style={issueGroupStyle}>
			<h3>{title}</h3>
			{issues.map((issue, index) => (
			  <div key={index} style={issueStyle}>
				<h4>{issue.description} - {issue.method}</h4>
				<p><strong>Explanation:</strong> {issue.explanation}</p>
				<p><strong>Source:</strong> {issue.source}</p>
				<p><strong>Impact:</strong> {issue.impact}</p>
			  </div>
			))}
		  </div>
		);
	  };
	  

	const IssueReport = ({ data }) => {
		const { issues, summary, humanReadableSummary, developerLevel, score, recommendations } = data;
	  
		return (
		  <div>			
			<IssueGroup title="Low Severity Issues" issues={issues.lowSeverityIssues} />
			<IssueGroup title="Medium Severity Issues" issues={issues.mediumSeverityIssues} />
			<IssueGroup title="High Severity Issues" issues={issues.highSeverityIssues} />
			<IssueGroup title="Critical Severity Issues" issues={issues.criticalSeverityIssues} />
	  
			<h2>Developer Level</h2>
			<p>{developerLevel}</p>
	  
			<h2>Score</h2>
			<p>{score}</p>

			<h2>Summary</h2>
			<p>{summary}</p>
			<p>{humanReadableSummary}</p>
	  
			<h2>Recommendations</h2>
			<ul>
			  {recommendations.map((recommendation, index) => (
				<li key={index}>{recommendation}</li>
			  ))}
			</ul>
		  </div>
		);
	  };

	return (
		<Container p={0}>
			<Flex px='24px' py='16px' borderBottom='1px solid var(--bs-border-color)'>
				<Heading size='md'>Summary</Heading>
			</Flex>
			<Flex flex={1}>
				<Flex flex={3} borderRight={`1px dashed var(--bs-border-color)`}>
					<ProgressChart
						title='Goal Achieving'
						level={scoreLevel(report?.insights?.goalsAnalysis?.score || 0)}
						progress={report?.insights?.goalsAnalysis?.score || 0}
						color={scoreColore(report?.insights?.goalsAnalysis?.score || 0)}
					/>
					<ProgressChart
						title='Coding Proficiency'
						level={scoreLevel(report?.codingProficiencyScore || 0)}
						progress={(report?.codingProficiencyScore || 0)}
						color={scoreColore((report?.codingProficiencyScore || 0))}
					/>
					<ProgressChart
						title='Completion Time'
						// level={scoreLevel(report?.duration || 0)}
						progress={100}
						color={scoreColore(100)}
					/>
				</Flex>
				<Flex flex={1}>
					<ProgressChart
						title='Candidate Ranking'
						// level='Min Level'
						progress={"0/100"}
						specialCenter="0/100"
					// color='green'
					// tag='BETTER THAN 96% OF CANDIDATES'
					/>
				</Flex>
			</Flex>

			<Flex id="overall" px='24px' py='16px' flexDir='column' gap={4}>
				<Heading size='sm'>Overall Insight</Heading>
				<Stack colorr='#7E8299'>
					<Text>
						{report?.insights?.overallSummary?.summary}
					</Text>
				</Stack>
			</Flex>
			<Flex flexDir='column' px='24px' gap={6} py='24px' pb='32px'>
				<Section title='Unit Testing' href='#UnitTesting' isExpended={isExpended}>
					{report?.testCaseResults && report?.testCaseResults.length > 0 ? report?.testCaseResults?.slice().sort((a, b) => (a.name > b.name) ? 1 : -1).map((value, index) => (
						<>
							<Text>
								{index + 1}. {value?.goal} ({value?.isPassed? 'Passed' : 'Failed'})
							</Text>
							<Text>
								{value?.description}
							</Text>
							<br />
						</>
					)) : <>
						<Text>
							There is no unitests
						</Text>
					</>}
				</Section>
				<Section title='Syntax Errors' href='#SyntaxErrors' isExpended={isExpended}>
					{IssueReport({ data: report?.insights?.syntaxAnalysis })}
				</Section>
				<Section title='Logical Errors' href='#LogicalErrors' isExpended={isExpended}>
					{IssueReport({ data: report?.insights?.logicalAnalysis })}
				</Section>
				<Section title='Goal Achieving' href='#GoalAchieving' isExpended={isExpended}>
					{goals && report?.insights?.goalsAnalysis?.goals ? zip(goals, report?.insights?.goalsAnalysis?.goals).map((value, index) => (
						<>
							<Text>
								{index + 1}. {value[0]?.content} ({value[1]?.achieved === 'Yes' ? value[0]?.weight : 0}/{value[0]?.weight} points)
							</Text>
							<Text>
								{value[1]?.reasonForReportHumanReadable}
							</Text>
							<br />
						</>
					)) : <></>}

					{/* {getOverall(report?.summary)} */}
				</Section>
				{/* <Section title='Coding Proficiency' href='#CodingProficiency' content={report?.codingProficiencyScore ?? 'Under Construction'}/> */}
				{/* <Section title='Coding Proficiency' href='#CompletionTime' content={report?.codingProficiencyScore ?? 'Under Construction' }/> */}
			</Flex>
		</Container>
	);
};

export default SummaryChallenge;
