import { Input, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { colors, dimentions } from '../../libs/config';
import { Controller } from 'react-hook-form';

const TextField = ({ hideLabel, label, h, form, fieldName, registerOptions, required, newVersion, ...props }) => {

	const { control } = form;

	if (!registerOptions) {
		registerOptions = {}
	}

	if (required) {
		registerOptions['required'] = `${label} is required`;
	}

	const register = form.register(fieldName, registerOptions);

	return (
		<Stack w='full' spacing={0.5}>
			{!hideLabel && (<Text fontSize='12px' fontWeight='600'>
				{label}
			</Text>)}
			<Controller
				name={fieldName}
				control={control}
				rules={registerOptions}
				render={({ field, fieldState, formState }) => {
					return (
						<>
							{newVersion ? 
							(<input type='text' placeholder={label} {...register} {...props} />) : (<Input
								h={h || '42px'}
								w='full'
								border='1px solid var(--bs-border-color)'
								bgColor='var(--bs-gray-100)'
								borderRadius={dimentions.borderRadius.sm}
								{...register}
								{...props}
							/>)}

							{fieldState.invalid && (formState.submitCount > 0 || fieldState.isDirty) ? (
								<>
									<Text variant="danger">
										{fieldState.error.message}
									</Text>
								</>) : (<>
								</>)}


						</>)
				}}
			/>

		</Stack>
	);
};

export default TextField;
