import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function useNumericQueryParam(key, defaultValue = 0) {
  const query = useQuery();
  const value = query.get(key);
  if (value === null)
    return defaultValue;
  const numericValue = Number(value);
  return isNaN(numericValue) ? defaultValue : numericValue;
}

function useQueryParam(key, defaultValue) {
  const query = useQuery();
  const value = query.get(key);
  if (value === null)
    return defaultValue;
  return value;
}


export {useQueryParam};
export default useNumericQueryParam;