import React from 'react';
import SelectField from './SelectField';
import { useGetDepartmentsQuery, useCreateDepartmentMutation } from "src/rtk/departments.api";

const DepartmentField = ({ label, h, form, fieldName, registerOptions, ...props }) => {
	return (
		<SelectField form={form} fieldName={fieldName} label={label ?? 'Department'} {...props} registerOptions={registerOptions} query={useGetDepartmentsQuery} keyName={'name'} valueName={'id'} createable={true} mutation={useCreateDepartmentMutation} />
	);
};

export default DepartmentField;
