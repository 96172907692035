import { useGetAllRolesQuery } from "src/rtk/users.api";
import { Tag } from "@chakra-ui/react";
import React from "react";

function RolesTag({ children }) {
	const {
		data: rolesDict,
		isLoading: rolesDictIsLoading,
		isError: rolesDictIsError,
	} = useGetAllRolesQuery();

	let rolesDictionary = {};
	let departmentColorSchemes = {};
	const colorSchemes = ["blue", "green", "yellow"];

	if (rolesDict) {
		if (Object.keys(rolesDictionary).length == 0) {
			rolesDictionary = Object.fromEntries(
				rolesDict.data.map((x) => [x.id, x.name])
			);
			departmentColorSchemes = Object.fromEntries(
				rolesDict.data.map((x, idx) => [x.id, colorSchemes[idx]])
			);
		}
	}
	if (!rolesDictionary[children]) {
		return <Tag colorScheme='gray'>Not Specified</Tag>;
	}
	if (Object.keys(rolesDictionary).length === 0) {
		return null;
	}
	return (
		<Tag colorScheme={departmentColorSchemes[children]}>
			{rolesDictionary[children]}
		</Tag>
	);
}

export default RolesTag;
