import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./base.api";
import { toast } from "react-toastify";

const tags = ["Departments"];
export const departmentApi = createApi({
    reducerPath: "departmentApi",
    keepUnusedDataFor: 100000,
    baseQuery: axiosBaseQuery({
        baseUrl: "",
    }),
    tagTypes: tags,
    endpoints: (builder) => ({
        getDepartments: builder.query({
            query: () => ({
                url: `/management/department`,
                method: "GET",
                // params: {
                // 	status: status && status,
                // },
            }),

            providesTags: ["Departments"]
        }),
        getAssessmentDepartments: builder.query({
            query: () => ({
                url: `/management/department/assessments/overview`,
                method: "GET",
                // params: {
                // 	status: status && status,
                // },
            }),

            // tags: []
        }),
        getTeammatesDepartments: builder.query({
            query: () => ({
                url: `/management/department/teammates/overview`,
                method: "GET",
                // params: {
                // 	status: status && status,
                // },
            }),

            // tags: []
        }),
        getCandidatesDepartments: builder.query({
            query: () => ({
                url: `/management/department/candidates/overview`,
                method: "GET",
                // params: {
                // 	status: status && status,
                // },
            }),

            // tags: []
        }),
        getPositionDepartments: builder.query({
            query: () => ({
                url: `/management/department/positions/overview`,
                method: "GET",
                // params: {
                // 	status: status && status,
                // },
            }),

            // tags: []
        }),
        createDepartment: builder.mutation({
            query: ({ name } = {}) => ({
                url: `/management/department`,
                method: "POST",
                body: {
                    name
                },
                validateStatus: (response, body) => {
                    if (response.ok) {
                        (async () => {
                            const { toast } = await import('react-toastify');
                            toast.success("Department created successfully", {
                                position: toast.POSITION.TOP_RIGHT,
                                theme: 'colored',
                                pauseOnHover: false,
                                pauseOnFocusLoss: false
                            })
                        })()
                    }
                    return response.ok;
                },
            }),
            invalidatesTags: ["Departments"]
        })
    }),
});

export const {
    useGetDepartmentsQuery,
    useGetAssessmentDepartmentsQuery,
    useGetTeammatesDepartmentsQuery,
    useGetCandidatesDepartmentsQuery,
    useGetPositionDepartmentsQuery,
    useCreateDepartmentMutation
} = departmentApi;
