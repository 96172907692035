import { colors, dimentions } from "src/app/libs/config";
import { Button, Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { PageTitle } from "src/_metronic/layout/core";
import Challenge from "./Challenge";
import { KTCard, KTCardBody } from "src/_metronic/helpers";


const ExamPage = ({breadCrumbs, candidate, exam}) => {
	return (<>
		<PageTitle breadcrumbs={breadCrumbs}>
                {exam.name}
		</PageTitle>

		<KTCard flush={true} border='1px solid var(--bs-border-color)'>
		<Flex
				w='full'
				borderBottom={`1px solid var(--bs-border-color)`}
				py={4}
				px={4}
			>
				<Text color={colors.textLight} fontWeight='bold' fontSize='24px' marginBottom={0}>
					{`Exam: ${exam?.name}`}
				</Text>
			</Flex>
			<KTCardBody border='1px solid var(--bs-border-color)'>
			{exam.challenges &&
					exam.challenges?.map(
						(challenge, idx) => (
							<Challenge key={challenge.id} candidateId={candidate.id} examId={exam.id} challenge={challenge}  />
						)
					)}
			</KTCardBody>
		</KTCard>

			{/* <Flex
			bgColor='white'
			borderRadius={dimentions.borderRadius.lg}
			flex={1}
			py={6}
			flexDir='column'
			>
			<Flex
				w='full'
				borderBottom={`1px solid ${colors.light}`}
				py={4}
				px={4}
			>
				<Text color={colors.textLight} fontWeight='bold' fontSize='24px'>
					{`Challenge: ${exam?.assessmentTemplate?.name}`}
				</Text>
			</Flex>
			<Stack w='full' p={4} spacing={4}>
				{exam.tasks &&
					exam.tasks?.map(
						(challenge, idx) => (
							<AssessmentChallenge key={challenge.id} candidateId={candidate.id} examId={exam.id} challenge={challenge}  />
						)
					)}
			</Stack>
			</Flex> */}
			</>
	);
};

export default ExamPage;
