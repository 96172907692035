import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./base.api";
import { toast } from "react-toastify";

const tags = ["Exam", "Challenge", "Position"];
export const assessmentApi = createApi({
    reducerPath: "assessmentApi",
    baseQuery: axiosBaseQuery({
        baseUrl: "",
    }),
    tagTypes: tags,
    endpoints: (builder) => ({
        getAllAssessments: builder.query({
            query: ({ page = 1, search, pageSize = 10, assessmentTemplateId, id, positionId } = {}) => ({
                url: `/assessment`,
                method: "GET",
                params: {
                    page,
                    ...(search && { search: search }),
                    ...(pageSize && { pageSize: pageSize }),
                    ...(assessmentTemplateId && { assessmentTemplateId: assessmentTemplateId }),
                    ...(id && { id: id }),
                    ...(positionId && { positionId: positionId })
                },
            }),
            // providesTags: ["Position"]
            providesTags:  (result, error, args) => args ? [{ type: 'Exam', id: args?.id}] : ['Position'],
        }),
        getPositionAssessmentsOverview: builder.query({
            query: ({ id, positionId }) => ({
                url: `/management/assessment/overview`,
                method: "GET",
                params: {
                    ...(id && { id: id }),
                    ...(positionId && { positionId: positionId })
                },
            }),
        }),
        getAssessmentsInProgressCount: builder.query({
            query: () => ({
                url: `/assessment`,
                method: "GET",
                params: {
                    count: true,
                    assessmentStatusId: 2,
                },
            }),
        }),
        getAssessmentsStartedCount: builder.query({
            query: () => ({
                url: `/assessment`,
                method: "GET",
                params: {
                    count: true,
                    assessmentStatusId: 3,
                },
            }),
        }),
        getAssessmentsFinishedCount: builder.query({
            query: () => ({
                url: `/assessment`,
                method: "GET",
                params: {
                    count: true,
                    assessmentStatusId: 4,
                },
            }),
        }),
        getAllAssessmentTemplates: builder.query({
            query: ({ page = 1, pageSize = 10, search } = {}) => ({
                url: `/Management/AssessmentTemplate`,
                method: "GET",
                params: {
                    page,
                    pageSize: pageSize,
                    ...(search && { search: search }),
                },
            }),
            providesTags: ["Exam"],
        }),
        addAssessmentTemplates: builder.mutation({
            query: (body) => ({
                url: `/Management/AssessmentTemplate`,
                method: "POST",
                body: body,
                validateStatus: (response, body) => {
                    if (response.ok) {
                        toast.success("Exam created successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                            theme: 'colored',
                            pauseOnHover: false,
                            pauseOnFocusLoss: false
                        })
                    }
                    return response.ok;
                },
            }),
            invalidatesTags: ["Exam"],
        }),
        getAnAssessmentTemplate: builder.query({
            query: ({ id } = {}) => ({
                url: "/Management/AssessmentTemplate",
                method: "GET",
                params: {
                    id: id,
                },
            }),
            providesTags: ["Exam"],
        }),
        updateAssessmentTemplates: builder.mutation({
            query: (body) => ({
                url: `/Management/AssessmentTemplate`,
                method: "PATCH",
                body: body,
                params: {
                    id: body.id,
                },
            }),
            invalidatesTags: ["Exam"],
        }),
        getAssessmentsSummery: builder.query({
            query: () => ({
                url: "/Exam/Summary",
                method: "GET",
            }),
            providesTags: ["Exam"],
        }),
        getAnAssessment: builder.query({
            query: (id) => ({
                url: "/Exam",
                method: "GET",
                params: {
                    id,
                },
            }),
            providesTags: ["Exam"],
        }),
        initAssessmentChallenge: builder.mutation({
            query: ({ examId, challengeId } = {}) => ({
                url: "/assessmenttask/init",
                method: "GET",
                params: { examId, challengeId },
            }),
        }),
        getChallenge: builder.query({
            query: (challengeId) => ({
                url: "/challenge",
                method: "GET",
                params: {
                    id: challengeId,
                },
            }),
            providesTags:  (result, error, args) => args ? [{ type: 'Challenge', id: args?.challengeId}] : ['Challenge'],
        }),
        getIframe: builder.query({
            query: ({ examId, challengeId } = {}) => ({
                url: "/assessmenttask/start",
                method: "GET",
                params: {
                    examId: examId,
                    challengeId: challengeId,
                },
            }),
        }),
        checkVsCodeAvailablity: builder.query({
            query: ({ examId, challengeId } = {}) => ({
                url: "/AssessmentChallenge/Check",
                method: "GET",
                params: {
                    examId,
                    challengeId,
                },
            }),
        }),
        submitChallenge: builder.mutation({
            query: ({ examId, challengeId } = {}) => ({
                url: "/assessmenttask/submit",
                method: "PATCH",
                body: {},
                params: {
                    examId,
                    challengeId,
                },
            }),
            invalidatesTags: (result, _, params) => ([{type: 'Challenge', id: params.challengeId}, {type: 'Exam', id: params.examId}])
        }),
        checkIfChallengeReportIsAvailable: builder.query({
            query: ({ examId, challengeId } = {}) => ({
                url: "/Event/Check",
                method: "GET",
                params: {
                    examId: examId,
                    challengeId: challengeId,
                },
            }),
        }),
        getChallengeReport: builder.query({
            query: ({ examId, challengeId } = {}) => ({
                url: "/Event",
                method: "GET",
                params: {
                    examId: examId,
                    challengeId: challengeId,
                },
            }),
        }),
    }),
});

export const {
    useAddAssessmentTemplatesMutation,
    useCheckIfChallengeReportIsAvailableQuery,
    useCheckVsCodeAvailablityQuery,
    useGetAllAssessmentTemplatesQuery,
    useGetAllAssessmentsQuery,
    useGetAnAssessmentQuery,
    useGetAnAssessmentTemplateQuery,
    useGetAssessmentsFinishedCountQuery,
    useGetAssessmentsInProgressCountQuery,
    useGetAssessmentsStartedCountQuery,
    useGetAssessmentsSummeryQuery,
    useGetIframeQuery,
    useGetChallengeQuery,
    useGetChallengeReportQuery,
    useInitAssessmentChallengeMutation,
    useSubmitChallengeMutation,
    useUpdateAssessmentTemplatesMutation,
    useGetPositionAssessmentsOverviewQuery
} = assessmentApi;
