import { colors, dimentions } from "src/app/libs/config";
import { Avatar, Button, Flex, Image, Stack, Text } from "@chakra-ui/react";
import {React, useEffect, useState} from "react";
import { FiChevronDown } from "react-icons/fi";
import { PageTitle } from "src/_metronic/layout/core";
import WhiteButton from "src/app/components/temp/WhiteButton";
import Grade from "src/app/components/temp/Grade";
import MainRow from "src/app/components/temp/MainRow";
import SummaryChallenge from "src/app/components/temp/SummaryChallenge";
import { useLoaderData, useParams, useRouteLoaderData } from "react-router-dom";
import { usePDF, Margin } from 'react-to-pdf';

const ChallengeReportPage = () => {
	const { candidate } = useRouteLoaderData("candidateProfile")
	const { exam } = useRouteLoaderData("exam")
	// const { report } = useLoaderData()
	const { challengeId } = useParams(); 
	const [ expendAllSections, setExpendAllSections] = useState(false)

    const [section, setSection] = useState();

	const [grade, setGrade] = useState(0);
	const { toPDF, targetRef } = usePDF({
		method: "save",
		filename: "report.pdf",
		overrides: {
			// see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
			pdf: {
			  compress: true,
			},
		}
	  });

	  const challenge = exam.challenges.filter(challenge => challenge.id === challengeId)[0];

	  const downloadJSON = () => {
		const dataStr =
		  'data:application/json;charset=utf-8,' +
		  encodeURIComponent(JSON.stringify(challenge, null, 2));
		const download = document.createElement('a');
		download.setAttribute('href', dataStr);
		download.setAttribute('download', 'report' + '.json');
		document.body.appendChild(download);
		download.click();
		download.remove();
	  };

	  const downloadPDF = () => {
		setExpendAllSections(true)
		setTimeout(() => {
			toPDF()
			setExpendAllSections(false)
		}, 300)
	  }

	  


	return (
        <>
            <PageTitle>
                    {/* {report.taskName} */}
					Test
					<div key={"toolbar"}>
					<Flex gap={6} >
						<WhiteButton 
						    className={'d-print-none'}
							rightIcon={<FiChevronDown />}
							onClick={(e) => downloadJSON()}
						>
							Export To Json
						</WhiteButton>
						<WhiteButton
						    className={'d-print-none'}
							rightIcon={<FiChevronDown />}
							onClick={(e) => downloadPDF()}
						>
							Export
						</WhiteButton>
					</Flex>
					</div>
            </PageTitle>
            <div ref={targetRef} style={{backgroundColor: 'var(--bs-app-bg-color)'}}>
				<Stack id="report"  className="print-content-only" spacing={6}>
					<MainRow>
						<Flex
							w='full'
							bgColor='var(--bs-body-bg)'
							h='fit-content'
							px='24px'
							pt='24px'
							rounded={dimentions.borderRadius.lg}
							flexDir='column'
						>
							{/* <CandidateHeader /> */}
							<Flex
								pb='24px'
								flex={2}
								// borderBottom={`2px solid ${colors.gray}`}
								justify='space-between'
								gap={6}
							>
								<Flex gap={6}>
								<Avatar src={candidate.avatar} name={`${candidate.firstName} ${candidate.lastName}`} sx={{
                width: '160px',
                height: '160px',
              }} size={'2xl'} borderRadius={12} />
									
								</Flex>

								<Flex flex={2}>
									<Stack flex={2} spacing={1}>
										{/* <Text
										color={colors.text}
										fontSize='18px'
										fontWeight='semibold'
									>
										Profile Details
									</Text> */}
										<Flex flex={1} gap={6}>
											<Flex flexDir='column' flex={2}>
												<Flex
													flex={1}
													borderBottom={`1px dashed var(--bs-border-color)`}
													gap={6}
													align='center'
												>
													<Text
														fontSize='16px'
														color={colors.text}
														fontWeight='bold'
														flex={2}
													>
														Company Name
													</Text>
													<Text
														fontSize='14px'
														color={colors.text}
														fontWeight='regular'
														flex={2}
													>
														{challenge?.companyName}
													</Text>
												</Flex>
												<Flex
													flex={1}
													borderBottom={`1px dashed var(--bs-border-color)`}
													gap={6}
													align='center'
												>
													<Text
														fontSize='16px'
														color={colors.text}
														fontWeight='bold'
														flex={2}
													>
														Position
													</Text>
													<Text
														fontSize='14px'
														color={colors.text}
														fontWeight='regular'
														flex={2}
													>
														{exam?.associatedName}
													</Text>
												</Flex>
												<Flex
													flex={1}
													borderBottom={`1px dashed var(--bs-border-color)`}
													gap={6}
													align='center'
												>
													<Text
														fontSize='16px'
														color={colors.text}
														fontWeight='bold'
														flex={2}
													>
														Challenge Name
													</Text>
													<Text
														fontSize='14px'
														color={colors.text}
														fontWeight='regular'
														flex={2}
													>
														{challenge?.challenge?.name}
													</Text>
												</Flex>
												<Flex
													flex={1}
													borderBottom={`1px dashed var(--bs-border-color)`}
													gap={6}
													align='center'
												>
													<Text
														fontSize='16px'
														color={colors.text}
														fontWeight='bold'
														flex={2}
													>
														Candidate
													</Text>
													<Text
														fontSize='14px'
														color={colors.text}
														fontWeight='regular'
														flex={2}
													>
														
														{candidate?.firstName +
															" " +
															candidate?.lastName}
													</Text>
												</Flex>
											</Flex>
											<Flex flexDir='column' flex={2}>
												<Flex
													flex={1}
													borderBottom={`1px dashed var(--bs-border-color)`}
													gap={6}
													align='center'
												>
													<Text
														fontSize='16px'
														color={colors.text}
														fontWeight='bold'
														flex={2}
													>
														LinkedIn
													</Text>
													<Text
														fontSize='14px'
														color={colors.text}
														fontWeight='regular'
														flex={2}
													>
														{candidate?.linkedInProfile ?? 'N/A' }
													</Text>
												</Flex>
												<Flex
													flex={1}
													borderBottom={`1px dashed var(--bs-border-color)`}
													gap={6}
													align='center'
												>
													<Text
														fontSize='16px'
														color={colors.text}
														fontWeight='bold'
														flex={2}
													>
														Date
													</Text>
													<Text
														fontSize='14px'
														color={colors.text}
														fontWeight='regular'
														flex={2}
													>
														
														{challenge?.startedAt}
													</Text>
												</Flex>
												<Flex
													flex={1}
													borderBottom={`1px dashed var(--bs-border-color)`}
													gap={6}
													align='center'
												>
													<Text
														fontSize='16px'
														color={colors.text}
														fontWeight='bold'
														flex={2}
													>
														Email
													</Text>
													<Text
														fontSize='14px'
														color={colors.text}
														fontWeight='regular'
														flex={2}
													>
														{candidate?.email ?? 'N/A' }
													</Text>
												</Flex>
												<Flex
													flex={1}
													borderBottom={`1px dashed var(--bs-border-color)`}
													gap={6}
													align='center'
												>
													<Text
														fontSize='16px'
														color={colors.text}
														fontWeight='bold'
														flex={2}
													>
														Phone
													</Text>
													<Text
														fontSize='14px'
														color={colors.text}
														fontWeight='regular'
														flex={2}
													>
														{candidate?.phoneNumber ?? 'N/A' }
													</Text>
												</Flex>
												<Flex flex={1}></Flex>
											</Flex>
											<Flex flexDir='column' flex={2}>
											{/* <Flex flexDir='column' flex={2} borderLeft={`1px dashed var(--bs-border-color)`}> */}
														{/* <Flex
														flex={1}
														borderBottom={`1px dashed var(--bs-border-color)`}
														gap={6}
														align='center'
													>
														<Text
															fontSize='16px'
															color={colors.text}
															fontWeight='bold'
															flex={2}
														>
															Difficulty Level
														</Text>
														<Text
															fontSize='14px'
															color={colors.text}
															fontWeight='regular'
															flex={2}
														>
															Senior 
														</Text>
													</Flex> */}
													<Flex
														flex={1}
														gap={6}
														align='center'
													>
														<Grade grade={ challenge?.score?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) } />
													</Flex>
												</Flex>
										</Flex>
									</Stack>
								</Flex>
							</Flex>
						</Flex>
					</MainRow>
					<SummaryChallenge report={challenge?.result} goals={challenge?.challenge?.goals} isExpended={expendAllSections} />
				</Stack>
				</div>
				</>
	);
};

export default ChallengeReportPage;
