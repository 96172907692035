/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData } from "react-router-dom"
import DataTable from 'src/app/components/DataTable'

import { skipToken } from "@reduxjs/toolkit/query"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button } from '@chakra-ui/react';
import { Toolbar } from 'src/_metronic/layout/components/toolbar/Toolbar'
import { ToolbarReports } from 'src/_metronic/layout/components/toolbar/toolbars'
import { Page } from 'src/app/components/Page'
import { PositionsChart } from 'src/app/components/charts/PositionsChart'
import { colors } from 'src/app/libs/config'
import Row from 'src/app/components/temp/Row'
import { Can } from 'src/app/routing/AppRoutes'
import DepartmentTag from 'src/app/components/Tags/DepartmentTag'
import StatusTag from 'src/app/components/Tags/StatusTag'
import ManageTeammateModal from './ManageTeammateModal'
import { useGetTeammatesQuery } from 'src/rtk/positions.api'


const columnHelper = createColumnHelper()

const breadCrumbs: Array<PageLink> = [
    {
        title: 'Management',
        path: '#',
        isSeparator: false,
        isActive: false
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: true,
    },
]

export const TeammatesPage = ({ }) => {
    const { teammates, positionsOverview, departments } = useLoaderData();

    const colmns = [
        columnHelper.display({
            header: 'Teammate Description',
            cell: (info) => {
                return (
                    <div className='d-flex'>
                        <div className='symbol symbol-50px me-5'>
                            <Avatar src={info.row.original.avatar} name={`${info.row.original.firstName} ${info.row.original.lastName}`} borderRadius={'0.475rem'} sx={{ width: '50px', height: '50px' }} />
                        </div>
                        <div>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {info.row.original.firstName} {info.row.original.lastName}
                            </a>
                            <span className='text-muted d-block fw-semibold'>{info.row.original.email}</span>
                            <span className='text-muted d-block fw-semibold'>{info.row.original.phoneNumber}</span>
                        </div>
                    </div>
                )
            },

        }),
        columnHelper.accessor("username", {
            header: 'Username'
        }),
        columnHelper.accessor("gender", {
            header: 'Gender'
        }),
        columnHelper.accessor("departmentId", {
            header: 'Department',
            cell: (info) => {
                return (
                    <DepartmentTag>{info.getValue()}</DepartmentTag>
                )
            },
        }),
        columnHelper.accessor('active', {
            header: 'Status',
            cell: (info) => {
                return (
                    <StatusTag>{info.getValue()}</StatusTag>
                )
            },
        }),
        columnHelper.display({
            header: 'Details',
            enableSorting: false,
            cell: (info) => {
                return (
                    <ManageTeammateModal key={info.cell.row.original.id} initializedData={info.cell.row.original}
                        trigger={
                            <Button size='sm' color='#A1A5B7'>
                                Edit
                            </Button>
                        } />
                )
            }
        })
    ];


    return (
        <>
            <Page breadcrumbs={breadCrumbs} title={'Teammates'} columns={colmns} dataSourceQueryFn={useGetTeammatesQuery} queryParams={{
                roles: ['Recruiter', 'Interviewer']
            }}>
                <div key='toolbar'>
                    <Can a={'Teammate'} I={'create'}>
                        <ManageTeammateModal />
                    </Can>
                </div>
                <div key='middle'>
                    <Row>
                        <PositionsChart
                            total={positionsOverview?.total || 0}
                            title='Total Positions'
                            data={["In Progress", "Completed", "Yet To Start"]}
                            color={[colors.link, colors.success, colors.light]}
                            amount={[
                                positionsOverview?.inProgress || 0,
                                positionsOverview?.completed || 0,
                                positionsOverview?.yetToStart || 0,
                            ]}
                            values={[
                                positionsOverview?.inProgress || 0,
                                positionsOverview?.completed || 0,
                                positionsOverview?.yetToStart || 0,
                            ]}
                        />
                        <PositionsChart
                            total={teammates?.pagination?.length || 0}
                            title='Total Teammates'
                            data={["Active", "Suspended", "Unknown"]}
                            color={[colors.link, colors.success, "#FF9F9F"]}
                            amount={[teammates?.data?.filter(teammate => teammate.active).length, teammates?.data?.filter(teammate => !teammate.active).length, 0]}
                            values={[teammates?.data?.filter(teammate => teammate.active).length, teammates?.data?.filter(teammate => !teammate.active).length, 0]}
                        />
                        <PositionsChart
                            total={departments?.pagination?.length || 0}
                            title='Departments'
                            data={departments?.map((item, idx) => item.name)}
                            color={[colors.link, colors.success, colors.warning, "#FF9F9F"]}
                            amount={
                                departments
                                    ? departments?.map((item, idx) => item.value)
                                    : [0, 0, 0, 0]
                            }
                            values={
                                departments
                                    ? departments?.map((item, idx) => item.value)
                                    : [0, 0, 0, 0]
                            }
                        />
                    </Row>
                </div>
            </Page>
        </>
    )
}


export default TeammatesPage