import React, { useEffect, useCallback, useState } from "react";
import { Stack, Text, 	  Modal, Button,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Checkbox,
	ModalCloseButton, useDisclosure } from "@chakra-ui/react";
import Select, {
	components
} from "react-select";
import CreatableSelect from 'react-select/creatable';
import { Controller } from "react-hook-form";
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import {
	arrayMove,
	verticalListSortingStrategy,
	SortableContext,
	useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useModal } from "../ModalContext";

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

const customStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: 'var(--bs-gray-100, #f0f0f0)', // Fallback color if --bs-body-bg is not set
        borderColor: 'var(--bs-border-color, #ddd)', // Fallback color if --border-color is not set
        transition: 'none',
        ':hover': {
            borderColor: 'var(--border-color, #ddd)',
        },
		cursor: 'pointer',
		color: 'var(--bs-gray-900)',
		height: '42px',
		minHeight: '42px'
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: 'var(--bs-body-bg, #f0f0f0)',
        border: '1px solid var(--bs-border-color, #ddd)',
    }),
    option: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: isFocused ? 'var(--bs-gray-200, lightblue)' : 'var(--bs-body-bg, #f0f0f0)',
        color: 'var(--bs-gray-900)',
        ':hover': {
            backgroundColor: 'var(--bs-gray-200, lightblue)',
        },
		cursor: 'pointer'
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        backgroundColor: 'var(--bs-border-color, #ddd)',
    }),
    placeholder: (styles) => ({
        ...styles,
        color: 'var(--bs-gray-900)',
    }),
    singleValue: (styles) => ({
        ...styles,
        color: 'var(--bs-gray-900)',
    }),
	input: (styles) => ({
        ...styles,
        color: 'var(--bs-gray-900)',
    }),
	multiValue: (styles) => ({
        ...styles,
        backgroundColor: 'var(--bs-gray-200)', // Background of the tag
        color: 'var(--bs-gray-900)', // Text color of the tag
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        color: 'var(--bs-gray-900)', // Text color of the label
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        ':hover': {
            backgroundColor: 'var(--multi-value-remove-hover-bg, #c0c0c0)', // Background color on hover
            color: 'var(--multi-value-remove-hover-color, #000)', // Text color on hover
        }
    }),
};


const SelectField = ({
	form,
	query,
	queryFilters,
	fieldName,
	label,
	keyName,
	valueName,
	keyInterpolation,
	width,
	registerOptions,
	options,
	createable,
	mutation,
	multiselect,
	valueManipulation,
	maxOptions,
	ignoreCaseSensitive,
	multiValueClick,
	onCreateCallback,
	hideLabel,
	...props
}) => {

	const [selected, setSelected] = useState([]);
	const [inputValue, setInputValue] = useState(null);
	const debouncedInputValue = useDebounce(inputValue, 300);
	const [filters, setFilters] = useState(queryFilters ? {filter: ''} :  {});
	const { closeModal } = useModal();


	useEffect(() => {
		if (!query) return;
		if (!queryFilters) return;
		if (debouncedInputValue === null) return;
		const newFilters = queryFilters.map((filter) => {
			return `${filter}=*${debouncedInputValue}`;
		}).join('|');

		setFilters({filter: newFilters});
	}, [debouncedInputValue])

	const MultiValue = (props) => {
		
		const [clientX, setClientX] = useState();
		const [inputValue, setInputValue] = useState('');
		const { isOpen, onOpen, onClose } = useDisclosure();
		
		const onMouseDown = (e) => {
			setClientX(e.clientX);
			e.preventDefault();
			e.stopPropagation();
		};

		const onMouseUp = (e) => {
			if(e.clientX === clientX) {
				onOpen();
			}
			e.preventDefault();
			e.stopPropagation();
		}
		
		const innerProps = { ...props.innerProps, onMouseDown, onMouseUp };

		const { attributes, listeners, setNodeRef, transform, transition } =
			useSortable({
				id: props.data[valueName],
			});

		
		const style = {
			transform: CSS.Translate.toString(transform),
			transition,
		};

		return (
			<>
			<div onContextMenu={(e)=> e.preventDefault()}>
				<div style={style} ref={setNodeRef} {...attributes} {...listeners} onContextMenu={(e)=> e.preventDefault()}>
					<components.MultiValue {...props} innerProps={innerProps} />
				</div>
				{multiValueClick ? multiValueClick(props, setSelected, isOpen, onClose) : <></>}
			</div>
			</>
			
			
		);
	};

	const MultiValueRemove = (props) => {
		return (
			<components.MultiValueRemove
				{...props}
				innerProps={{
					onPointerDown: (e) => e.stopPropagation(),
					...props.innerProps,
				}}
			/>
		);
	};



	const onSelectChanged = (selectedOptions) =>
		setSelected([...selectedOptions]);

	

	const { data: data, isLoading: isLoading } = query ? query(filters) : {};
	const { getValues, control } = form;
	const register = form.register(fieldName, registerOptions);
	const { onChange, ...rest } = register;

	
	const onDragEnd = useCallback((event) => {
		const { active, over } = event;

		if (!active || !over) return;

		setSelected((items) => {
			const oldIndex = items.findIndex((item) => item[valueName] === active.id);
			const newIndex = items.findIndex((item) => item[valueName] === over.id);
			return arrayMove(items, oldIndex, newIndex);
		});
	}, [setSelected, valueName]);

	let [createItem, itemCreationResult] = [];

	if (mutation) {
		[createItem, itemCreationResult] = mutation();
	}

	const handleCreate = (inputValue) => {
		if (onCreateCallback) {
			onCreateCallback(inputValue, createItem);
		}
		else {
			createItem({
				[keyName]: inputValue
			});
		}
	};

	useEffect(() => {
		if (multiselect && getValues(fieldName)?.length > 0) {
			setSelected(getValues(fieldName));
		}
	}, [multiselect, setSelected, getValues, fieldName]);

	useEffect(() => {
		if (!itemCreationResult?.isLoading) {
			if (itemCreationResult?.isSuccess) {
				onChange({
					target: {
						name: fieldName,
						value: itemCreationResult.data[valueName]
					}
				})
			}
		}

		if (!itemCreationResult?.isLoading && itemCreationResult?.isSuccess) {
			closeModal();
			if (multiselect) {
				setSelected([...selected, itemCreationResult.data]);
			}
		}
	}, [itemCreationResult, onChange, fieldName, valueName]);

	useEffect(() => {
		if (selected && multiselect) {
			onChange({
				target: {
					name: fieldName,
					value: valueManipulation? valueManipulation(selected) : selected
				}
			})
		}
	}, [onChange, selected, fieldName, valueManipulation, multiselect])

	return (
		<Stack w='full' spacing={0.5}>
			{!hideLabel && (<Text fontSize='12px' fontWeight='600'>
				{label}
			</Text>)}
			<	Controller
				name={fieldName}
				control={control}
				render={({ field, fieldState, formState}) => {
					return (
						multiselect && !createable ? (<>
							<DndContext modifiers={[restrictToParentElement]} onDragEnd={onDragEnd}>
							{/* collisionDetection={closestCenter} */}
								<SortableContext
									items={selected?.map((o) => o)}
									strategy={verticalListSortingStrategy}
								>
									<Select
										distance={2}
										isMulti
										isLoading={!options && isLoading}
										options={options ?? data?.data}
										{...rest}
										value={selected?.map((o) => typeof(o) !== 'object' ? ((options ?? data?.data)?.filter((item) => item.id === o)[0]) : o)}
										onChange={onSelectChanged}
										components={{
											// @ts-ignore We're failing to provide a required index prop to SortableElement
											MultiValue,
											MultiValueRemove,
										}}
										isOptionDisabled={() => !maxOptions ? false : selected.length >= maxOptions}
										closeMenuOnSelect={false}
										getOptionLabel={option => keyInterpolation ? keyInterpolation(option) : option[keyName]}
										getOptionValue={option => option[valueName]}
										styles={{
											control: (provided) => ({
												...provided,
												width: width ?? '270px',
												
											})
										}, customStyles}
									/>
								</SortableContext>
							</DndContext>
						</>) :
							(!createable ? (
								<>
									<Select
										isLoading={!options && isLoading}
										options={options ?? data?.data}
										value={
											(options ?? data?.data)?.filter(option =>
												ignoreCaseSensitive? (option[valueName]?.toLowerCase() === getValues(fieldName)?.toLowerCase()) : (option[valueName] === getValues(fieldName)))
										}
										onChange={(e) => onChange({
											target: {
												name: fieldName,
												value: e[valueName]
											}
										})}
										{...rest}
										getOptionLabel={option => keyInterpolation ? keyInterpolation(option) : option[keyName]}
										getOptionValue={option => option[valueName]}
										styles={{
											control: (provided) => ({
												...provided,
												width: width ?? '270px',
												
											})
										}, customStyles}
										{...props}
									>
									</Select>
									{fieldState.invalid && (formState.submitCount > 0 || fieldState.isDirty) ? (
										<>
											<Text textColor={'red'}>
												{fieldState.error.message}
											</Text>
										</>) : (<>
										</>)}
								</>
							) : (
								<>
									<CreatableSelect
										isLoading={(!options && isLoading) || itemCreationResult?.isLoading}
										options={options ?? data?.data}
										allowCreateWhileLoading={false}
										onCreateOption={handleCreate}
										onInputChange={(inputValue) => {setInputValue(inputValue)}}
										value={ multiselect?  selected?.map((o) => typeof(o) !== 'object' ? ((options ?? data?.data)?.filter((item) => item.id === o)[0]) : o) : (options ?? data?.data)?.filter(option =>
											option[valueName] === getValues(fieldName)) }
										onChange={multiselect? onSelectChanged : (e) => onChange({
											target: {
												name: fieldName,
												value: e[valueName]
											}
										}) }

										{...rest}
										
										
										isMulti={multiselect}

										getNewOptionData={(inputValue, optionLabel) => ({
											[valueName]: inputValue,
											[keyName]: optionLabel,
											__isNew__: true
										})}
										getOptionLabel={option => option[keyName]}
										getOptionValue={option => option[valueName]}
										styles={{
											control: (provided) => ({
												...provided,
												width: width ?? '270px',
												
											})
										}, customStyles}
									>
									</CreatableSelect>
									{fieldState.invalid && (formState.submitCount > 0 || fieldState.isDirty) ? (
										<>
											<Text textColor={'red'}>
												{fieldState.error.message}
											</Text>
										</>) : (<>
										</>)}
								</>
							)
							))
				}
				}>

			</Controller>
		</Stack>
	);
};

export default SelectField;