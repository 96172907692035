import { colors, dimentions } from '../../libs/config';
import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import { MdModeEditOutline } from "react-icons/md";

const PhotoInput = ({ src, w, onClick, imagePlaceholder, ...props }) => {
	return (
		<Flex
			position='relative'
			w={w ? w : "142px"}
			h={w ? w : "142px"}
			z='5'
			boxShadow='1px 1px 5px rgb(0,0,0,.2)'
			borderRadius={dimentions.borderRadius.lg}
		>
			<Flex
				onClick={onClick}
				position='absolute'
				p='4px'
				borderRadius='full'
				boxShadow='dark-lg'
				top='-10px'
				right='-10px'
				z='10'
				bgColor='white'
				cursor='pointer'
			>
				<MdModeEditOutline size='18px' color={colors.textLG} />
			</Flex>
			<Image
				src={src && typeof src === "string" && src.startsWith('data:') ? src : (imagePlaceholder ?? "/media/avatars/blank.png")}
				border='4px solid white'
				borderRadius={dimentions.borderRadius.lg}
				w='full'
				h='full'
				objectFit='contain'
				objectPosition='center'
				alt='profile picture'
				z='-1'
				{...props}
			></Image>
		</Flex>
	);
};

export default PhotoInput;
