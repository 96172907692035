import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./base.api";
import { toast } from "react-toastify";

const tags = ["Challenge"];
export const challengesApi = createApi({
	reducerPath: "challengesApi",
	baseQuery: axiosBaseQuery({
        baseUrl: "",
    }),
	tagTypes: tags,
	endpoints: (builder) => ({
		getChallenges: builder.query({
			query: ({ search, page = 1, pageSize = 10, ids, purpose }) => ({
				url: `/Management/Challenge`,
				method: "GET",
				params: {
					page: page,
					ids: ids,
					pageSize: pageSize,
					...(search && { search }),
					...(purpose && { purpose: purpose }),
				}
			}),
			providesTags: ["Challenge"],
		}),
        getReport: builder.query({
			query: ({ examId, challengeId }) => ({
				url: `/Management/Challenge/Report`,
				method: "GET",
				params: {
					examId: examId,
					challengeId: challengeId
				}
			})
		}),
        checkIfReportAvailable: builder.query({
			query: ({ examId, challengeId }) => ({
				url: `/Management/Challenge/ReportStatus`,
				method: "GET",
				params: {
					examId: examId,
					challengeId: challengeId
				}
			})
		}),
        getIframe: builder.query({
            query: ({ examId, challengeId } = {}) => ({
                url: "/Challenge/start",
                method: "GET",
                params: {
                    examId: examId,
                    challengeId: challengeId,
                },
            }),
        }),
        checkVsCodeAvailablity: builder.query({
            query: ({ examId, challengeId } = {}) => ({
                url: "/Challenge/Check",
                method: "GET",
                params: {
                    examId,
                    challengeId,
                },
            }),
        }),
        initChallenge: builder.mutation({
            query: ({ examId, challengeId } = {}) => ({
                url: "/Challenge/init",
                method: "GET",
                params: { examId, challengeId },
            }),
        }),
        getChallenge: builder.query({
            query: (challengeId) => ({
                url: "/challenge",
                method: "GET",
                params: {
                    id: challengeId,
                },
            }),
            providesTags:  (result, error, args) => args ? [{ type: 'Challenge', id: args?.challengeId}] : ['Challenge'],
        }),
        submitChallenge: builder.mutation({
            query: ({ examId, challengeId, answers } = {}) => ({
                url: "/Challenge/submit",
                method: "PATCH",
                body: answers?? [],
                params: {
                    examId,
                    challengeId
                },
            }),
            invalidatesTags: (result, _, params) => ([{type: 'Challenge', id: params.challengeId}, {type: 'Exam', id: params.examId}])
        }),
	}),
});

export const {
	useGetChallengesQuery,
    useGetReportQuery,
    useCheckIfReportAvailableQuery,
    useGetIframeQuery,
    useCheckVsCodeAvailablityQuery,
    useGetChallengeQuery,
    useInitChallengeMutation,
    useSubmitChallengeMutation,
    // useGetClonesQuery,
} = challengesApi;
