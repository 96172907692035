import React from 'react'
import {Navigate, Route, Routes, Outlet, useLoaderData, useRouteLoaderData} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {AccountHeader} from '../accounts/AccountHeader'
import { MatchCard } from './components/MatchCard'
import { MatchStatusDetails } from 'src/app/libs/MatchStatus'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Account',
    path: '/crafted/account/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const CandidateMatches = ({ }) => {


const { matches } = useRouteLoaderData('candidateMatches');

  return (
   <>  
  <div className='row g-6 g-xl-9'>
   
   {matches.map((match, index) => {
      return (
        <div className='col-md-6 col-xl-4' key={index}>
          <MatchCard
            icon={match.companyLogo}
            badgeColor={MatchStatusDetails[match.status]['badgeColor']}
            status={MatchStatusDetails[match.status]['label']}
            statusColor={MatchStatusDetails[match.status]['badgeColor']}
            id={match.id}
            score={match.score}
            title={match.title}
            description={match.description}
            date={match.date}
            location={match.locations.join(', ')}
            progress={match.progress}
          />
        </div>
      )
    
   })}


   {/* <div className='col-md-6 col-xl-4'>
     <MatchCard
       icon='/media/svg/brand-logos/disqus.svg'
       badgeColor='info'
       status='Pending'
       statusColor='info'
       title='Leaf CRM'
       description='CRM App application to HR efficiency'
       date='May 10, 2021'
       budget='$36,400.00'
       progress={30}
    //    users={users2}
     />
   </div>

   <div className='col-md-6 col-xl-4'>
     <MatchCard
       icon='/media/svg/brand-logos/figma-1.svg'
       badgeColor='success'
       status='Completed'
       statusColor='success'
       title='Atica Banking'
       description='CRM App application to HR efficiency'
       date='Mar 14, 2021'
       budget='$605,100.00'
       progress={100}
    //    users={users3}
     />
   </div>

   <div className='col-md-6 col-xl-4'>
     <MatchCard
       icon='/media/svg/brand-logos/sentry-3.svg'
       badgeColor='info'
       status='Pending'
       statusColor='info'
       title='Finance Dispatch'
       description='CRM App application to HR efficiency'
       date='Mar 14, 2021'
       budget='$605,100.00'
       progress={60}
    //    users={users4}
     />
   </div>

   <div className='col-md-6 col-xl-4'>
     <MatchCard
       icon='/media/svg/brand-logos/xing-icon.svg'
       badgeColor='primary'
       status='In Progress'
       statusColor='primary'
       title='9 Degree'
       description='CRM App application to HR efficiency'
       date='Mar 14, 2021'
       budget='$605,100.00'
       progress={40}
    //    users={users5}
     />
   </div>

   <div className='col-md-6 col-xl-4'>
     <MatchCard
       icon='/media/svg/brand-logos/tvit.svg'
       badgeColor='primary'
       status='In Progress'
       statusColor='primary'
       title='9 Degree'
       description='CRM App application to HR efficiency'
       date='Mar 14, 2021'
       budget='$605,100.00'
       progress={70}
    //    users={users6}
     />
   </div>

   <div className='col-md-6 col-xl-4'>
     <MatchCard
       icon='/media/svg/brand-logos/aven.svg'
       badgeColor='primary'
       status='In Progress'
       statusColor='primary'
       title='Buldozer CRM'
       description='CRM App application to HR efficiency'
       date='Mar 14, 2021'
       budget='$605,100.00'
       progress={70}
    //    users={users7}
     />
   </div>

   <div className='col-md-6 col-xl-4'>
     <MatchCard
       icon='/media/svg/brand-logos/treva.svg'
       badgeColor='danger'
       status='Overdue'
       statusColor='danger'
       title='Aviasales App'
       description='CRM App application to HR efficiency'
       date='Mar 14, 2021'
       budget='$605,100.00'
       progress={10}
    //    users={users8}
     />
   </div>

   <div className='col-md-6 col-xl-4'>
     <MatchCard
       icon='/media/svg/brand-logos/kanba.svg'
       badgeColor='success'
       status='Completed'
       statusColor='success'
       title='Oppo CRM'
       description='CRM App application to HR efficiency'
       date='Mar 14, 2021'
       budget='$605,100.00'
       progress={100}
    //    users={users9}
     />
   </div> */}
 </div></>
  )
}

export default CandidateMatches
