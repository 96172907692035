/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from 'src/_metronic/helpers'
import { Link, useLoaderData, useLocation, useParams, useRouteLoaderData } from 'react-router-dom'
import { Dropdown1 } from 'src/_metronic/partials'
import { useUserAbilities } from 'src/app/libs/ability'
import { ProfileHeader } from 'src/app/modules/profile/ProfileHeader'
import { useAuth } from '../auth'
import { useGetUserQuery } from 'src/rtk/users.api'
import { Can } from 'src/app/routing/AppRoutes'
import ExamPage from '../exams/ExamPage'

const ExamPageWrapper: React.FC = () => {
  const { currentUser } = useAuth()
  const { exam } = useRouteLoaderData("exam")

  const breadCrumbs: Array<PageLink> = [
    {
        title: 'Account',
        path: '#',
        isSeparator: false,
        isActive: false
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false
    },  
    {
        title: 'Exams',
        path: '/account/exams',
        isSeparator: false,
        isActive: false
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false
    }
]

  return (
    <ExamPage exam={exam} candidate={currentUser} />
  )
}

export { ExamPageWrapper }
