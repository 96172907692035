/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData } from "react-router-dom"
import DataTable from 'src/app/components/DataTable'

import { skipToken } from "@reduxjs/toolkit/query"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button, Tag } from '@chakra-ui/react';
import { Toolbar } from 'src/_metronic/layout/components/toolbar/Toolbar'
import { ToolbarReports } from 'src/_metronic/layout/components/toolbar/toolbars'
import { Page } from 'src/app/components/Page'
import { PositionsChart } from 'src/app/components/charts/PositionsChart'
import { colors } from 'src/app/libs/config'
import Row from 'src/app/components/temp/Row'
import { Can } from 'src/app/routing/AppRoutes'
import DepartmentTag from 'src/app/components/Tags/DepartmentTag'
import StatusTag from 'src/app/components/Tags/StatusTag'
import RoundedProgressCard from 'src/app/components/charts/RoundedProgressCard'
import moment from "moment";
import { useGetAllOrganizationsQuery } from 'src/rtk/organizations.api'
import RolesTag from 'src/app/components/Tags/RolesTag'
import ManageOrganization from './components/ManageOrganization'


const columnHelper = createColumnHelper()

const breadCrumbs: Array<PageLink> = [
    {
        title: 'Management',
        path: '#',
        isSeparator: false,
        isActive: false
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: true,
    },
]


export const OrganizationsPage = ({ }) => {
    const { overview, organizations } = useLoaderData();

    const colmns = [
        columnHelper.accessor('name', {
            header: 'Customer Name'
        }),
        columnHelper.accessor("taxId", {
            header: 'Tax ID'
        }),
        columnHelper.accessor("created", {
            header: 'Join date',
            cell: (info) => moment(info.getValue()).format("MMM DD, YYYY")
        }),
        columnHelper.accessor("contactName", {
            header: 'Contact Name'
        }),
        columnHelper.accessor('email', {
            header: 'Contact Email'
        }),
        columnHelper.accessor('phoneNumber', {
            header: 'Contact Phone'
        }),
        columnHelper.accessor('active', {
            header: 'Status',
            cell: (info) => (
                <Tag colorScheme={info.getValue() ? "green" : "red"}>
				    {info.getValue() ? "Active" : "Inactive"}
			    </Tag>
            )
        }),
        columnHelper.accessor('id', {
            header: 'Details',
            enableSorting: false,
            cell: (info) => {
                return (
                    <ManageOrganization key={Date.now()} organization={info.row.original} />
                )
            }
        })
    ];


    return (
        <>
            <Page breadcrumbs={breadCrumbs} title={'Organizations'} columns={colmns} dataSourceQueryFn={useGetAllOrganizationsQuery}>
                <div key='toolbar'>
                    <ManageOrganization />
                </div>
                <div key='middle'>
                    <Row>
                        <PositionsChart
                            flex={1}
                            title='Total Organizations'
                            total={
                                overview?.reduce(
                                    (total, num) => total + Number(num.value),
                                    0
                                ) || 0
                            }
                            color={["#50CD89", "#FF9F9F", "#E4E6EF"]}
                            data={overview?.map((item, idx) => item.name) || []}
                            values={overview?.map((item, idx) => item.value) || []}
                            amount={overview?.map((item, idx) => item.value) || []}
                        />
                    </Row>
                </div>
            </Page>
        </>
    )
}


export default OrganizationsPage