import {
    Column,
    Table as ReactTable,
    PaginationState,
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    ColumnDef,
    OnChangeFn,
    flexRender,
    functionalUpdate
} from '@tanstack/react-table'

import { useDebounce } from "@uidotdev/usehooks"

import { KTIcon, toAbsoluteUrl } from '../../../src/_metronic/helpers'
import React, { useEffect, useState, useMemo } from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../_metronic/layout/core'

import { InputGroup, InputLeftAddon, Input, Select } from '@chakra-ui/react'
import { BiSearch, BiStar } from "react-icons/bi"
import { BsFillStarFill } from "react-icons/bs"

import clsx from 'clsx'

import { useLayout } from '../../_metronic/layout/core'


import { CreateAppModal, Dropdown1 } from '../../../src/_metronic/partials'
import { renderChildren } from '../libs/utils'
import { useSavePinnedDataMutation, useGetPinnedDataByPathQuery } from 'src/rtk/pinneddata.api'
import { skipToken } from '@reduxjs/toolkit/query'
import { useSearchParams } from 'react-router-dom'
import useNumericQueryParam from '../libs/params.helpers'
import { useQueryParam } from '../libs/params.helpers'

const deepCopy = (obj) => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        const arrCopy = [];
        for (let i = 0; i < obj.length; i++) {
            arrCopy[i] = deepCopy(obj[i]);
        }
        return arrCopy;
    } else {
        const objCopy = {};
        for (let key in obj) {
            objCopy[key] = deepCopy(obj[key]);
        }
        return objCopy;
    }
}

const DataTable: React.FC<{
    onPaginationChangedFn: any,
    onGlobalFilterChangedFn: any,
    queryParams: any,
    dataSourceQueryFn: any,
    dataPrepareFn: any,
    columns: any,
    title: string,
    showFilters?: bool,
    children: any
}> = ({ onPaginationChangedFn, onGlobalFilterChangedFn, queryParams, dataSourceQueryFn, dataPrepareFn, columns, title, showFilters, children, enableRowPinning, enableSorting, externalData }) => {
    const PAGE_SIZE = 10;

    const [searchParams, setSearchParams] = useSearchParams();

    const [getQueryParams, setQueryParams] = useState({
        page: useNumericQueryParam('page', 1),
        pageSize: useNumericQueryParam('pageSize', 10),
        ...(searchParams.get('filter') !== null && { filter: searchParams.get('filter')}),
        ...(searchParams.get('order') !== null && { order: searchParams.get('order')}),
        ...(searchParams.get('sort') !== null && { sort: searchParams.get('sort')}),
        ...queryParams,
    })

    const [savePinnedRows, savePinnedRowsResult] = useSavePinnedDataMutation();

    const [sorting, setSorting] = useState({id: searchParams.get('sort'), desc: searchParams.get('order')});

    const { data: pinnedRowsData, isError: pinnedRowsDataError } = useGetPinnedDataByPathQuery(enableRowPinning ? { path: title } : skipToken);

    const [pinnedRows, setPinnedRows] = useState({});

    useEffect(() => {
        if (pinnedRowsData && !pinnedRowsDataError) {
            setPinnedRows(pinnedRowsData.pinnedGuids.reduce((obj, guid) => {
                obj[guid] = true;
                return obj;
            }, {}));
        }
    }, [pinnedRowsData, pinnedRowsDataError]);

    const togglePinRow = (rowId) => {
        const newPinnedRows = { ...pinnedRows, [rowId]: !pinnedRows[rowId] };
        setPinnedRows(newPinnedRows);
        savePinnedRows({
            path: title,
            pinnedGuids: Object.entries(newPinnedRows)
                .filter(([id, value]) => value === true)
                .map(([id, value]) => id)
        })
    };

    const [searchInputValue, setSearchInputValue] = useState(useQueryParam('filter', undefined));
    const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);

    const { data: data, isError: dataError, isLoading: dataLoading } = dataSourceQueryFn(externalData ? skipToken : getQueryParams);


    if (externalData) {
        data = externalData;
    }

    const sortedData = useMemo(() => {
        if (data) {
            const dataToSort = dataPrepareFn ? dataPrepareFn(deepCopy(data.data)) : data.data
            const pinnedData = dataToSort.filter(row => pinnedRows[row.id]);
            const unpinnedData = dataToSort.filter(row => !pinnedRows[row.id]);
            return [...pinnedData, ...unpinnedData];
        }
        else
            return [];
    }, [data, pinnedRows]);

    const [pagination, setPagination] = useState({'length': data?.count})

    const [pageCount, setPageCount] = useState(Math.ceil(pagination?.length ?? 0 / PAGE_SIZE))

    const table = useReactTable({
        data: sortedData,
        columns,
        initialState: {
            pagination: {
                pageSize: getQueryParams.pageSize,
                pageIndex: getQueryParams.page
            },
            globalFilter: useQueryParam('filter', undefined)
        },
        // getRowId: (row) => row.id,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        manualFiltering: true,
        pageCount: pageCount,
        manualPagination: true,
        enableRowPinning: enableRowPinning,
        enableSorting: enableSorting,
        manualSorting: true,
        onSortingChange: (state) => {
            console.log('sortingStateChanged', state)
        }
    })

    useEffect(() => {
        setPageCount(Math.ceil(pagination.length / table.getState().pagination.pageSize))
        onPageChanged(table.getState().pagination)
    }, [table.getState().pagination])

    // const debouncedSearchTerm = useDebounce(table.getState().globalFilter, 700);

    // useEffect(() => {
    //     if (table.getState().globalFilter !== undefined)
    //         onGlobalFilterChanged(table.getState().globalFilter)
    // }, [table.getState().globalFilter])

    useEffect(() => {
        for (let key in getQueryParams) {
            if (getQueryParams.hasOwnProperty(key)) {
                if (key === 'page' || key === 'pageSize' || key === 'filter' || key === 'order' || key === 'sort') {
                    if (getQueryParams[key] || getQueryParams[key] === '') {
                        if (key === 'filter')
                            searchParams.set('filter', getQueryParams[key]);
                        if (key === 'page')
                            searchParams.set('page', table?.getState()?.pagination?.pageIndex ?? 1);
                        else
                            searchParams.set(key, getQueryParams[key]);
                    }
                }
            }
          }

        setSearchParams(searchParams, {replace: true})

    }, [getQueryParams])

    useEffect(() => {
        setSearchInputValue(table.getState().globalFilter)
    }, [table.getState().globalFilter])

    useEffect(() => {
        if (data) {
            setPagination({'length': data.count})
            const pCount = Math.ceil(data.count / table.getState().pagination.pageSize + 1)
            setPageCount(pCount)
            if (pCount < table.getState().pagination.pageIndex) {
                table.resetPagination(true)
            }
        }
    }, [data])

    const onPageChanged = (paginationModel) => {
        setQueryParams({ ...getQueryParams, page: paginationModel.pageIndex, pageSize: paginationModel.pageSize });

        if (onPaginationChangedFn) {
            onPaginationChangedFn({ ...getQueryParams, page: paginationModel.pageIndex + 1, pageSize: paginationModel.pageSize })
        }
    };

    const onGlobalFilterChanged = (query) => {
        if (getQueryParams.filter !== query) {
            setQueryParams({ ...getQueryParams, filter: query, page: 1 })
            if (onGlobalFilterChangedFn) {
                onGlobalFilterChangedFn({ ...getQueryParams, filter: query, page: 1 })
            }
        }
    }

    const handleFocus = () => setIsSearchInputFocused(true);

    const handleBlur = () => {
        if (!searchInputValue || searchInputValue.trim() === '') {
            setIsSearchInputFocused(false);
        }
    };

    useEffect(() => {
        if (sorting.id)
            setQueryParams({ ...getQueryParams, sort: sorting.id, order: sorting.desc ? 'desc' : 'asc' });
    }, [sorting])

    const { config } = useLayout()

    const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop
        ? 'btn-light'
        : 'bg-body btn-color-gray-700 btn-active-color-primary'


    const handleEnterPress = (event) => {
        if (event.key === 'Enter') {
            if (table.getState().globalFilter !== undefined)
                onGlobalFilterChanged(table.getState().globalFilter)
        }
    };


    return (<>
        <div className="p-2">
            <div className="h-2" />
            {/* begin::Body */}
            <div className='card-body py-3'>
                {title ? (
                    <>
                        <div className='d-flex'>
                            <div className='me-auto'>
                                <div className='d-flex align-items-center mb-5'>
                                    <div className='pe-4'>
                                        <h5 className='mb-0'>Results: {pagination.length}</h5>
                                    </div>
                                    <div>
                                        <InputGroup size='sm'>
                                            <InputLeftAddon px={2} borderLeftRadius='6px' h='34px'>
                                                <BiSearch size='15px' z='10' />
                                            </InputLeftAddon>
                                            <Input
                                                px={2}
                                                borderRightRadius='6px'
                                                h='34px'
                                                placeholder='Search'
                                                transition="width 0.3s ease-in-out"
                                                value={searchInputValue}
                                                width={searchInputValue || isSearchInputFocused ? "300px" : "120px"}
                                                onChange={(e) => table.setGlobalFilter(e.target.value)}
                                                onKeyUp={handleEnterPress}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                            />
                                        </InputGroup>
                                    </div>
                                </div>
                            </div>
                            <div className="align-items-center">
                                <div className='d-flex align-items-center gap-2 gap-lg-3'>
                                    {showFilters && <div className='m-0'>
                                        <a
                                            href='#'
                                            className={clsx('btn btn-sm btn-flex fw-bold', daterangepickerButtonClass)}
                                            data-kt-menu-trigger='click'
                                            data-kt-menu-placement='bottom-end'
                                        >
                                            <KTIcon iconName='filter' className='fs-6 text-muted me-1' />
                                            Filter
                                        </a>
                                        <Dropdown1 />
                                    </div>
                                    }
                                    {renderChildren(children, 'actions')}
                                </div>
                            </div>
                        </div>

                    </>
                ) : <></>}

                {/* begin::Table container */}
                <div className='table-responsive table-loading'>
                    <div className={`${dataLoading ? 'table-loading-message' : 'd-none'}`}>
                        Loading...
                    </div>
                    {/* begin::Table */}
                    <table className='table align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                            {table?.getHeaderGroups()?.map((headerGroup) => (
                                <tr key={headerGroup.id} className='fw-bold  bg-light'>
                                    {enableRowPinning && <th key={'pinned'} style={{ width: '100px' }}>
                                    </th>}
                                    {headerGroup.headers.map((header, index) => (
                                        <th key={header.id}
                                            onClick={() => {
                                                const isCurrentlyAsc = sorting.id === header.id && !sorting.desc;
                                                if (header.column.accessorFn && header.column.columnDef.enableSorting !== false) {
                                                    setSorting({
                                                        id: header.id,
                                                        desc: isCurrentlyAsc
                                                    });
                                                }
                                            }}
                                            className={`${header.column.id === sorting?.id ? `table-sort-${sorting.desc ? 'desc' : 'asc'}` : 'text-muted'} ${header.column.accessorFn && header.column.columnDef.enableSorting !== false ? 'cursor-pointer' : ''} user-select-none ${index === 0? 'ps-4 rounded-start' : (index === headerGroup.headers.length - 1? 'text-end rounded-end pe-4' : '')} text-uppercase`} style={{ width: `${100 / headerGroup.headers.length * header.colSpan}%` }} scope='col'>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext(),
                                                )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {table?.getRowModel()?.rows.map((row) => (
                                <tr key={row.id}>
                                    {enableRowPinning && <td key={`${row.id}_pinning`}>
                                        <button onClick={() => togglePinRow(row.original.id)}>
                                            {!pinnedRows[row.original.id] && <BiStar color='var(--bs-primary)' pageSize='20px' z='10' />}
                                            {pinnedRows[row.original.id] && <BsFillStarFill color='var(--bs-primary)' pageSize='20px' z='10' />}
                                        </button>
                                    </td>}
                                    {row.getVisibleCells().map((cell, index) => (
                                        <td key={cell.id} className={index === row.getVisibleCells().length - 1 ? 'text-end' : 'align-items-center'}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            <div className="h-2" />
            <div className='d-flex justify-content-center'>
            {data && (<ul className="pagination">
                    <li className={"page-item previous " + (!table?.getCanPreviousPage() ? 'disabled' : 'cursor-pointer')} onClick={() => table?.previousPage()} disabled={!table?.getCanPreviousPage()}>
                        <span className="page-link">
                            <i className="previous"></i> Prev
                        </span>
                    </li>

                    {/* Show the first page and possibly an ellipsis */}
                    {table?.getState()?.pagination?.pageIndex > 2 && (
                        <>
                            <li className="page-item cursor-pointer" onClick={() => table?.setPageIndex(1)}>
                                <span className="page-link">1</span>
                            </li>
                            <li className="page-item">
                                <span className="page-link">...</span>
                            </li>
                        </>
                    )}

                    {/* Show a few pages around the current page */}
                    {table?.getPageCount() && Array.from(Array(table?.getPageCount())?.slice(Math.max(0, table?.getState()?.pagination?.pageIndex - 2), table?.getState()?.pagination?.pageIndex + 3))?.map((e, i) => {
                        const pageNumber = i + table?.getState()?.pagination?.pageIndex - 2;
                        return pageNumber >= 1 && pageNumber < table.getPageCount() ? (
                            <li key={pageNumber} className={"page-item cursor-pointer " + (pageNumber === table?.getState()?.pagination?.pageIndex ? 'active' : '')} onClick={() => table?.setPageIndex(pageNumber)}>
                                <span className="page-link">
                                    {pageNumber}
                                </span>
                            </li>
                        ) : null;
                    })}

                    {/* Show an ellipsis and the last page if needed */}
                    {table?.getState()?.pagination?.pageIndex < table?.getPageCount() - 3 && (
                        <>
                            <li className="page-item">
                                <span className="page-link">...</span>
                            </li>
                            <li className="page-item cursor-pointer" onClick={() => table?.setPageIndex(table?.getPageCount() - 1)}>
                                <span className="page-link">{table?.getPageCount() - 1}</span>
                            </li>
                        </>
                    )}

                    <li className={"page-item next " + (!table.getCanNextPage() ? 'disabled' : 'cursor-pointer')} onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                        <span className="page-link">
                            Next <i className="next"></i>
                        </span>
                    </li>
                </ul>)}
                {data?.count > 0 &&
                (<div style={{ width: '100px' }}>
                    <Select
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </Select>
                </div>)}
            </div>
        </div>
    </>)
}

export default DataTable
