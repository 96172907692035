/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import TextShortener from './TextShortener'
import { format } from 'date-fns';
import TimeAgo from './TimeAgo';
import { KTIcon } from 'src/_metronic/helpers';

type Props = {
  icon: string
  badgeColor: string
  status: string
  statusColor: string
  title: string
  description: string
  date: string
  id: string
  location: string
  progress: number
  score: number
}

const MatchCard: FC<Props> = ({
  icon,
  badgeColor,
  status,
  statusColor,
  title,
  description,
  date,
  id,
  location,
  progress,
  score
}) => {
  return (
    <Link
      to={id}
      className='card border border-2 border-gray-300 border-hover'
    >
      <div className='card-header border-0 pt-9'>
        <div className='card-title m-0'>
          <div className='symbol symbol-50px w-50px bg-light'>
            <img src={(icon)} alt='card2' className='p-3' />
          </div>
        </div>

        <div className='card-toolbar'>
          <span className={`badge badge-light-${badgeColor} fw-bolder me-auto px-4 py-3`}>
            {status}
          </span>
        </div>
      </div>

      <div className='card-body p-9'>
        <div className='fs-3 fw-semibold text-dark'>{title}</div>
        <div className='d-flex flex-wrap fw-medium fs-10 mb-4 pe-2 py-5'>
          <div
            className='d-flex align-items-center text-hover-primary me-5 mb-2'
          >
            <KTIcon iconName='rocket' className='fs-4 me-1' />
            Match Score: <span className={`badge badge-light-${score > 80 ? 'success' : 'warning'} fw-bolder me-auto mx-3`}>
              {score}
            </span>
          </div>
          <div
            className='d-flex align-items-center text-gray-400 fw-medium text-hover-primary mb-2 me-5'
          >
            <KTIcon iconName='time' className='fs-4 me-1' />
            <TimeAgo date={date} />
          </div>
          <div
            className='d-flex align-items-center text-gray-400 fw-medium text-hover-primary mb-2'
          >
            <KTIcon iconName='geolocation' className='fs-4 me-1' />
              {location}
          </div>
        </div>

        <p className='fw-normal fs-6 mt-1 mb-7'><span className='fw-bold'>Overview</span> <br/><TextShortener text={description} /></p>

        {/* <div className='d-flex flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
            <div className='fs-6 text-gray-800 fw-bolder'>{format(new Date(date), 'dd/MM/yyyy HH:mm')}</div>
            <div className='fw-bold text-gray-400'>Matched At</div>
          </div>

          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
            <div className='fs-6 text-gray-800 fw-bolder'>{budget}</div>
            <div className='fw-bold text-gray-400'>Budget</div>
          </div>
        </div> */}
{/* 
        <div
          className='h-4px w-100 bg-light mb-5'
          data-bs-toggle='tooltip'
          title='This project completed'
        >
          <div
            className={`bg-${statusColor} rounded h-4px`}
            role='progressbar'
            style={{ width: `${progress}%` }}
          ></div>
        </div> */}

        {/* <UsersList users={users} /> */}
      </div>
    </Link>
  )
}

export { MatchCard }
