import React from "react";
import SelectField from "./SelectField";
import { useGetAllRolesQuery } from "src/rtk/users.api";

function RoleSelectField({ label, h, form, fieldName, registerOptions, ...props }) {
	return (
		<SelectField
			form={form}
            fieldName={fieldName}
            keyName={'name'}
            valueName={'id'}
            query={useGetAllRolesQuery}
            multiselect={true}
            valueManipulation={(selected) => selected.map((value) => (value?.id ?? value))}
            maxOptions={1}
			label={label ?? 'Roles'}
			{...props}
		/>
	);
}

export default RoleSelectField;
