import React, { useContext, useEffect, useRef } from 'react';
import { LoadingContext } from './LoadingContext';

const LoadingOverlay = () => {
  const { loading } = useContext(LoadingContext);
  const overlayRef = useRef(null);

  useEffect(() => {
    const overlay = document.getElementById('loading-overlay');
    if (loading) {
      overlay.style.display = 'flex';
    } else {
      setTimeout(() => {
        overlay.style.display = 'none';
      }, 500)
    }
  }, [loading]);

  return null;
};

export default LoadingOverlay;