/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, Children, cloneElement, useState, useEffect } from 'react'

import DataTable from './DataTable'
import { PageTitle } from 'src/_metronic/layout/core'
import { renderChildren } from 'src/app/libs/utils'


export const Page = ({ title, subheader, children, dataSourceQueryFn, breadcrumbs, columns, onPaginationChangedFn, onGlobalFilterChangedFn, queryParams, dataPrepareFn, dataGridTitle, enableRowPinning, ...props }) => {

    // const [getQueryParams, setQueryParams] = useState({
    //     page: 0,
    //     size: 10,
    //     ...queryParams
    // })

    // const { data: data, isError: dataError } = dataSourceQueryFn(getQueryParams);

    const onPageChanged = (paginationModel) => {
        // setQueryParams({  ...getQueryParams, page: paginationModel.pageIndex, size: paginationModel.pageSize, ...queryParams });
        // if (onPaginationChangedFn) {
        //     onPaginationChangedFn({ ...getQueryParams, page: paginationModel.pageIndex, size: paginationModel.pageSize, ...queryParams })
        // }
    };

    const onGlobalFilterChanged = (query) => {
        // setQueryParams({ ...getQueryParams, search: query, page: 0, ...queryParams })
        // if (onGlobalFilterChangedFn) {
        //     onGlobalFilterChangedFn({ ...getQueryParams, search: query, page: 0, ...queryParams })
        // }
    }


    const {className, ...rest} = props;


    return (
        <>
            {/* <PageTitle breadcrumbs={breadcrumbs}>
                {title}
                {renderChildren(children, 'toolbar')}
            </PageTitle> */}
            {/* {renderChildren(children, 'middle', {className: 'mb-10'})} */}
            <>
                <div className={`card card-custom card-flush ${className}`}>
                    {title && <div className="card-header">
                        <div className='pt-5'>
                            <h3 className="card-title fs-3 fw-medium">{title}</h3>
                            {subheader && <h3 className="card-title fs-5 fw-medium mt-3 text-muted">{subheader}</h3>}
                        </div>
                        <div className="card-toolbar pt-5">
                            {renderChildren(children, 'toolbar')}
                        </div>
                    </div>}

                    {title && renderChildren(children, 'middle', { className: 'mb-10' })}
                    {dataSourceQueryFn ?
                        <>
                            <DataTable onPaginationChangedFn={onPageChanged} onGlobalFilterChangedFn={onGlobalFilterChanged} dataSourceQueryFn={dataSourceQueryFn} dataPrepareFn={dataPrepareFn} columns={columns} title={dataGridTitle ?? title} queryParams={queryParams} enableRowPinning={enableRowPinning} />
                        </> :
                        <>
                            {renderChildren(children, 'card')}
                        </>}

                </div>
            </>

            {renderChildren(children, 'footer')}
        </>
    )
}