import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./base.api";
import { toast } from "react-toastify";

const tags = ["Exams", "ClonedExams", "Position"];
export const examsApi = createApi({
	reducerPath: "examsApi",
	baseQuery: axiosBaseQuery({
        baseUrl: "",
    }),
	tagTypes: tags,
	endpoints: (builder) => ({
		getExams: builder.query({
			query: ({ search, page = 1, pageSize = 10, ids, purpose }) => ({
				url: `/Management/Exam`,
				method: "GET",
				params: {
					page: page,
					ids: ids,
					pageSize: pageSize,
					...(search && { search }),
					...(purpose && { purpose: purpose }),
				}
			}),
			providesTags: ["Exams"],
		}),
        getClones: builder.query({
			query: ({ search, page = 1, pageSize = 10, ids, id, purpose, associatedId, parentExamId, sort, order }) => ({
				url: `/Management/Exam/Clones`,
				method: "GET",
				params: {
					page: page,
					pageSize: pageSize,
					...(search && { search }),
					...(purpose && { purpose: purpose }),
					...(associatedId && { associatedId: associatedId }),
					...(parentExamId && { parentExamId: parentExamId }),
					...(ids && { ids: ids }),
					...(id && { ids: [id] }),
					...(sort && { sort: sort }),
					...(order && { order: order }),
				}
			}),
			providesTags: ["ClonedExams", "Position"],
		}),
        getPositionExamsOverview: builder.query({
            query: ({ id, positionId }) => ({
                url: `/management/exam/overview`,
                method: "GET",
                params: {
                    ...(id && { id: id }),
                    ...(positionId && { positionId: positionId })
                },
            }),
            providesTags: ["ClonedExams", "Position"],
        }),
        createExam: builder.mutation({
            query: (body) => {
                return {
                    url: "/management/exam",
                    method: "POST",
                    body: body,
                };
            },
            invalidatesTags: ["Exams"],
        }),
        updateExam: builder.mutation({
            query: (body) => {
                return {
                    url: "/management/exam",
                    method: "PUT",
                    body: body,
                };
            },
            invalidatesTags: ["Exams"],
        }),
        getMyExam: builder.query({
			query: ({ search, page = 1, pageSize = 10, id }) => ({
				url: `/Exam`,
				method: "GET",
				params: {
					page: page,
					pageSize: pageSize,
					...(id && { id: id }),
				}
			}),
			providesTags: ["ClonedExams"],
		}),
		getClonesOverview: builder.query({
			query: ({ positionId, parentExamId }) => ({
				url: `/management/Exam/clones/overview`,
				method: "GET",
				params: {
					...(positionId && { positionId: positionId }),
					...(parentExamId && { parentExamId: parentExamId }),
				}
			}),
			providesTags: ["ClonedExams"],
		}),
		getOverview: builder.query({
			query: () => ({
				url: `/management/Exam/overview`,
				method: "GET"
			}),
			providesTags: ["ClonedExams"],
		}),
	}),
});

export const {
	useGetExamsQuery,
    useGetClonesQuery,
    useGetPositionExamsOverviewQuery,
    useCreateExamMutation,
    useUpdateExamMutation,
    useGetMyExamQuery,
	useGetClonesOverviewQuery,
	useGetOverviewQuery,
} = examsApi;
