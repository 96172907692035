/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle, } from 'src/_metronic/layout/core'

import { Link, useLoaderData, useRouteLoaderData } from "react-router-dom"


import { Avatar, Flex, Stack, Text, Button } from '@chakra-ui/react';
import { colors } from 'src/app/libs/config'
import Moment from 'react-moment'
import { CandidateExams } from 'src/app/modules/exams/CandidateExams'
import { useAuth } from '../auth';


export const ExamsPage = ({ }) => {
    const { currentUser } = useAuth()
    const { candidatePositions } = useLoaderData()

    const breadCrumbs: Array<PageLink> = [
        {
            title: 'Account',
            path: '#',
            isSeparator: false,
            isActive: false
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false
        },
        {
            title: 'Profile',
            path: '/account/profile',
            isSeparator: false,
            isActive: false
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false
        }
    ]

    return (<CandidateExams title={'Exams'} candidate={currentUser} candidatePositions={candidatePositions} />)
}
