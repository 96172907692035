import React, { useState, useMemo, useEffect } from 'react'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { useForm } from 'react-hook-form'
import { useRouteLoaderData } from 'react-router-dom'
import TextField from 'src/app/components/form-inputs/TextField'
import SelectField from 'src/app/components/form-inputs/SelectField'
import PhoneField from 'src/app/components/form-inputs/PhoneField'
import countryList from 'react-select-country-list'
import EmailField from 'src/app/components/form-inputs/EmailField'
import ImageField from 'src/app/components/form-inputs/ImageField'
import CheckField from 'src/app/components/form-inputs/CheckField'
import { Can } from 'src/app/routing/AppRoutes'


export const ProfileDetails: React.FC = ({ viewOnly, user, updateMutation }) => {
    const [loading, setLoading] = useState(false)
    const form = useForm({
        mode: 'all',
        defaultValues: user
    })

    const { handleSubmit, reset } = form

    const [update, result] = updateMutation()

    const submit = (data) => {
        setLoading(true)
        update(data)
    }

    useEffect(() => {
        setLoading(false)
    }, [result]);

    const countries = useMemo(() => countryList().getData(), [])

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Profile Details</h3>
                </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={handleSubmit((data) => submit(data))} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-5 col-form-label fw-bold fs-6'>Avatar</label>
                            <div className='col-lg-7'>
                                {viewOnly ? (<div
                                    className='image-input image-input-outline'
                                    data-kt-image-input='true'
                                    style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}
                                >
                                    <div
                                        className='image-input-wrapper w-125px h-125px'
                                        style={{ backgroundImage: `url(${toAbsoluteUrl(user.avatar)})` }}
                                    ></div>
                                </div>) : <ImageField form={form} fieldName={'avatar'} imagePlaceholder={toAbsoluteUrl('/media/avatars/blank.png')} />}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-5 col-form-label required fw-bold fs-6'>Full Name</label>

                            <div className='col-lg-7'>
                                <div className='row'>
                                    <div className='col-lg-6 fv-row'>
                                        <TextField form={form}
                                            registerOptions={{
                                                maxLength: {
                                                    value: 20,
                                                    message: 'Max length: 20'
                                                },
                                                required: 'First Name is required!'
                                            }}
                                            fieldName={'firstName'}
                                            label='First Name' hideLabel={true} newVersion={true} disabled={viewOnly} className='form-control form-control-lg form-control-solid mb-3 mb-lg-0' />
                                    </div>

                                    <div className='col-lg-6 fv-row'>
                                        <TextField form={form}
                                            registerOptions={{
                                                maxLength: {
                                                    value: 20,
                                                    message: 'Max length: 20'
                                                },
                                                required: 'Last Name is required!'
                                            }}
                                            fieldName={'lastName'}
                                            disabled={viewOnly}
                                            label='Last Name' hideLabel={true} newVersion={true} className='form-control form-control-lg form-control-solid' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-5 col-form-label required fw-bold fs-6'>Gender</label>

                            <div className='col-lg-7 fv-row'>
                                <SelectField form={form} fieldName={'gender'} keyName={'label'} valueName={'value'} options={[{
                                    value: 'Male',
                                    label: 'Male'
                                }, {
                                    value: 'Female',
                                    label: 'Female'
                                }]}
                                    label={'Gender'}
                                    ignoreCaseSensitive={true}
                                    isDisabled={viewOnly}
                                    hideLabel={true}
                                />
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-5 col-form-label fw-bold fs-6'>
                                <span className='required'>Contact Phone</span>
                            </label>

                            <div className='col-lg-7 fv-row'>
                                <PhoneField form={form} fieldName={'phoneNumber'} label={'Contact Phone'} hideLabel={true} className='form-control form-control-lg form-control-solid' disabled={viewOnly} />
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-5 col-form-label required fw-bold fs-6'>Country</label>

                            <div className='col-lg-7 fv-row'>
                                <SelectField form={form} fieldName={'country'} keyName={'label'} valueName={'value'} options={countries}
                                    label={'Gender'}
                                    ignoreCaseSensitive={true}
                                    hideLabel={true}
                                    isDisabled={viewOnly}
                                />
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-5 col-form-label fw-bold fs-6'>
                                <span className='required'>Email</span>
                            </label>

                            <div className='col-lg-7 fv-row'>
                                <Can I={'update'} a={'ProfileEmail'} passThrough>
                                {allowed => <EmailField form={form} fieldName={'email'} label={'Email'} className='form-control form-control-lg form-control-solid' disabled={!allowed || viewOnly} hideLabel={true} />}
                                </Can>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-5 col-form-label fw-bold fs-6'>
                                <span >LinkedIn</span>
                            </label>

                            <div className='col-lg-7 fv-row'>
                                <TextField form={form} fieldName={'linkedInProfile'} label={'LinkedIn'} className='form-control form-control-lg form-control-solid' hideLabel={true} disabled={viewOnly} />
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-5 col-form-label fw-bold fs-6'>Communication</label>

                            <div className='col-lg-7 fv-row'>
                                <div className='d-flex align-items-center mt-3'>
                                    <label className='form-check form-check-inline form-check-solid me-5'>
                                        <CheckField form={form} fieldName={'emailNotifications'} hideLabel={true} />
                                        <span className='fw-bold ps-2 fs-6'>Email</span>
                                    </label>

                                    <label className='form-check form-check-inline form-check-solid'>
                                        <CheckField form={form} fieldName={'phoneNotifications'} hideLabel={true} />
                                        <span className='fw-bold ps-2 fs-6'>Phone</span>
                                    </label>
                                </div>
                            </div>
                        </div>


                    </div>

                    {!viewOnly && <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Save Changes'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>}
                </form>
            </div>
        </div>
    )
}