import {
	Box,
	Center,
	CircularProgress,
	CircularProgressLabel,
	Flex,
	Heading,
	Text,
} from "@chakra-ui/react";
import React from "react";
import Container from "./Container";

const Grade = ({ grade }) => {
	return (
		<Container flex={1} p='16px'>
			<Center flex={1}>
				<CircularProgress
					thickness={1}
					lineHeight={1}
					color='green.500'
					value={100}
					size='150px'
				>
					<CircularProgressLabel fontSize='24px' fontWeight='700' color='#888'>
						<Flex flex={1} flexDir="column" justify="center">
							<Text>Grade</Text>
							<Text>{grade}</Text>
						</Flex>
					</CircularProgressLabel>
				</CircularProgress>
			</Center>
		</Container>
	);
};

export default Grade;
