import { colors, dimentions } from "src/app/libs/config";
import { Button, Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";
// import  AssessmentChallenge  from "@/components/exam/AssessmentChallenge"; 
import { useLoaderData, useRouteLoaderData } from "react-router-dom";
import { PageTitle } from "src/_metronic/layout/core";
import Challenge from "./Challenge";
import ExamPage from "./ExamPage";


const CandidateExamPage = ({breadCrumbs}: {breadCrumbs?: any}) => {
	const { candidate } = useRouteLoaderData("candidateProfile");
	const { exam } = useRouteLoaderData("exam");

	const breadcrumbs = breadCrumbs ?? [
        {
            title: 'Management',
            path: '#',
            isSeparator: false,
            isActive: false
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false
        },
        {
            title: 'Candidates',
            path: '/management/candidates',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false
        },
        {
            title: `${candidate.firstName} ${candidate.lastName}'s Exams`,
            path: `/management/candidates/${candidate.id}/exams`,
            isSeparator: false,
            isActive: false,
        },
		{
            title: '',
            path: '',
            isSeparator: true,
            isActive: false
        }
    ]

	

	return <ExamPage candidate={candidate} exam={exam} />;
};

export default CandidateExamPage;
