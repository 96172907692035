import { Stack, Text, Textarea } from '@chakra-ui/react';
import React from 'react';
import { colors, dimentions } from '../../libs/config';
import { Controller } from 'react-hook-form';

const TextAreaField = ({ label, h, form, fieldName, registerOptions, ...props }) => {

	const { control } = form;
	const register = form.register(fieldName, registerOptions);

	return (
		<Stack w='full' spacing={0.5}>
			<Text fontSize='12px' fontWeight='600'>
				{label}
			</Text>
			<Controller
				name={fieldName}
				control={control}
				rules={registerOptions}
				render={({ field, fieldState, formState, ...props}) => {
					return (
						<>
							<Textarea
								h={h || '42px'}
								w='full'
								border='1px solid var(--bs-border-color)'
								bgColor='var(--bs-gray-100)'
								borderRadius={dimentions.borderRadius.sm}
								{...register}
								{...props}
							/>

							{ fieldState.invalid && (fieldState.submitCount > 0 || fieldState.isDirty)? (
							<>
								<Text variant="danger">
									{fieldState.error.message}
								</Text>
							</>): (<>
							</>)}
							

							</>)
				}}
			/>

		</Stack>
	);
};

export default TextAreaField;
