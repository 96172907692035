import {BaseLoader} from "../../common/base.loader"
import { assessmentApi } from '../../rtk/assessments.api'
import { positionApi } from "src/rtk/positions.api";
import { defer } from "react-router-dom";
import { departmentApi } from "src/rtk/departments.api";
import { userApi } from "src/rtk/users.api";


export class UsersLoader extends BaseLoader {
    getUsersPageData = async ({ request, params }) => {
        const getUserOverviewByRolesLoader = this._loader(
            userApi.endpoints.getUserOverviewByRoles,
            request
        );

        const getAllUsersByStatusLoader = this._loader(
            userApi.endpoints.getAllUsersByStatus,
            request
        );

        const getUsersLoader = this._loader(
            userApi.endpoints.getAllUsers,
            request,
            {
                page: 1,
                pageSize: 10,
                roles: ['Recruiter', 'Candidate', 'Admin']
            }
        );

        const getRolesLoader = this._loader(
            userApi.endpoints.getAllRoles,
            request
        );

        const [ usersOverviewByRoles, usersByStatus, users, roles ] = await Promise.all([getUserOverviewByRolesLoader, getAllUsersByStatusLoader, getUsersLoader, getRolesLoader])
        return { usersOverviewByRoles, usersByStatus, users,  roles }
    };
}