import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./base.api";
import { toast } from "react-toastify";

const tags = ["PinnedData"];
export const pinnedDataApi = createApi({
    reducerPath: "pinnedDataApi",
    baseQuery: axiosBaseQuery({
        baseUrl: "",
    }),
    tagTypes: tags,
    endpoints: (builder) => ({
        getPinnedDataByPath: builder.query({
			query: ({path} = {}) => ({
				url: `/PinnedData`,
				method: "GET",
				params: {
					path: path,
				},
			}),
            providesTags: ["PinnedData"],
		}),
		savePinnedData: builder.mutation({
			query: (body) => ({
				url: `/PinnedData`,
				method: "PUT",
				body
			}),
			invalidatesTags: ["PinnedData"],
		})
    }),
});

export const {
    useGetPinnedDataByPathQuery,
    useSavePinnedDataMutation
} = pinnedDataApi;
