import {React, useState, useEffect} from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Flex,
	Stack,
	Grid,
	Box,
	Checkbox,
	Text
} from '@chakra-ui/react';
import { dimentions } from 'src/app/libs/config';
import Select from 'react-select';
import { useAttachPositionToCandidateMutation } from "src/rtk/positions.api"

const customStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: 'var(--bs-gray-100, #f0f0f0)', // Fallback color if --bs-body-bg is not set
        borderColor: 'var(--bs-border-color, #ddd)', // Fallback color if --border-color is not set
        transition: 'none',
        ':hover': {
            borderColor: 'var(--border-color, #ddd)',
        },
		cursor: 'pointer',
		color: 'var(--bs-gray-900)',
		height: '42px',
		minHeight: '42px'
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: 'var(--bs-body-bg, #f0f0f0)',
        border: '1px solid var(--bs-border-color, #ddd)',
    }),
    option: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: isFocused ? 'var(--bs-gray-200, lightblue)' : 'var(--bs-body-bg, #f0f0f0)',
        color: 'var(--bs-gray-900)',
        ':hover': {
            backgroundColor: 'var(--bs-gray-200, lightblue)',
        },
		cursor: 'pointer'
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        backgroundColor: 'var(--bs-border-color, #ddd)',
    }),
    placeholder: (styles) => ({
        ...styles,
        color: 'var(--bs-gray-900)',
    }),
    singleValue: (styles) => ({
        ...styles,
        color: 'var(--bs-gray-900)',
    }),
	input: (styles) => ({
        ...styles,
        color: 'var(--bs-gray-900)',
    }),
	multiValue: (styles) => ({
        ...styles,
        backgroundColor: 'var(--bs-gray-200)', // Background of the tag
        color: 'var(--bs-gray-900)', // Text color of the tag
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        color: 'var(--bs-gray-900)', // Text color of the label
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        ':hover': {
            backgroundColor: 'var(--multi-value-remove-hover-bg, #c0c0c0)', // Background color on hover
            color: 'var(--multi-value-remove-hover-color, #000)', // Text color on hover
        }
    }),
};

const AddCandidateExam = ({ trigger, candidateId, candidatePositions, positions, ...props }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	
	const [positionId, setPositionId] = useState(null);

	const [attachPositionToCandidate, result] = useAttachPositionToCandidateMutation();

	useEffect(() => {
		const close = () => {
			setPositionId(null);
			onClose();
		};

		if (result?.isSuccess && !result?.isLoading) {
			close();
		}

	}, [onClose, result?.isLoading, result?.isSuccess]);



	const attachPosition = () => {
		attachPositionToCandidate({
			positionId: positionId.id,
			userId: candidateId
		});
	}

	const Row = ({ children }) => (
		<Grid gridTemplateColumns='1fr 1fr' gap={6}>
			{children}
		</Grid>
	);

	return (
		<>
			<Flex onClick={onOpen}>
				{trigger}
			</Flex>

			<Modal isOpen={isOpen} onClose={onClose} size='2xl'>
				<ModalOverlay />
				<ModalContent p={4} borderRadius={dimentions.borderRadius.lg}>
					<ModalHeader>Add Position</ModalHeader>
					<ModalCloseButton />
					<ModalBody borderRadius={dimentions.borderRadius.lg}>
						<Flex>
							<Stack w='100%' spacing={6}>
								<Row>
									<Flex flex={1} alignItems='flex-end'>
										<Stack w='full' spacing={0.5}>
											<Text fontSize='12px' fontWeight='600'>
												Position
											</Text>

										<Select 
										options={positions?.data} 
										onChange={setPositionId}
										getOptionLabel={option => option.name}
										getOptionValue={option => option.id}
										filterOption={({label}) => !candidatePositions?.data.some((candidatePosition) => candidatePosition.position === label)}
										value={positionId}
										styles={{
											control: (provided) => ({
											  ...provided,
											  width: '300px',
											})
										  }, customStyles} ></Select>
										</Stack>
									</Flex>
								</Row>

								<Box pt="400px">
									<Button
										size='md'
										borderRadius={dimentions.borderRadius.sm}
										onClick={attachPosition}
										colorScheme='blue'>
										Attach
									</Button>
								</Box>
							</Stack>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default AddCandidateExam;
