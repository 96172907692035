/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG, toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData } from "react-router-dom"
import DataTable from 'src/app/components/DataTable'

import { skipToken } from "@reduxjs/toolkit/query"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button, Tag } from '@chakra-ui/react';
import { Toolbar } from 'src/_metronic/layout/components/toolbar/Toolbar'
import { ToolbarReports } from 'src/_metronic/layout/components/toolbar/toolbars'
import { Page } from 'src/app/components/Page'
import { PositionsChart } from 'src/app/components/charts/PositionsChart'
import { colors } from 'src/app/libs/config'
import Row from 'src/app/components/temp/Row'
import { Can } from 'src/app/routing/AppRoutes'
import DepartmentTag from 'src/app/components/Tags/DepartmentTag'
import StatusTag from 'src/app/components/Tags/StatusTag'
import { useGetPositionsQuery } from 'src/rtk/positions.api'
import RoundedProgressCard from 'src/app/components/charts/RoundedProgressCard'
import moment from "moment";
import { useFindJobCandidatesMutation, useGetJobsQuery } from 'src/rtk/drushim.api'
import { toast } from "react-toastify";
import { format } from 'date-fns';
// import UploadResume from './UploadResume'
// import ShowCandidateResume from './ShowCandidateResume'
// import ManagePosition from './components/ManagePosition'


const columnHelper = createColumnHelper()

const breadCrumbs: Array<PageLink> = [
    // {
    //     title: 'Management',
    //     path: '#',
    //     isSeparator: false,
    //     isActive: false
    // },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: true,
    },
]


export const ManageJobsPage = ({ }) => {
    const { jobs } = useLoaderData();

    const [trigger, result] = useFindJobCandidatesMutation();

    const findCandidates = (id, customPrompt, additionalPrompt) => {
        trigger({ jobId: id, overridePrompt: customPrompt, additionalPrompt: additionalPrompt })
    }

    useEffect(() => {
        if (result?.isSuccess && !result?.isLoading) {
            toast.success("Start searching for candidate", {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
                pauseOnHover: false,
                pauseOnFocusLoss: false
            })
        }
    }, [result?.isLoading]);


    const colmns = [
        // columnHelper.accessor('companyLogo', {
        //     header: '',
        //     cell: (info) => (
        //         <img src={info.row.original.companyLogo} />
        //     ),
        // }),
        columnHelper.accessor("companyName", {
            header: 'Company',
            // cell: (info) => {
            //     return (
            //         <Flex alignItems='center'>
            //             <Avatar src={info.getValue().logo} size='md' />
            //             <Text fontSize='md' >{info.getValue().name}</Text>
            //         </Flex>
            //     )
            //     // return info.getValue().name
            // }
            cell: (info) => {
                return (
                    <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px me-5'>
                            <img
                                src={info.row.original.companyLogo}
                                className=''
                                alt=''
                            />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                            {/* <a href='#' className='text-dark fw-semibold text-hover-primary mb-1 fs-6'>
                                {info.getValue()}
                            </a> */}
                            <span className='fw-medium d-block fs-7'>
                                {info.getValue()}
                            </span>
                        </div>
                    </div>
                )
            }
        }),
        columnHelper.accessor("title", {
            header: 'Title'
        }),
        columnHelper.accessor("score", {
            header: 'Matching Score',
            cell: (info) => {
                try {
                    return (<span className="badge badge-light-primary">{(info.getValue()).toFixed(1)}</span>)
                }
                catch (e) {
                    return 'N/A'
                }
            }
        }),
        columnHelper.accessor("progressDate", {
            header: 'Processing Date',
            cell: (info) => {
                if (info.getValue() === null) {
                    return 'N/A'
                }
                return format(new Date(info.getValue()), 'dd/MM/yyyy')
            }
        }),
        columnHelper.display({
            header: 'Candidates',
            enableSorting: false,
            cell: (info) => {
                return (
                    <Link key={info.row.original.id} to={info.row.original.id}>
                        <Button size='sm' colorScheme='blue' color='white'>
                            Show
                        </Button>
                    </Link>
                )
            }
        }),
        columnHelper.accessor('id', {
            header: 'Action',
            enableSorting: false,
            cell: (info) => {
                let promptText = ''
                let additionalPrompt = ''
                return (
                    // <Button size='sm'  onClick={() => findCandidates(info.getValue())}>
                    //     Find Candidates
                    // </Button>
                    <>
                    <div className="btn-group" role="group">
                    <button
                        type="button"
                        className="btn btn-light-primary btn-sm"
                        onClick={() => findCandidates(info.getValue())}
                    >
                        Find Candidates
                    </button>
                    <button
                        type="button"
                        className="btn btn-light-info btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target={`#kt_modal_${info.row.original.id}`}
                    >
                        +
                    </button>
                </div>
                <div className="modal fade" tabIndex={-1} id={`kt_modal_${info.row.original.id}`}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Inject or Override Prompt</h5>
                                    <div
                                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <KTSVG
                                            path="media/icons/duotune/arrows/arr061.svg"
                                            className="svg-icon svg-icon-2x"
                                        />
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label htmlFor={`promptTextArea_${info.row.original.id}`} className="form-label">
                                                Enter your prompt
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id={`promptTextArea_${info.row.original.id}`}
                                                rows="5"
                                                placeholder="Type your prompt here..."
                                                defaultValue={promptText}
                                                onChange={(e) => promptText = e.target.value}
                                            ></textarea>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor={`additionalPromptTextArea_${info.row.original.id}`} className="form-label">
                                                Enter your additional prompt
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id={`additionalPromptTextArea_${info.row.original.id}`}
                                                rows="5"
                                                placeholder="Type your prompt here..."
                                                defaultValue={additionalPrompt}
                                                onChange={(e) => additionalPrompt = e.target.value}
                                            ></textarea>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        data-bs-dismiss="modal"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-dismiss="modal"
                                        onClick={() => findCandidates(info.row.original.id, promptText, additionalPrompt)}
                                    >
                                        Find
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )
            }
        }),
        columnHelper.accessor("link", {
            header: 'Link',
            cell: (info) => {
                return (
                    <a href={info.row.original.link ? info.row.original.link : `https://drushim.co.il/job/${info.row.original.externalId}/${info.row.original.externalSubId}/`} className="btn btn-info fs-8" target='_blank'><i className="bi bi-app-indicator fs-3 me-2"></i> Link to Job</a>
                )
            }
        })
        // columnHelper.accessor('id', {
        //     header: 'Resume',
        //     enableSorting: false,
        //     cell: (info) => {
        //         return (
        //             // <Link key={info.getValue()} to={info.getValue()}>
        //             //     <Button size='sm' color='#A1A5B7'>
        //             //         Show
        //             //     </Button>
        //             // </Link>
        //             <ShowCandidateResume candidate={info.row.original} />
        //         )
        //     }
        // }),
    ];


    return (
        <>
            <Page breadcrumbs={breadCrumbs} title={'Jobs'} columns={colmns} dataSourceQueryFn={useGetJobsQuery}>
                <div key='toolbar'>
                    {/* <UploadResume /> */}
                </div>
            </Page>
        </>
    )
}


export default ManageJobsPage