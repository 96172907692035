import { organizationApi } from "src/rtk/organizations.api";
import {BaseLoader} from "../../common/base.loader"
import { defer } from "react-router-dom";


export class OrganizationsLoader extends BaseLoader {
    getOrganizationsData = async ({ request, params }) => {
        
        const getOrganizationDataLoader = this._loader(
            organizationApi.endpoints.getOrganizationData,
            request
        );

        const getOrganizationsLoader = this._loader(
            organizationApi.endpoints.getAllOrganizations,
            request, {
                page: 1,
                pageSize: 10
            }
        );

        const [ overview, organizations ] = await Promise.all([getOrganizationDataLoader, getOrganizationsLoader])
        return { overview, organizations }
    };

}