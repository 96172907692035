import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./base.api";
import { toast } from "react-toastify";

const tags = ["Companies", "Company"];
export const companiesApi = createApi({
	reducerPath: "companiesApi",
	baseQuery: axiosBaseQuery({
        baseUrl: "",
    }),
	tagTypes: tags,
	endpoints: (builder) => ({
		getCompanies: builder.query({
			query: ({ filter, page = 1, pageSize = 10, sort, order }) => ({
				url: `/Company`,
				method: "GET",
				params: {
					page: page,
					pageSize: pageSize,
					...(filter && { filter }),
					...(sort && { sort: sort }),
					...(order && { order: order }),
				}
			}),
			providesTags: ["Companies"],
		}),
		createCompany: builder.mutation({
			query: (company) => ({
				url: "/Company",
				method: "POST",
				body: company,
				validateStatus: (response, body) => {
					if (response.ok) {
							toast.success("Company created successfully", {
							position: toast.POSITION.TOP_RIGHT,
							theme: 'colored',
							pauseOnHover: false,
							pauseOnFocusLoss: false
						})
					}
					return response.ok;
				},
			}),
			invalidatesTags: ["Companies"],
		}),
		updateCompany: builder.mutation({
			query: (company) => ({
				url: `/Company`,
				method: "PUT",
				body: company,
				params: {
					id: company.id
				},
				validateStatus: (response, body) => {
					if (response.ok) {
							toast.success("Company updated successfully", {
							position: toast.POSITION.TOP_RIGHT,
							theme: 'colored',
							pauseOnHover: false,
							pauseOnFocusLoss: false
						})
					}
					return response.ok;
				},
			}),
			invalidatesTags: ["Companies"],
		}),
		// getCandidateJobs: builder.query({
		// 	query: ({ candidateId, page = 1, pageSize = 10, filter }) => ({
		// 		url: `/Candidate/Matches`,
		// 		method: "GET",
		// 		params: {
		// 			page: page,
		// 			pageSize: pageSize,
		// 			...(filter && { filter }),
		// 			candidateId: candidateId
		// 		}
		// 	}),
		// 	providesTags: ["Candidates"],
		// }),
		// getCandidateJobReport: builder.query({
		// 	query: ({ candidateId, jobId }) => ({
		// 		url: `/Candidate/report`,
		// 		method: "GET",
		// 		params: {
		// 			candidateId: candidateId,
		// 			jobId: jobId
		// 		}
		// 	}),
		// 	providesTags: ["Candidates"],
		// }),
		// getCandidateResume: builder.query({
		// 	query: ({ candidateId }) => ({
		// 		url: `/Candidate/Resume`,
		// 		method: "GET",
		// 		params: {
		// 			candidateId: candidateId
		// 		}
		// 	}),
		// 	providesTags: ["Candidates"],
		// }),
        // createCandidate: builder.mutation({
        //     query: (resumeFile) => {
		// 		var bodyFormData = new FormData();
    	// 		bodyFormData.append('file', resumeFile);
		// 		console.log({ bodyFormData, resumeFile });
        //         return {
        //             url: "/Candidate",
        //             method: "POST",
		// 			headers: {
		// 				'Content-Type': 'multipart/form-data;'
		// 			},
        //             body: bodyFormData,
        //             formData: true
        //         };
        //     },
        //     invalidatesTags: ["Candidates"],
        // }),
		// findCandidateJobs: builder.mutation({
        //     query: (candidateId) => {
        //         return {
        //             url: "/Candidate/find",
        //             method: "GET",
        //             params: {
		// 				candidateId: candidateId
		// 			}
        //         };
        //     },
        //     invalidatesTags: ["Candidates"],
        // }),
		// getJobs: builder.query({
		// 	query: ({ filter, page = 1, pageSize = 10, ids, sort, order, purpose }) => ({
		// 		url: `/Job`,
		// 		method: "GET",
		// 		params: {
		// 			page: page,
		// 			ids: ids,
		// 			pageSize: pageSize,
		// 			...(filter && { filter }),
		// 			...(sort && { sort: sort }),
		// 			...(order && { order: order }),
		// 			...(purpose && { purpose: purpose }),
		// 		}
		// 	}),
		// 	providesTags: ["Jobs"],
		// }),
		// getJobCandidates: builder.query({
		// 	query: ({ jobId, page = 1, pageSize = 10, filter }) => ({
		// 		url: `/Job/Candidates`,
		// 		method: "GET",
		// 		params: {
		// 			page: page,
		// 			pageSize: pageSize,
		// 			...(filter && { filter }),
		// 			jobId: jobId
		// 		}
		// 	}),
		// 	providesTags: ["Jobs"],
		// }),
		// deleteJobCandidates: builder.mutation({
		// 	query: ({ jobId }) => ({
		// 		url: `/Job/Candidates`,
		// 		method: "DELETE",
		// 		params: {
		// 			jobId: jobId
		// 		}
		// 	}),
		// 	providesTags: ["Jobs"],
		// }),
		// getJobCandidateReport: builder.query({
		// 	query: ({ candidateId, jobId }) => ({
		// 		url: `/Job/report`,
		// 		method: "GET",
		// 		params: {
		// 			candidateId: candidateId,
		// 			jobId: jobId
		// 		}
		// 	}),
		// 	providesTags: ["Jobs"],
		// }),
		// findJobCandidates: builder.mutation({
        //     query: (jobId) => {
        //         return {
        //             url: "/Job/find",
        //             method: "GET",
        //             params: {
		// 				jobId: jobId
		// 			}
        //         };
        //     },
        //     invalidatesTags: ["Jobs"],
        // }),
	}),
});

export const {
	useGetCompaniesQuery,
	useCreateCompanyMutation,
	useUpdateCompanyMutation
} = companiesApi;
