import { useGetDepartmentsQuery } from "src/rtk/departments.api";
import { Tag } from "@chakra-ui/react";
import React from "react";

function DepartmentTag({ children }) {
	const {
		data: departmentsDict,
		isLoading: departmentsDictIsLoading,
		isError: departmentsDictIsError,
	} = useGetDepartmentsQuery();

	let departmentsDictionary = {};
	let departmentColorSchemes = {};
	const colorSchemes = ["blue", "green", "yellow"];

	if (departmentsDict) {
		if (Object.keys(departmentsDictionary).length == 0) {
			departmentsDictionary = Object.fromEntries(
				departmentsDict.data.map((x) => [x.id, x.name])
			);
			departmentColorSchemes = Object.fromEntries(
				departmentsDict.data.map((x, idx) => [x.id, colorSchemes[idx]])
			);
		}
	}
	if (!departmentsDictionary[children]) {
		return <Tag colorScheme='gray'>Not Specified</Tag>;
	}
	if (Object.keys(departmentsDictionary).length === 0) {
		return null;
	}
	return (
		<Tag colorScheme={departmentColorSchemes[children]}>
			{departmentsDictionary[children]}
		</Tag>
	);
}

export default DepartmentTag;
