/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData, useLocation, useNavigate, useNavigation, useRouteLoaderData } from "react-router-dom"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button } from '@chakra-ui/react';
import { colors } from 'src/app/libs/config'
import { MatchPage } from './MatchPage'
import { ProfilePage } from '../../profile/ProfilePage'
import { useAuth } from '../../auth'

const columnHelper = createColumnHelper()

export const MatchPageWrapper = ({ }) => {

    const { currentUser } = useAuth()

    const { match } = useLoaderData()

    const breadCrumbs: Array<PageLink> = [
        {
            title: 'Account',
            path: '#',
            isSeparator: false,
            isActive: false
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false
        },
    ]

    return (
        <>
            <ProfilePage user={currentUser}>
                <MatchPage match={match} />
            </ProfilePage>
        </>
    )
}
