import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Stack,
  Text,
  Textarea,
  IconButton,
  Grid,
  GridItem
} from "@chakra-ui/react";
import ContactField from "./form-inputs/ContactField";
import ImageField from "./form-inputs/ImageField";



const DynamicForm = ({ config, onSubmit, defaultValues }) => {
    const form = useForm({
        mode: 'all',
        defaultValues: defaultValues || {}
    });
  
    const { handleSubmit, control, formState: { errors } } = form;
  
    const renderField = (name, fieldConfig, props) => {
        const { type, title, readOnly, validation } = fieldConfig;
        
        switch (type) {
          case "string":
            return (
              <Controller
                name={name}
                control={control}
                rules={validation}
                render={({ field }) => (
                  <Box>
                    <Text>{title}</Text>
                    <Input
                      {...field}
                      isReadOnly={readOnly}
                      isInvalid={errors[name]}
                      value={field.value || ""}
                      onChange={(e) => field.onChange(e.target.value)}
                      ref={field.ref}
                    />
                    {errors[name] && <Text color="red.500">{errors[name]?.message}</Text>}
                  </Box>
                )}
              />
            );
          case "file":
            return (
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <Box>
                    <Text>{title}</Text>
                    <Input
                      type="file"
                      {...field}
                      onChange={(e) => field.onChange(e.target.files)}
                      ref={field.ref}
                    />
                  </Box>
                )}
              />
            );
          case "image":
            return (
              <ImageField
                form={form}
                fieldName={name}
                label={title}
                registerOptions={validation}
                width={props.width}
              />
            );
          case "textarea":
            return (
              <Controller
                name={name}
                control={control}
                rules={validation}
                render={({ field }) => (
                  <Box>
                    <Text>{title}</Text>
                    <Textarea
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => field.onChange(e.target.value)}
                      ref={field.ref}
                    />
                    {errors[name] && <Text color="red.500">{errors[name]?.message}</Text>}
                  </Box>
                )}
              />
            );
          case "url":
            return (
              <Controller
                name={name}
                control={control}
                rules={validation}
                render={({ field }) => (
                  <Box>
                    <Text>{title}</Text>
                    <Input
                      type="url"
                      {...field}
                      value={field.value || ""}
                      isInvalid={errors[name]}
                      onChange={(e) => field.onChange(e.target.value)}
                      ref={field.ref}
                    />
                    {errors[name] && <Text color="red.500">{errors[name]?.message}</Text>}
                  </Box>
                )}
              />
            );
          case "number":
            return (
              <Controller
                name={name}
                control={control}
                rules={validation}
                render={({ field }) => (
                  <Box>
                    <Text>{title}</Text>
                    <Input
                      type="number"
                      {...field}
                      value={field.value || ""}
                      isInvalid={errors[name]}
                      onChange={(e) => field.onChange(parseFloat(e.target.value))}
                      ref={field.ref}
                    />
                    {errors[name] && <Text color="red.500">{errors[name]?.message}</Text>}
                  </Box>
                )}
              />
            );
          case "boolean":
            return (
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <Box>
                    <Checkbox
                      {...field}
                      isChecked={field.value || false}
                      onChange={(e) => field.onChange(e.target.checked)}
                      ref={field.ref}
                    >
                      {title}
                    </Checkbox>
                  </Box>
                )}
              />
            );
          case "contactField":
            return (
              <ContactField
                form={form}
                fieldName={name}
                label={title}
                registerOptions={validation}
              />
            );
          default:
            return null;
        }
      };
    
      return (
        <Box as="form" onSubmit={handleSubmit(onSubmit)} p={4}>
          <Grid templateColumns={`repeat(${config.layout.columns}, 1fr)`} gap={4}>
            {config.layout.fields.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                {row.map((field) => {
                  const fieldName = typeof field === 'string' ? field : field.name;
                  const colSpan = typeof field === 'string' ? 1 : field.colSpan || 1;
                  const rowSpan = typeof field === 'string' ? 1 : field.rowSpan || 1;
    
                  return (
                    <GridItem key={fieldName} colSpan={colSpan} rowSpan={rowSpan}>
                      {renderField(fieldName, config.fields[fieldName], {width: field?.width})}
                    </GridItem>
                  );
                })}
              </React.Fragment>
            ))}
          </Grid>
          <Button type="submit" className="mt-5" colorScheme="blue">
            Save
          </Button>
        </Box>
      );
    };
  
  export default DynamicForm;