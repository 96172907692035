import { colors, dimentions } from "src/app/libs/config";
import { useSubmitChallengeMutation } from "src/rtk/challenges.api";
import {
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const ChallengeSubmit = ({ examId, challengeId, returnTo }) => {
    const navigate = useNavigate()
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [trigger, result] = useSubmitChallengeMutation();

	useEffect(() => {
		if (result.isSuccess) {
			onOpen();
		}
	}, [result, onOpen]);

	useEffect(() => {
	}, [result]);

	const submitChallenge = (e) => {
		trigger({
			examId,
			challengeId,
		});
	};

	return (
		<>
			<Button px={8} isLoading={result?.isLoading} spinner={<BeatLoader size={8} color='gray' />} loadingText={'Submitting'} spinnerPlacement={'end'} onClick={submitChallenge}>
				{result.isSucccess? 'Submitted' : 'Submit'}
			</Button>

			<Modal isOpen={isOpen} onClose={onClose} size='2xl'>
				<ModalOverlay />
				<ModalContent p={4} borderRadius={dimentions.borderRadius.lg}>
					<ModalHeader></ModalHeader>
					<ModalCloseButton />
					<ModalBody borderRadius={dimentions.borderRadius.lg}>
						<Flex flex={1} flexDir='column' gap={10}>
							<Text color={colors.text} fontSize='24px' fontWeight='semibold'>
								Exam Submitted
							</Text>
							<Text color={colors.text} fontSize='20px'>
								You have successfully submitted your exam! Thank you for
								taking the time to complete the exam
							</Text>
						</Flex>
						<Flex w='full' justify='center' mt='128px'>
							<Button
								colorScheme='blue'
								onClick={() => {
									onClose();
									navigate(returnTo ?? `/account/exams/${examId}`, {
                                        replace: true,
                                        state: {
                                            [challengeId]: 'submitted'
                                        }
                                    })
								}}
							>
								OK
							</Button>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ChallengeSubmit;
