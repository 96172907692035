import {BaseLoader} from "../../common/base.loader"
import { defer } from "react-router-dom";
import { drushimsApi } from "src/rtk/drushim.api";


export class DrushimLoader extends BaseLoader {
    listCandidates = async ({ request }) => {
        const params = new URL(request.url).searchParams
        let page = 1
        if (params.get('page') !== null) {
            page = Number(params.get('page'))
        }
        let pageSize = 10
        if (params.get('pageSize') !== null) {
            pageSize = Number(params.get('pageSize'))
        }

        let filter = null
        if (params.get('filter') !== null) {
            filter = params.get('filter')
        }

        const candidatesLoader = this._loader(
            drushimsApi.endpoints.getCandidates,
            request,
            {
                page: page,
                pageSize: pageSize,
                ...(filter !== null && { filter: filter}),
            }
        );

        const [candidates] = await Promise.all([candidatesLoader])
        return { candidates };
    };


    listJobs = async ({ request }) => {
        const params = new URL(request.url).searchParams
        const filter = params.get('filter')

        let page = 1
        if (params.get('page') !== null) {
            page = Number(params.get('page'))
        }
        let pageSize = 10
        if (params.get('pageSize') !== null) {
            pageSize = Number(params.get('pageSize'))
        }
        let order = params.get('order')
        let sort = params.get('sort')
        const jobsLoader = this._loader(
            drushimsApi.endpoints.getJobs,
            request,
            {
                page: isNaN(page) ? 1 : page,
                pageSize: isNaN(pageSize)? 10 : pageSize,
                ...(filter !== null && { filter: filter}),
                ...(sort !== null && { sort: sort }),
                ...(order !== null && { order: order })
            }
        );

        const [jobs] = await Promise.all([jobsLoader])
        return { jobs };
    };



    getCandidate = async ({ request, params }) => {

        const queryParams = new URL(request.url).searchParams
        const filter = queryParams.get('filter')
        let page = 1
        if (queryParams.get('page') !== null) {
            page = Number(queryParams.get('page'))
        }
        let pageSize = 10
        if (queryParams.get('pageSize') !== null) {
            pageSize = Number(queryParams.get('pageSize'))
        }

        const getCandidateLoader = this._loader(
            drushimsApi.endpoints.getCandidates,
            request,
            {
                page: 1,
                pageSize: 1,
                ids: [params.candidateId]
            }
        );

        const getCandidateJobsLoader = this._loader(
            drushimsApi.endpoints.getCandidateJobs,
            request,
            {
                candidateId: params?.candidateId,
                page: page,
                pageSize: pageSize,
                ...(filter !== null && { filter: filter})
            }
        );

        const [candidate, jobs] = await Promise.all([getCandidateLoader, getCandidateJobsLoader])
        
        return { candidate: candidate.data[0], jobs: jobs.data };
    };

    getCandidateMatches = async ({ request, params }) => {
        const queryParams = new URL(request.url).searchParams
        const filter = queryParams.get('filter')
        let page = 1
        if (queryParams.get('page') !== null) {
            page = Number(queryParams.get('page'))
        }
        let pageSize = 10
        if (queryParams.get('pageSize') !== null) {
            pageSize = Number(queryParams.get('pageSize'))
        }

        const getCandidateJobsLoader = this._loader(
            drushimsApi.endpoints.getCandidateJobs,
            request,
            {
                candidateId: params?.candidateId,
                page: page,
                pageSize: pageSize,
                sort: 'OverallScore',
                filter: 'OverallScore > 70',
                order: 'desc',
                ...(filter !== null && { filter: filter})
            }
        );

        const [matches] = await Promise.all([getCandidateJobsLoader])

        return { matches: matches.data };
    };

    getCandidateMatch = async ({ request, params }) => {
        const queryParams = new URL(request.url).searchParams
        const filter = queryParams.get('filter')
        let page = 1
        if (queryParams.get('page') !== null) {
            page = Number(queryParams.get('page'))
        }
        let pageSize = 10
        if (queryParams.get('pageSize') !== null) {
            pageSize = Number(queryParams.get('pageSize'))
        }

        const getCandidateJobLoader = this._loader(
            drushimsApi.endpoints.getCandidateJobs,
            request,
            {
                candidateId: params?.candidateId,
                page: page,
                pageSize: pageSize,
                filter: 'Id = ' + params.matchId,
                ...(filter !== null && { filter: filter})
            }
        );

        const [matches] = await Promise.all([getCandidateJobLoader])

        return { match: matches.data[0] };
    };


    getJob = async ({ request, params }) => {

        const queryParams = new URL(request.url).searchParams
        let filter = queryParams.get('filter')

        if (filter === null ) {
            filter = "OverallScore > 70"
        }

        let page = 1
        if (queryParams.get('page') !== null) {
            page = Number(queryParams.get('page'))
        }
        let pageSize = 30
        if (queryParams.get('pageSize') !== null) {
            pageSize = Number(queryParams.get('pageSize'))
        }
        
        const getJobLoader = this._loader(
            drushimsApi.endpoints.getJobs,
            request,
            {
                page: 1,
                pageSize: 1,
                ids: [params.jobId]
            }
        );

        const getJobCandidatesLoader = this._loader(
            drushimsApi.endpoints.getJobCandidates,
            request,
            {
                jobId: params.jobId,
                page: page,
                pageSize: pageSize,
                ...(filter !== null && { filter: filter})
            }
        );

        const [job, candidates] = await Promise.all([getJobLoader, getJobCandidatesLoader])
        
        return { job: job.data[0], candidates: candidates.data };
    };

    getCandidateJobReport = async ({ request, params }) => {
        const getCandidateJobReportLoader = this._loader(
            drushimsApi.endpoints.getCandidateJobReport,
            request,
            {
                candidateId: params.candidateId,
                jobId: params.jobId
            }
        );

        const [report] = await Promise.all([getCandidateJobReportLoader])
        return { report: report[0] };
    }

    getJobCandidateReport = async ({ request, params }) => {
        const getJobCandidateReportLoader = this._loader(
            drushimsApi.endpoints.getJobCandidateReport,
            request,
            {
                candidateId: params.candidateId,
                jobId: params.jobId
            }
        );

        const [report] = await Promise.all([getJobCandidateReportLoader])
        return { report: report[0] };
    }

    // getCandidatePositions = async ({ request, params }) => {

    //     const getCandidatePositionsLoader = this._loader(
    //         positionApi.endpoints.getCandidatePositions,
    //         request,
    //         {
    //             candidateId: params.candidateId,
    //             page: 1,
    //             pageSize: 10
    //         }
    //     );

    //     const getPositionsLoader = this._loader(
    //         positionApi.endpoints.getPositions,
    //         request,
    //         {
    //             page: 1,
    //             pageSize: 1000
    //         }
    //     );

    //     const [candidatePositions, positions] = await Promise.all([getCandidatePositionsLoader, getPositionsLoader])
    //     return { candidatePositions, positions }
    // };


    // getCandidateExam = async ({ request, params }) => {

    //     const getCandidateExamLoader = this._loader(
    //         examsApi.endpoints.getClones,
    //         request,
    //         {
    //             id: params.examId,
    //             page: 1,
    //             pageSize: 1
    //         }
    //     );

    //     const [exam] = await Promise.all([getCandidateExamLoader])
    //     return { exam: exam.data[0] }
    // };

    // getMyExam = async ({ request, params }) => {

    //     const getCandidateExamLoader = this._loader(
    //         examsApi.endpoints.getMyExam,
    //         request,
    //         {
    //             id: params.examId,
    //             page: 1,
    //             pageSize: 1
    //         }
    //     );

    //     const [exam] = await Promise.all([getCandidateExamLoader])
    //     return { exam: exam.data[0] }
    // };

}