import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./base.api";
import { toast } from "react-toastify";

const tags = ["Organization"];
export const organizationApi = createApi({
    reducerPath: "organizationApi",
    baseQuery: axiosBaseQuery({
        baseUrl: "",
    }),
    tagTypes: tags,
    endpoints: (builder) => ({
        getOrganizationData: builder.query({
			query: () => ({
				url: `/Management/Tenant/Overview`,
				method: "GET",
				// params: {
				// 	byNewCustomers: true,
				// },
			}),
			// tags: []
		}),
		getAllOrganizations: builder.query({
			query: ({ search, page = 1, pageSize = 10 } = {}) => ({
				url: `/Management/Tenant`,
				method: "GET",
				params: {
					search: search,
					pageSize: pageSize,
					page: page,
				},
			}),
			providesTags: ["Organization"],
		}),

		updateOrganization: builder.mutation({
			query: (body) => ({
				url: "/Management/Tenant",
				method: "PATCH",
				body,
				params: {
					id: body.id,
				},
			}),
			invalidatesTags: ["Organization"],
		}),
		addOrganization: builder.mutation({
			query: (body) => ({
				url: "/Management/Tenant",
				method: "POST",
				body,
				validateStatus: (response, body) => {
					if (response.ok) {
						(async () => {
							const { toast } = await import('react-toastify');
							toast.success("Organization created successfully", {
							position: toast.POSITION.TOP_RIGHT,
							theme: 'colored',
							pauseOnHover: false,
							pauseOnFocusLoss: false
						})})()
					}
					return response.ok;
				},
			}),
			invalidatesTags: ["Organization"],
		}),
		deleteOrganization: builder.mutation({
			query: ({ id } = {}) => ({
				url: "/management/tenant",
				method: "DELETE",
				params: {
					id,
				},
			}),
			invalidatesTags: ["Organization"],
		}),
    }),
});

export const {
    useGetOrganizationDataQuery,
	useGetAllOrganizationsQuery,
	useUpdateOrganizationMutation,
	useAddOrganizationMutation,
	useDeleteOrganizationMutation,
} = organizationApi;
