/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData, useRouteLoaderData } from "react-router-dom"
import DataTable from 'src/app/components/DataTable'

import { skipToken } from "@reduxjs/toolkit/query"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button, Tag } from '@chakra-ui/react';
import { Toolbar } from 'src/_metronic/layout/components/toolbar/Toolbar'
import { ToolbarReports } from 'src/_metronic/layout/components/toolbar/toolbars'
import { Page } from 'src/app/components/Page'
import { PositionsChart } from 'src/app/components/charts/PositionsChart'
import { colors } from 'src/app/libs/config'
import Row from 'src/app/components/temp/Row'
import { Can } from 'src/app/routing/AppRoutes'
import DepartmentTag from 'src/app/components/Tags/DepartmentTag'
import StatusTag from 'src/app/components/Tags/StatusTag'
import { useGetPositionsQuery } from 'src/rtk/positions.api'
import RoundedProgressCard from 'src/app/components/charts/RoundedProgressCard'
import moment from "moment";
import { useGetCandidateJobsQuery, useGetCandidatesQuery } from 'src/rtk/drushim.api'
import UploadResume from './UploadResume'
import ShowCandidateResume from './ShowCandidateResume'
// import ManagePosition from './components/ManagePosition'


const columnHelper = createColumnHelper()

const breadCrumbs: Array<PageLink> = [

]


export const CandidateJobsPage = ({ }) => {
    const { candidate, jobs } = useRouteLoaderData('candidate');

    const colmns = [
        columnHelper.accessor('companyLogo', {
            header: '',
            cell: (info) => (
                <img src={info.row.original.companyLogo} />
            ),
        }),
        columnHelper.accessor('companyName', {
            header: 'Company Name',
        }),
        columnHelper.accessor("title", {
            header: 'Title'
        }),
        columnHelper.accessor("qualificationScore", {
            header: 'Qualification Score',
            cell: (info) => {
                return (
                        <span className="badge badge-light-primary">{info.getValue()}</span>
                )
            }
        }),
        columnHelper.accessor("attractiveScore", {
            header: 'Attractive Score',
            cell: (info) => {
                return (
                        <span className="badge badge-light-primary">{info.getValue()}</span>
                )
            }
        }),
        columnHelper.accessor("score", {
            header: 'Score',
            cell: (info) => {
                return (
                    <div className='progress w-100px w-xl-150px w-xxl-300px h-25px bg-light-primary'>
                    <div
                      className='progress-bar rounded bg-primary fs-7 fw-bold'
                      role='progressbar'
                      style={{width: (info.getValue()/100 * 100).toFixed(0) + '%'}}
                      aria-valuenow={info.getValue()}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      {(info.getValue()/100 * 100).toFixed(0)}%
                    </div>
                  </div>
                    // <RoundedProgressCard value={info.getValue()} />
                )
            }
        }),
        columnHelper.accessor('jobId', {
            header: 'Report',
            enableSorting: false,
            cell: (info) => {
                return (
                    <Link key={info.getValue()} to={`report/${info.getValue()}`}>
                        <Button size='sm' colorScheme='blue' color='white'>
                            Show
                        </Button>
                    </Link>
                )
            }
        }),
        columnHelper.accessor("link", {
            header: 'Link',
            cell: (info) => {
                return (
                    <a href={info.getValue()} target='_blank'>
                        {/* <Button size='sm' colorScheme='orange' color='white'>
                            Open
                        </Button> */}
                        <img src={toAbsoluteUrl('/media/logos/drushim.jpg')} style={{borderRadius: '5px', width: '80px'}}/>
                    </a>
                )
            }
        })
        // columnHelper.accessor('recruiterName', {
        //     header: 'Recruiter Name'
        // }),
        // columnHelper.accessor("departmentId", {
        //     header: 'Department',
        //     cell: (info) => {
        //         return (
        //             <DepartmentTag>{info.getValue()}</DepartmentTag>
        //         )
        //     },
        // }),
        // columnHelper.accessor('candidates', {
        //     header: 'Associated Candidates',
        //     cell: (info) => `${info.getValue().length}`,
        //     enableSorting: false
        // }),
        // columnHelper.accessor('engagement', {
        //     header: 'Engagement',
        //     cell: (info) => `${info.getValue()??0}%`,
        //     enableSorting: false
        // }),
        // columnHelper.accessor('status', {
        //     header: 'Job Status',
        //     cell: (info) => (
        //         <Tag colorScheme='blue'>
        //             {info.getValue()}
        //         </Tag>
        //     )
        // }),
        // columnHelper.accessor('id', {
        //     header: 'Details',
        //     enableSorting: false,
        //     cell: (info) => {
        //         return (
        //             <Link key={info.getValue()} to={info.getValue()}>
        //                 <Button size='sm' color='#A1A5B7'>
        //                     Go to Position
        //                 </Button>
        //             </Link>
        //         )
        //     }
        // })
    ];

    // console.log(candidate, jobs)


    return (
        <>
            <Page breadcrumbs={breadCrumbs} title={`${candidate.name}'s Jobs`} columns={colmns} dataSourceQueryFn={useGetCandidateJobsQuery} queryParams={{candidateId: candidate.id, page: 1, pageSize: 10}}>
                <div key={'middle'}>
                    <span>
                     Title: {candidate.title} <ShowCandidateResume candidate={candidate} buttonName={'Show Resume'} classNames={"btn btn-light-primary"}/>
                    </span>
                    
                </div>
                <div key={'card'}>
                    Test
                </div>
            </Page>
        </>
    )
}


export default CandidateJobsPage