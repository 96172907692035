import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./base.api";
import { toast } from "react-toastify";

const tags = [];
export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: axiosBaseQuery({
        baseUrl: "",
    }),
    tagTypes: tags,
    endpoints: (builder) => ({
        login: builder.mutation({
			query: (body) => {
				return {
					url: "/auth/sign-in",
					method: "POST",
					body: { ...body, rememberMe: true },
				};
			},
		}),
		socialLogin: builder.mutation({
			query: (body) => {
				return {
					url: "/auth/social-signin",
					method: "POST",
					body: body,
				};
			},
		}),
		forgetPassword: builder.mutation({
			query: (body) => ({
				url: "/Auth/forgot-password",
				method: "POST",
				body: body,
			}),
		}),
    }),
});

export const {
    useLoginMutation,
	useSocialLoginMutation,
	useForgetPasswordMutation,
} = authApi;
