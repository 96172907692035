/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData } from "react-router-dom"
import DataTable from 'src/app/components/DataTable'

import { skipToken } from "@reduxjs/toolkit/query"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button, Tag } from '@chakra-ui/react';
import { Toolbar } from 'src/_metronic/layout/components/toolbar/Toolbar'
import { ToolbarReports } from 'src/_metronic/layout/components/toolbar/toolbars'
import { Page } from 'src/app/components/Page'
import { PositionsChart } from 'src/app/components/charts/PositionsChart'
import { colors } from 'src/app/libs/config'
import Row from 'src/app/components/temp/Row'
import { Can } from 'src/app/routing/AppRoutes'
import DepartmentTag from 'src/app/components/Tags/DepartmentTag'
import StatusTag from 'src/app/components/Tags/StatusTag'
import { useGetPositionsQuery } from 'src/rtk/positions.api'
import RoundedProgressCard from 'src/app/components/charts/RoundedProgressCard'
import moment from "moment";
import ManagePosition from './components/ManagePosition'


const columnHelper = createColumnHelper()

const breadCrumbs: Array<PageLink> = [
    {
        title: 'Management',
        path: '#',
        isSeparator: false,
        isActive: false
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: true,
    },
]


export const PositionsPage = ({ }) => {
    const { positions, positionsOverview, departments } = useLoaderData();

    const colmns = [
        columnHelper.accessor('title', {
            header: 'Name',
        }),
        columnHelper.accessor("created", {
            header: 'Created',
            cell: (info) => moment(info.getValue()).format("MMM DD, YYYY")
        }),
        columnHelper.accessor('recruiterName', {
            header: 'Recruiter Name'
        }),
        columnHelper.accessor("departmentId", {
            header: 'Department',
            cell: (info) => {
                return (
                    <DepartmentTag>{info.getValue()}</DepartmentTag>
                )
            },
        }),
        columnHelper.accessor('candidates', {
            header: 'Associated Candidates',
            cell: (info) => `${info.getValue().length}`,
            enableSorting: false
        }),
        columnHelper.accessor('engagement', {
            header: 'Engagement',
            cell: (info) => `${info.getValue()??0}%`,
            enableSorting: false
        }),
        columnHelper.accessor('status', {
            header: 'Job Status',
            cell: (info) => (
                <Tag colorScheme='blue'>
                    {info.getValue()}
                </Tag>
            )
        }),
        columnHelper.accessor('id', {
            header: 'Details',
            enableSorting: false,
            cell: (info) => {
                return (
                    <Link key={info.getValue()} to={info.getValue()}>
                        <Button size='sm' color='#A1A5B7'>
                            Go to Position
                        </Button>
                    </Link>
                )
            }
        })
    ];


    return (
        <>
            <Page breadcrumbs={breadCrumbs} title={'Positions'} columns={colmns} dataSourceQueryFn={useGetPositionsQuery}>
                <div key='toolbar'>
                    <Can a={'Position'} I={'create'}>
                        <ManagePosition />
                    </Can>
                </div>
                <div key='middle'>
                    <Row>

                        <RoundedProgressCard
                            title='Positions In Progress'
                            color={colors.link}
                            progress={
                                (positionsOverview.inProgress / positionsOverview.total) * 100
                            }
                            count={positionsOverview.inProgress}
                        />

                        <RoundedProgressCard
                            title='Positions Close'
                            color={colors.success}
                            progress={
                                (positionsOverview.completed / positionsOverview.total) * 100
                            }
                            count={positionsOverview.completed}
                        />


                        <PositionsChart
                            title='Departments'
                            data={departments?.map((item, idx) => item.name)}
                            color={[colors.link, colors.success, colors.warning, "#FF9F9F"]}
                            amount={
                                departments
                                    ? departments?.map((item, idx) => item.value)
                                    : [0, 0, 0, 0]
                            }
                            values={
                                departments
                                    ? departments?.map((item, idx) => item.value)
                                    : [0, 0, 0, 0]
                            }
                        />
                    </Row>
                </div>
            </Page>
        </>
    )
}


export default PositionsPage