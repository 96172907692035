import { Checkbox, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { colors } from 'src/app/libs/config';
import { Controller } from 'react-hook-form';

const CheckField = ({ hideLabel, label, form, fieldName, registerOptions, ...props }) => {

    const { control } = form;
    const register = form.register(fieldName, registerOptions);
    const { onChange, ...rest } = register;

    return (
        <>
            {!hideLabel  && <Text fontSize='12px' fontWeight='600'>
                {label}
            </Text>}
            <Controller
                name={fieldName}
                control={control}
                rules={registerOptions}
                render={({ field, fieldState, formState}) => {
                    return (
                        <>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                {...rest}
                                {...props}
                                onChange={(e) => onChange({
                                    target: {
                                        name: fieldName,
                                        value: e.target.value === "on" ? true : false
                                    }
                                })
                            }
                            />

                            {fieldState.invalid && (formState.submitCount > 0 || fieldState.isDirty) ? (
                                <>
                                    <Text textColor={'red'}>
                                        {fieldState.error.message}
                                    </Text>
                                </>) : (<>
                                </>)}


                        </>)
                }}
            />

        </>
    );
};

export default CheckField;
