import {useEffect} from 'react'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {ToolbarWrapper} from './components/toolbar'
import { Actions, Subjects, useUserAbilities } from '../../app/libs/ability'
import { useAuth } from '../../app/modules/auth'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { colors as clr } from '../../app/libs/config'
import { ModalProvider } from 'src/app/components/ModalContext'
import CustomModal from 'src/app/components/CustomModal'

interface IMasterLayoutProps {
  action?: Actions;
  subject?: Subjects;
}

const styles = {
};

const fonts = {
  body: "Poppins, sans-serif",
}


const colors = {
	primary: {
		400: "#696C80",
		500: "#3F4254",
		600: "#1E1E2D",
		700: "#1B1B28",
	},
	yellow: {
		500: 'var(--bs-yellow-500)',
	},
	blue: {
		500: 'var(--bs-primary)',
	},
	red: {
		500: 'var(--bs-danger)',
	},
	green: {
		500: 'var(--bs-success-active)',
	},
	gray: {
		500: 'var(--bs-gray-500)',
	},
};

const components = {
	Text: {
		variants: {
			link: {
				fontWeight: "600",
				color: "dodgerblue",
			},
      danger: { 
        color: "var(--bs-danger)", 
      },
		},
	},
  Modal: {
    baseStyle: {
      dialog: {
        bg: 'var(--bs-body-bg)',
      },
    }
  },
  Tag: {
    baseStyle:{ 
      label: {
        color: 'var(--bs-gray-900)'
      }
    }
  },
  Accordion: {
    baseStyle: {
      root: {
        border: '1px solid var(--bs-border-color)',
        borderRadius: '7px',
        bg: 'var(--bs-body-bg)'
      },
      container: {
        border: 'none'
      },
      button: {
        border: 'none',
        color: 'var(--bs-gray-900)',
        fontSize: '15px',
        fontWeight: 'bold'
      },
      panel: {
        paddingInlineStart: '1.5rem'
      }
    }
  }
};

export const theme = extendTheme({
	styles,
	colors,
	components,
  fonts,
});

const MasterLayout = (props?: IMasterLayoutProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const userAbilities = useUserAbilities();
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  if (props) {
    if (userAbilities.can(props.action!, props.subject!)) {
      navigate('/errors/403');
    }
  }

  return (
    <ChakraProvider theme={theme}>
    <PageDataProvider>
      <ModalProvider>
      <CustomModal />
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <HeaderWrapper />
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              <Sidebar />
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  <ToolbarWrapper />
                  <Content>
                    <Outlet />
                  </Content>
                </div>
                {/* <FooterWrapper /> */}
              </div>
            </div>
          </div>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        {/* <RightToolbar /> */}
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
      </ModalProvider>
    </PageDataProvider>
    </ChakraProvider>
  )
}

export {MasterLayout}
