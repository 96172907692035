import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./base.api";
import { toast } from "react-toastify";

const tags = ["Contacts"];
export const contactsApi = createApi({
	reducerPath: "contactsApi",
	baseQuery: axiosBaseQuery({
        baseUrl: "",
    }),
	tagTypes: tags,
	endpoints: (builder) => ({
		getContacts: builder.query({
			query: ({ filter, page = 1, pageSize = 10 }) => ({
				url: `/Contact`,
				method: "GET",
				params: {
					page: page,
					pageSize: pageSize,
					...(filter && { filter })
				}
			}),
			providesTags: ["Contacts"],
		}),
		createContact: builder.mutation({
			query: (contact) => ({
				url: "/Contact",
				method: "POST",
				body: contact,
				validateStatus: (response, body) => {
					if (response.ok) {
							toast.success("Contact created successfully", {
							position: toast.POSITION.TOP_RIGHT,
							theme: 'colored',
							pauseOnHover: false,
							pauseOnFocusLoss: false
						})
					}
					return response.ok;
				},
			}),
			invalidatesTags: ["Contacts"],
		})
	}),
});

export const {
	useGetContactsQuery,
	useCreateContactMutation
} = contactsApi;
