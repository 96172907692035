/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle } from 'src/_metronic/layout/core'

import { Link } from "react-router-dom"

import {
    useGetCandidatePositionsQuery
} from 'src/rtk/positions.api'

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button } from '@chakra-ui/react';
import { colors } from 'src/app/libs/config'
import DataTable from 'src/app/components/DataTable'
import Moment from 'react-moment'
import { Can } from 'src/app/routing/AppRoutes'
import { renderChildren } from 'src/app/libs/utils'

const columnHelper = createColumnHelper()

export const CandidateExams = ({ title, candidate, candidatePositions, positions, breadCrumbs, children, queryParams }) => {
    const columns = [
        columnHelper.accessor("companyName", {
            header: 'Company Name'
        }),
        columnHelper.accessor("position", {
            header: 'Position'
        }),
        columnHelper.accessor("name", {
            header: 'Name'
        }),
        columnHelper.accessor("created", {
            header: 'Created',
            cell: (info) => {
                return (
                    <Moment fromNow>{info.getValue()}</Moment>
                )
            },
        }),
        columnHelper.accessor("status", {
            header: 'Status',
        }),
        columnHelper.display({
            header: ' ',
            cell: (info) => {
                return (
                    <Flex flex={1}>
                        <Link to={`${info.row.original.examId}`} >
                            <Button size='sm' color='#A1A5B7'>
                                Go to exam
                            </Button>
                        </Link>
                    </Flex>
                )
            },
        }),
    ]

    return (
        <>
            <PageTitle>
                {title}
            </PageTitle>

            <div className="card card-custom card-flush">
                <DataTable dataSourceQueryFn={useGetCandidatePositionsQuery} queryParams={queryParams} columns={columns} title='Positions'>
                    <div key={'actions'}>{renderChildren(children, 'actions')}</div>
                </DataTable>
            </div>
        </>
    )
}
