/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData, useRouteLoaderData } from "react-router-dom"
import {
    useGetCandidatesQuery,
    useGetCandidatesOverviewQuery
} from 'src/rtk/candidates.api'
import { skipToken } from "@reduxjs/toolkit/query"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button } from '@chakra-ui/react';
import { colors } from 'src/app/libs/config'
import { Can } from 'src/app/routing/AppRoutes'

const columnHelper = createColumnHelper()

export const ProfilePage = ({user, breadCrumbs, children}) => {
    return (
        <>
            <PageTitle breadcrumbs={breadCrumbs}>
                Profile
            </PageTitle>
            <>{children}</>
        </>
    )
}
