import React, { useRef, useState } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Flex,
	Stack,
	Grid,
	Checkbox,
	Center,
	Spinner,
} from "@chakra-ui/react";
import { dimentions } from "src/app/libs/config";
import {
	useDeleteTeammateMutation,
	useUpdateTeammateMutation,
	useAddTeammatesMutation
} from "src/rtk/positions.api";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import ImageField from "src/app/components/form-inputs/ImageField";
import EmailField from "src/app/components/form-inputs/EmailField";
import ActiveField from "src/app/components/form-inputs/ActiveField";
import PhoneField from "src/app/components/form-inputs/PhoneField";
import RoleSelectField from "src/app/components/form-inputs/RoleSelectField";
import DepartmentField from "src/app/components/form-inputs/DepartmentField";
import TextField from "src/app/components/form-inputs/TextField";
import CheckField from "src/app/components/form-inputs/CheckField";
import TextAreaField from "src/app/components/form-inputs/TextAreaField";
import Label from "src/app/components/temp/Label";


const Row = ({ children }) => (
	<Grid gridTemplateColumns='1fr 1fr' gap={6}>
		{children}
	</Grid>
);

const ManageTeammateModal = ({
	trigger,
	initializedData,
}) => {

	const { isOpen, onOpen, onClose } = useDisclosure();
	const inputRef = useRef();

	const form = useForm({
		mode: 'all',
		defaultValues: initializedData ?? {
			avatar: null
		}
	});

	const { handleSubmit, reset } = form;

	const [deleteTeammate, deleteResult] = useDeleteTeammateMutation();
	const [updateTeammate, updateResult] = useUpdateTeammateMutation();
	const [createTeammate, createResult] = useAddTeammatesMutation();



	const deleteTeammateFn = () => {
		deleteTeammate({ id: initializedData.id });
	};

	const handleSubmitFn = (data) => {
		if (initializedData)
			updateTeammate(data)
		else
			createTeammate(data)
	}

	const close = () => {
		reset({});
		onClose();
	};

	useEffect(() => {
		if ((deleteResult?.isSuccess && !deleteResult?.isLoading) ||
			(updateResult?.isSuccess && !updateResult?.isLoading) ||
			(createResult?.isSuccess && !createResult?.isLoading)) {
			close();
		}
	}, [deleteResult?.isLoading, updateResult?.isLoading, createResult?.isLoading]);


	return (
		<>
			{!trigger &&
				<Button colorScheme='green' px={8} onClick={onOpen}>
					Add New Teammate
				</Button>
			}
			{trigger &&
				React.cloneElement(trigger, { onClick: onOpen })
			}


			<Modal isOpen={isOpen} onClose={onClose} size='2xl'>
				<ModalOverlay />
				<ModalContent p={4} borderRadius={dimentions.borderRadius.lg}>
					<ModalHeader>{initializedData ? 'Edit Teammate' : 'Add Teammate'}</ModalHeader>
					<ModalCloseButton />
					<ModalBody borderRadius={dimentions.borderRadius.lg}>
						<input type='file' ref={inputRef} style={{ display: "none" }} />
						<Flex>
							<Stack w='100%' spacing={6} as='form' onSubmit={handleSubmit((data) => handleSubmitFn(data))}>
								<Row>
									<Flex flex={1} alignItems='flex-end'>
										<TextField form={form} fieldName={'firstName'} required={true} label={'First Name'} />
									</Flex>
									<Flex flex={1} alignItems='flex-end' justify='center'>
										<ImageField fieldName={'avatar'} form={form} />
									</Flex>
								</Row>
								<Row>
									<TextField form={form} fieldName={'lastName'} required={true} label={'Last Name'} />
									<ActiveField fieldName={'active'} form={form} label={'Account Status'} required={true} />
								</Row>
								<Row>
									<EmailField form={form} fieldName={'email'} registerOptions={{
										required: 'Email is required'
									}} />
									<RoleSelectField fieldName={'roles'} form={form} />
								</Row>
								<Row>
									<PhoneField label={'Phone Number'} fieldName={'phoneNumber'} form={form} registerOptions={{
										required: 'Phone number is required'
									}} />
									<TextField label={'Job Title'} fieldName={'jobTitle'} form={form} registerOptions={{
										required: 'Job Title is required'
									}} />
								</Row>
								<Row>
									<DepartmentField form={form} fieldName={'departmentId'} registerOptions={{
										required: 'Department is required'
									}} />
								</Row>
								<Row>
									<Label>Overuse Notifications</Label>
									<Flex align='center' gap={4}>
										<CheckField form={form} fieldName={'emailNotifications'} label={'Email'} />
										<CheckField form={form} fieldName={'phoneNotifications'} label={'Phone'} />
									</Flex>
								</Row>
								<Flex>
									<TextAreaField form={form} label={'About'} fieldName={'about'} registerOptions={{}} />
								</Flex>
								<Flex justify='space-between'>
									{initializedData && <>
										<Button
											size='md'
											borderRadius={dimentions.borderRadius.sm}
											colorScheme='blue'
											type='submit'
											whiteSpace='initial'
											isLoading={updateResult.isLoading}
										>
											Update
										</Button>
										<Button
											size='md'
											borderRadius={dimentions.borderRadius.sm}
											colorScheme='red'
											onClick={deleteTeammateFn}
											whiteSpace='initial'
											isLoading={deleteResult.isLoading}
										>
											Delete
										</Button>
									</>}
									{!initializedData && <>
										<Button
											size='md'
											borderRadius={dimentions.borderRadius.sm}
											colorScheme='blue'
											type='submit'
											whiteSpace='initial'
											isLoading={updateResult.isLoading}
										>
											Create
										</Button>
									</>}
								</Flex>
							</Stack>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ManageTeammateModal;
