import { Box, Center, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { colors } from "src/app/libs/config";
import Chart from 'react-apexcharts';
import Container from "./Container";
import SubHeading from "../charts/helpers/SubHeading";


const Gauge = ({ inProgress, total }) => {


	const engNum = (score) => {
		const options = { maximumFractionDigits: 0 }
		const formattedNumber = Intl.NumberFormat("en-US", options).format(score);
		return Number(formattedNumber);
	};

	const series = [engNum(inProgress / total * 100)];

	const options = {
		chart: {
			type: "radialBar",
			sparkline: {
				enabled: true,
			},
		},
		plotOptions: {
			radialBar: {
				startAngle: -90,
				endAngle: 90,
				track: {
					background: "#E7E7E7",
					strokeWidth: "80%",
					margin: 5, // margin is in pixels
					dropShadow: {
						// enabled: true,
						// top: 2,
						// left: 0,
						// color: '#999',
						// opacity: 1,
						// blur: 2,
					},
				},
				dataLabels: {
					name: {
						show: false,
					},
					value: {
						offsetY: -2,
						fontSize: "22px",
						color: 'var(--bs-gray-900)'
					},
				},
			},
		},
		grid: {
			padding: {
				top: -10,
			},
		},
		fill: {
			type: "solid",
		},
		labels: ["Average Results"],
	};
	return (
		<div className={`card card-flush mb-5 mb-xl-10`}>

			<div className='card-header pt-5'>
				<div className='card-title d-flex flex-column'>
					<div className='d-flex align-items-center'>
						<span className='fw-bold text-dark me-2 text-gray-400 pt-1 fw-semibold fs-6'>% Engagement</span>
					</div>
				</div>
			</div>

			<div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
				<div className='d-flex flex-center me-5 pt-2'>
					<Stack justify='space-between' flex={1}>
						<Flex flex={1} align='flex-end'>
							<Chart
								options={options}
								series={series}
								type='radialBar'
								height={200}
							/>
						</Flex>
						<Center pt={4} gap={4} >
							<Flex align='center' gap={2}>
								<Box rounded='full' w='10px' h='10px' bgColor={colors.link}></Box>
								<Text fontSize='14px' fontWeight='semibold' color={colors.textMG} marginBottom={0}>
									Started
								</Text>
							</Flex>
							<Flex align='center' gap={2}>
								<Box rounded='full' w='10px' h='10px' bgColor='#E7E7E7'></Box>
								<Text fontSize='14px' fontWeight='semibold' color={colors.textMG} marginBottom={0}>
									Total
								</Text>
							</Flex>
						</Center>
					</Stack>
				</div>
			</div>
		</div >
	);
};
export default Gauge;
