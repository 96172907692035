import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./base.api";
import { toast } from "react-toastify";

const tags = [];
export const taskApi = createApi({
    reducerPath: "taskApi",
    baseQuery: axiosBaseQuery({
        baseUrl: "",
    }),
    tagTypes: tags,
    endpoints: (builder) => ({
        getChallenges: builder.query({
			query: ({ status, search, page = 1, pageSize = 10 } = {}) => ({
				url: `/challenge`,
				method: "GET",
				params: {
					page: page,
					pageSize: pageSize,
					...(search && { search }),
				},
			}),
		}),
		getPackages: builder.query({
			query: () => ({
				url: `/Management/Package`,
				method: "GET",
			}),
		}),
		getAllChallenges: builder.query({
			query: ({page = 1, pageSize = 10, search, systemChallenge} = {}) => ({
				url: "/management/challenge",
				method: "GET",
				params: {
					private: false,
					page: page,
                    pageSize: pageSize,
					search,
					...(systemChallenge && {systemChallenge: systemChallenge}),
				},
			}),
		}),
    }),
});

export const {
    useGetChallengesQuery, useGetPackagesQuery , useGetAllChallengesQuery
} = taskApi;
