import React from 'react';
import TextField from './TextField';

const EmailField = ({ label, h, form, fieldName, registerOptions, ...props }) => {

    if (!registerOptions) {
        registerOptions = {}
    }

    registerOptions['pattern'] = {
        value:
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Invalid email address",
    };

	return (
		<TextField form={form} fieldName={fieldName} label={label?? 'Email'} {...props} registerOptions={registerOptions} />
	);
};

export default EmailField;
