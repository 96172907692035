import { createRoot } from 'react-dom/client'
// Axios
import axios, { AxiosResponse } from 'axios'
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { AuthInit, AuthProvider, setupAxios } from './app/modules/auth'
import { LoadingProvider } from './providers/Loading/LoadingContext'
import LoadingOverlay from './providers/Loading/LoadingOverlay'
import { api } from "src/app/libs/api";
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)


const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  if (args[0].toString().indexOf(api.baseUrl) !== -1) {
    let url = args[0].toString();
    if (!args[0].toString().startsWith('https')) {
      url = window.location.origin + args[0].toString();
    }
    return  new Response((await axios.get(url, {responseType: 'blob'})).data);
  }
  
  const response = await originalFetch(...args);
  return response;
};

Chart.register(...registerables)


const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <LoadingProvider>
      <LoadingOverlay />
      <QueryClientProvider client={queryClient}>
        <MetronicI18nProvider>
          <AuthProvider>
            <AuthInit>
              <AppRoutes />
            </AuthInit>
          </AuthProvider>
        </MetronicI18nProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </LoadingProvider>
  )
}
