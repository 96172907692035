import { createMongoAbility } from '@casl/ability';
import { useAuth } from '../modules/auth';

export type Actions = 'create' | 'read' | 'write' | 'update' | 'delete' | 'manage' | 'solve' | 'hire' ;
export type Subjects = 'Management' | 'Dashboard' | 'Exam' | 'Organization' | 'Position' | 'Candidate' | 'Teammate' | 'Challenge' | 'ChallengeReport' | 'Comment' | 'User' | 'Matches' | 'ProfileEmail';

export const useUserAbilities = () => {
  const ability = createMongoAbility<[Actions, Subjects]>();
  const { currentUser } = useAuth();

  if (currentUser?.roles?.includes('SysAdmin')) {
    ability.update([
      {action: 'read', subject: 'Management'},
        {action: 'manage', subject: 'User'},
        {action: 'create', subject: 'User'},
        {action: 'manage', subject: 'Organization'},
        // {action: 'read', subject: 'Dashboard'},
    ]);
  } else if (currentUser?.roles?.includes('Admin')) {
    ability.update([
      {action: 'read', subject: 'Management'},
        {action: 'manage', subject: 'User'},
        {action: 'create', subject: 'User'},
        // {action: 'read', subject: 'Dashboard'},
    ]);
  } else if (currentUser?.roles?.includes('Recruiter')) {
    ability.update([
        {action: 'read', subject: 'Management'},
        {action: 'read', subject: 'Candidate'},
        {action: 'create', subject: 'Candidate'},
        {action: 'update', subject: 'Candidate'},
        {action: 'read', subject: 'ChallengeReport'},
        {action: 'read', subject: 'Teammate'},
        {action: 'create', subject: 'Teammate'},
        {action: 'manage', subject: 'Exam'},
        {action: 'create', subject: 'Exam'},
        {action: 'manage', subject: 'Position'},
        {action: 'create', subject: 'Position'},
        {action: 'update', subject: 'ProfileEmail'},
        // {action: 'read', subject: 'Dashboard'},
    ]);
  } else if (currentUser?.roles?.includes('Candidate')) {
    ability.update([
        {action: 'read', subject: 'Exam'},
        {action: 'manage', subject: 'Matches'},
        {action: 'solve', subject: 'Challenge'},
        {action: 'read', subject: 'Candidate'},
    ]);
  }

  return ability;
};