export const colors = {
	primaryDark: '#1B1B28',
	primary: '#1E1E2D',
	primaryLight: '#6C7293',
	background: '#EEF0F8',
	gray: '#F5F8FA',
	light: '#E4E6EF',
	border: '#EFF2F5',
	backgroundBlue: '#F1FAFF',
	danger: 'var(--bs-danger)',
	link: '#00A3FF',
	warning: '#FFD739',
	success: '#1DC894',
	heading: '#181C32',
	text: 'var(--bs-gray-900)',
	textLight: 'var(--bs-gray-900)',
	icon: '#6993FF',
	placeholder: '#A1A5B7 ',
	darkBg: '#2C2C41',
	borderShade: '#2D2D43',
	darkGray: '#494B69',
	textLG: '#959CB6',
	textDG: '#6C7293',
	textMG: 'var(--bs-gray-900)',
};
export const dimentions = {
	borderRadius: {
		lg: '12px',
		sm: '6px',
		xl: "80px"
	},
};
export const sizes = {};

export const breakpoints = {};
