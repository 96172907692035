import React from 'react';
import moment from 'moment';

const TimeAgo = ({ date }) => {
  const calculateTimeAgo = (date) => {
    const now = moment();
    const givenDate = moment(date);
    const diffInSeconds = now.diff(givenDate, 'seconds');

    if (diffInSeconds < 60) {
      return 'a moment ago';
    } else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    } else if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    } else if (diffInSeconds < 2592000) {
      return `${Math.floor(diffInSeconds / 86400)} days ago`;
    } else if (diffInSeconds < 31536000) {
      return `${Math.floor(diffInSeconds / 2592000)} months ago`;
    } else {
      return `${Math.floor(diffInSeconds / 31536000)} years ago`;
    }
  };

  return <span>{calculateTimeAgo(date)}</span>;
};

export default TimeAgo;