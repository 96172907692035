import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./base.api";
import { toast } from "react-toastify";

const tags = ["Candidates", "Users"];
export const candidateApi = createApi({
	reducerPath: "candidateApi",
	baseQuery: axiosBaseQuery({
        baseUrl: "",
    }),
	tagTypes: tags,
	endpoints: (builder) => ({
		getCandidates: builder.query({
			query: ({ status, search, page = 1, pageSize = 10, id }) => ({
				url: `/Management/Candidate`,
				method: "GET",
				params: {
					page: page,
					id: id,
					pageSize: pageSize,
					...(search && { search }),
				}
			}),
			providesTags: ["Candidates"],
		}),
		addCandidate: builder.mutation({
			query: (body) => {
				return {
					url: "/Management/Candidate",
					method: "POST",
					body: body,
					validateStatus: (response, body) => {
						if (response.ok) {
							toast.success("Candidate added successfully", {
								position: toast.POSITION.TOP_RIGHT,
								theme: 'colored',
								pauseOnHover: false,
								pauseOnFocusLoss: false
							})
						}
						return response.ok;
					},
				};
			},
			invalidatesTags: ["Candidates"],
		}),
		updateCandidate: builder.mutation({
            query: (body) => ({
                url: "/management/Candidate",
                method: "PATCH",
                body,
                params: {
                    id: body.id,
                },
                validateStatus: (response, body) => {
                    if (response.ok) {

                        toast.success("Candidate updated successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                            theme: 'colored',
                            pauseOnHover: false,
                            pauseOnFocusLoss: false
                        })
                    }
                    return response.ok;
                },
            }),
            invalidatesTags: ["Candidates"],
        }),
		getCandidatesOverview: builder.query({
			query: () => ({
				url: `/Management/candidate/overview`,
				method: "GET"
			}),
			providesTags: ["Candidates"],
		}),
		inviteCandidate: builder.mutation({
			query: ({candidateId, jobId, matchId}) => ({
				url: "/Candidate/Invite",
				method: "GET",
				params: {
					candidateId,
					matchId,
					jobId
				},
				validateStatus: (response, body) => {
					if (response.ok) {
						toast.success("Candidate invited successfully", {
							position: toast.POSITION.TOP_RIGHT,
							theme: 'colored',
							pauseOnHover: false,
							pauseOnFocusLoss: false
						})
					}
					return response.ok;
				},
			}),
			invalidatesTags: ["Users"],
		}),
		getCandidateResumeUrl: builder.mutation({
			query: ({candidateId}) => ({
				url: "/Candidate/resume",
				method: "GET",
				params: {
					candidateId
				}
			})
		}),
	}),
});

export const {
	useGetCandidatesQuery,
	useGetCandidatesOverviewQuery,
	useAddCandidateMutation,
	useUpdateCandidateMutation,
	useInviteCandidateMutation,
	useGetCandidateResumeUrlMutation
} = candidateApi;
