import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./base.api";
import { toast } from "react-toastify";

const tags = ["Teammate", "Users", "Candidates", "User"];
export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: axiosBaseQuery({
        baseUrl: "",
    }),
    tagTypes: tags,
    endpoints: (builder) => ({
        getUser: builder.query({
            query: () => ({
                url: `/user`,
                method: "GET",
                // params: {
                // 	page,
                // 	...(search && { search: search }),
                // },
            }),
            providesTags: ["User"]
        }),
        getCandidate: builder.query({
            query: (id) => ({
                url: "/management/candidate",
                method: "GET",
                params: {
                    id: id,
                },
            }),
            providesTags: ["Candidates"],
        }),
        updateUser: builder.mutation({
            query: (body) => ({
                url: `/user`,
                method: "PATCH",
                body: body,
                validateStatus: (response, body) => {
                    if (response.ok) {

                        toast.success("Profile updated successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                            theme: 'colored',
                            pauseOnHover: false,
                            pauseOnFocusLoss: false
                        })

                    }
                    return response.ok;
                },
                // params: {
                // 	page,
                // 	...(search && { search: search }),
                // },
            }),
            
            invalidatesTags: ["User"],
        }),
        getAllUsersByStatus: builder.query({
            query: () => ({
                url: `/management/user/overview`,
                method: "GET",
                params: {
                    by_status: true,
                },
            }),
            providesTags: ["Users"],
        }),
        getAllUsersByRole: builder.query({
            query: () => ({
                url: `/management/user/overview`,
                method: "GET",
                params: {
                    by_role: true,
                },
            }),
        }),
        getAllUsers: builder.query({
            query: ({ page, search, pageSize, order, sort } = {}) => ({
                url: `/management/user`,
                method: "GET",
                params: {
                    page: page,
                    pageSize: 10,
                    ...(search && { search: search }),
                    ...(sort && { sort: sort }),
					...(order && { order: order }),
                },
            }),
            providesTags: ["Users"],
        }),
        getAllRoles: builder.query({
            query: () => ({
                url: `/management/role`,
                method: "GET",
                // params: {
                // 	page: page - 1,
                // 	...(search && { search: search }),
                // },
            }),
        }),
        addUser: builder.mutation({
            query: (body) => ({
                url: "/management/user",
                method: "POST",
                body,
                validateStatus: (response, body) => {
                    if (response.ok) {

                        toast.success("User created successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                            theme: 'colored',
                            pauseOnHover: false,
                            pauseOnFocusLoss: false
                        })

                    }
                    return response.ok;
                },
            }),
            invalidatesTags: ["Users"],
        }),
        editUser: builder.mutation({
            query: (body) => ({
                url: "/management/user",
                method: "PATCH",
                body,
                params: {
                    id: body.id,
                },
                validateStatus: (response, body) => {
                    if (response.ok) {

                        toast.success("User edited successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                            theme: 'colored',
                            pauseOnHover: false,
                            pauseOnFocusLoss: false
                        })
                    }
                    return response.ok;
                },
            }),
            invalidatesTags: ["Users"],
        }),
        getUserOverview: builder.query({
            query: () => ({
                url: "/management/user/overview",
                method: "GET",
                params: {
                    by_Status: true,
                },
            }),
        }),
        getUserOverviewByRoles: builder.query({
            query: () => ({
                url: "/management/user/overview",
                method: "GET",
                params: {
                    by_roles: true,
                },
            }),
            providesTags: ["Users"],
        }),
        deleteUser: builder.mutation({
            query: ({ id } = {}) => ({
                url: "/management/user",
                method: "DELETE",
                params: {
                    id,
                },
            }),
            invalidatesTags: ["Users"],
        }),
        resetUserPassword: builder.mutation({
            query: (payload) => ({
                url: "/auth/reset-password",
                method: "POST",
                body: payload
            })
        })
    }),
});

export const {
    useGetUserQuery,
    useUpdateUserMutation,
    useGetAllUsersByStatusQuery,
    useGetAllUsersByRoleQuery,
    useGetAllUsersQuery,
    useGetAllRolesQuery,
    useAddUserMutation,
    useEditUserMutation,
    useGetUserOverviewQuery,
    useGetUserOverviewByRolesQuery,
    useDeleteUserMutation,
    useGetCandidateQuery,
    useResetUserPasswordMutation
} = userApi;
