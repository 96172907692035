export enum MatchStatus {
    NotMatched = 0,
    Matched = 100,
    Invited = 200,
    Registered = 300,
    Declined = 400,
    Applied = 500,
    CompanyReview = 600,
    PassedScreening = 700,
    DeclinedByCompany = 800,
    Hired = 900,
    Paid = 1000
}

export const MatchStatusDetails: {
    [key in MatchStatus]: {
        label: string;
        badgeColor: string; // Corresponds to Bootstrap badge colors
    };
} = {
    [MatchStatus.NotMatched]: {
        label: 'Not Matched',
        badgeColor: 'secondary' // Gray
    },
    [MatchStatus.Matched]: {
        label: 'Matched',
        badgeColor: 'success' // Green
    },
    [MatchStatus.Invited]: {
        label: 'Invited',
        badgeColor: 'info' // Blue
    },
    [MatchStatus.Registered]: {
        label: 'Registered',
        badgeColor: 'primary' // Dark Blue
    },
    [MatchStatus.Declined]: {
        label: 'Declined',
        badgeColor: 'danger' // Red
    },
    [MatchStatus.Applied]: {
        label: 'Applied',
        badgeColor: 'warning' // Yellow
    },
    [MatchStatus.CompanyReview]: {
        label: 'Company Review',
        badgeColor: 'info' // Blue
    },
    [MatchStatus.PassedScreening]: {
        label: 'Passed Screening',
        badgeColor: 'success' // Green
    },
    [MatchStatus.DeclinedByCompany]: {
        label: 'Declined by Company',
        badgeColor: 'danger' // Red
    },
    [MatchStatus.Hired]: {
        label: 'Hired',
        badgeColor: 'success' // Green
    },
    [MatchStatus.Paid]: {
        label: 'Paid',
        badgeColor: 'primary' // Dark Blue
    }
};