import React from 'react';
import TextField from './TextField';
import parsePhoneNumber from 'libphonenumber-js'

const PhoneField = ({ hideLabel, label, h, form, fieldName, registerOptions, ...props }) => {

    const isValidPhoneNumber = (phoneNumber) => {
		return parsePhoneNumber(phoneNumber)?.isValid() === true;
	};

    if (!registerOptions) {
        registerOptions = {}
    }

    registerOptions['validate'] = (value) => isValidPhoneNumber(value) || 'Invalid Phone Number';


	return (
		<TextField form={form} fieldName={fieldName} label={label} hideLabel={hideLabel} {...props} registerOptions={registerOptions} />
	);
};

export default PhoneField;
