/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, Suspense, lazy, useEffect, useState } from 'react'
import { Routes, Route, BrowserRouter, useNavigate, Navigate, createBrowserRouter, RouterProvider, createRoutesFromElements, defer, useRouteError, Outlet, Await, useLocation, useMatches, Link, isRouteErrorResponse } from 'react-router-dom'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import axios from 'axios'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
// import { CandidatesPage } from '../modules/management/candidates/CandidatesPage';
import { store } from "../../redux/store";
import { Provider } from 'react-redux'
import { CandidatesLoader } from '../loaders/candidates.loaders'
import { CandidateProfilePage } from '../modules/management/candidates/candidate/CandidateProfilePage'
import { CandidateExamsPage } from '../modules/management/candidates/candidate/CandidateExamsPage'
import { LayoutSplashScreen } from 'src/_metronic/layout/core'
import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AnyAbility } from '@casl/ability'
import { useUserAbilities } from 'src/app/libs/ability'
import CandidateExamPage from '../modules/exams/CandidateExamPage'
import ChallengeReportPage from '../modules/exams/ChallengeReportPage'
import { ExamsLoader } from '../loaders/exams.loaders'
import { TeammatesLoader } from '../loaders/teammates.loaders'
import TeammatesPage from '../modules/management/teammates/TeammatesPage'
import ManagementExamsPage from '../modules/management/exams/ManagementExamsPage'
import ManagementExamPage from '../modules/management/exams/ManagementExamPage'
import PositionsPage from '../modules/management/positions/PositionsPage'
import { PositionsLoader } from '../loaders/positions.loaders'
import PositionPage from '../modules/management/positions/PositionPage'
import { UsersLoader } from '../loaders/users.loaders'
import OrganizationsPage from '../modules/management/organizations/OrganizationsPage'
import { OrganizationsLoader } from '../loaders/organizations.loaders'
import { ProfileHeaderWrapper } from '../modules/management/candidates/candidate/components/ProfileHeaderWrapper'
import { UserProfileHeaderWrapper } from '../modules/profile/UserProfileHeaderWrapper'
import { AccountProfilePage } from '../modules/profile/components/account/AccountProfilePage'
import { ExamsPage } from '../modules/exams/ExamsPage'
import { ExamPageWrapper } from '../modules/candidate/ExamPageWrapper'
import { ChallengesLoader } from '../loaders/tasks.loaders'
import { ChallengeIntroPage } from '../modules/tasks/ChallengeIntroPage'
import { ChallengeEnvironementPage } from '../modules/tasks/ChallengeEnvironementPage'
import { ErrorsLayout } from '../modules/errors/ErrorsLayout'
import { Error404 } from '../modules/errors/components/Error404'
import { Error500 } from '../modules/errors/components/Error500'
import { DrushimLoader } from '../loaders/drushim.loaders'
import ManageDrushimCandidatesPage from '../modules/drushim/ManageDrushimCandidatesPage'
import CandidateJobsPage from '../modules/drushim/CandidateJobsPage'
import CandidateJobReportPage from '../modules/drushim/CandidateJobReportPage'
import ManageJobsPage from '../modules/jobs/ManageJobsPage'
import JobCandidatesPage from '../modules/jobs/JobCandidatesPage'
import JobCandidateReportPage from '../modules/jobs/JobCandidateReportPage'
import ManageCompaniesPage from '../modules/companies/ManageCompaniesPage'
import CandidateMatches from '../modules/candidate/CandidateMatches'
import { MatchPageWrapper } from '../modules/candidate/components/MatchPageWrapper'


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

// function ErrorBoundary() {
//   let error = useRouteError() as any;
//   console.error(error);
//   if (error?.status === 401)
//     return <Navigate to='/auth' replace={false} />;
//   return null;
// }

// const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
const CandidatesPage = lazy(() => import('../modules/management/candidates/CandidatesPage'))
const ManagementUsersPage = lazy(() => import('../modules/management/users/UsersPage'))


const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')

  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 2,
    shadowBlur: 10,
  })
  return <Suspense fallback={

    <>
      <TopBarProgress />
    </>}>{children}</Suspense>
}

export const AbilityContext = createContext<AnyAbility>(undefined!);
export default createContextualCan(AbilityContext.Consumer);
export const Can = createContextualCan(AbilityContext.Consumer);

const UnauthorizedErrorHandler: FC = () => {
  const error = useRouteError();
  const { pathname } = useLocation()
  return (<Navigate to={'/auth'} state={{
    redirectTo: pathname
  }} />)
};

const LoginRedirectionHandler: FC = () => {
  const { state } = useLocation()

  if (state && state.redirectTo) {
    window.history.pushState(null, "", "/");
    return (<Navigate to={state.redirectTo} />)
  }

  return (<Navigate to={'/'} />)
};

const ErrorHandler: FC = () => {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) 
      return <ErrorsLayout> <Error404 /> </ErrorsLayout>
    else if (error.status >= 500)
      return <ErrorsLayout> <Error500 /> </ErrorsLayout>
    else
      return <ErrorsLayout> <Error500 /> </ErrorsLayout>
  } else {
    // for now, it should be replaced in the near future
    return <ErrorsLayout> <Error500 /> </ErrorsLayout>
  }
};


const AppRoutes: FC = () => {
  const userAbilities = useUserAbilities()
  const { currentUser } = useAuth()

  const router = (store: any) => createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Suspense fallback={<LayoutSplashScreen visible={true} />}><App /></Suspense>}>
        {currentUser?.roles?.includes("Candidate") && <Route index element={<Navigate to={'/account/matches'} />} />}
        {currentUser?.roles?.includes("Recruiter") && <Route index element={<Navigate to={'/management/jobs'} />} />}
        {currentUser?.roles?.includes("Admin") && <Route index element={<Navigate to={'/management/teammates'} />} />}
        {currentUser?.roles?.includes("SysAdmin") && <Route index element={<Navigate to={'/management/organizations'} />} />}
        {/* {userAbilities.can('read', 'Dashboard') && <><Route path='dashboard' element={<DashboardWrapper />} />
          <Route index element={<Navigate to='/dashboard' />} />
        </>
        } */}
        <Route path='error/*' element={<ErrorsPage />} />
        <Route path='logout' element={<Logout />} />
        <Route path='auth/*' element={currentUser === undefined ? <AuthPage /> : <LoginRedirectionHandler />} />
        <Route path='/*' element={currentUser === undefined ? <UnauthorizedErrorHandler /> : <MasterLayout />} errorElement={<ErrorHandler />}>
          <Route path='account' handle={{
            crumb: () => <Link to="/account/profile">Account</Link>,
            contentType: 'fixed'
          }}
            element={
              <SuspensedView>
                <UserProfileHeaderWrapper />
                <Outlet />
              </SuspensedView>
            }>
            <Route index element={<Navigate to={'profile'} />} />
            <Route path='profile' handle={{
              crumb: () => <Link to="profile">Profile</Link>,
            }} element={<AccountProfilePage />} />
            {userAbilities.can('solve', 'Challenge') &&
              <Route path='Matches'
                handle={{
                  crumb: () => <Link to="matches">Matches</Link>,
                }} >
                <Route index loader={new DrushimLoader(store).getCandidateMatches} id="candidateMatches" element={<CandidateMatches />} />
                <Route path=':matchId' loader={new DrushimLoader(store).getCandidateMatch} element={<MatchPageWrapper />} />
                {/* <Route path=':examId' id="exam" loader={new CandidatesLoader(store).getMyExam}
                  handle={{
                    crumb: (data: any) => <Link to="exam">{data.exam.name}</Link>,
                  }}
                >
                  <Route index element={
                    <SuspensedView>
                      <ExamPageWrapper />
                    </SuspensedView>
                  } />
                  <Route path=':challengeId' loader={new ChallengesLoader(store).getChallengeIntroData} errorElement={<Navigate to={'..'} relative='path' />} 
                   handle={{
                    crumb: (data: any) => <Link to="challenge-intro">{data?.challenge?.data[0]?.challenge.name}</Link>,
                  }}
                  id='challenge'>
                    <Route index element={<ChallengeIntroPage />} />
                    <Route path='env' loader={new ChallengesLoader(store).checkIfVsCodeAvailable} handle={{
                    crumb: (data: any) => <Link to="env">Environment</Link>,
                  }} element={<ChallengeEnvironementPage />} />
                  </Route>
                </Route> */}
              </Route>}
            {userAbilities.can('solve', 'Challenge') &&
              <Route path='Exams'
                handle={{
                  crumb: () => <Link to="exams">Exams</Link>,
                }} >
                <Route index loader={new PositionsLoader(store).getMyPositions} element={<ExamsPage />} />
                <Route path=':examId' id="exam" loader={new CandidatesLoader(store).getMyExam}
                  handle={{
                    crumb: (data: any) => <Link to="exam">{data.exam.name}</Link>,
                  }}
                >
                  <Route index element={
                    <SuspensedView>
                      <ExamPageWrapper />
                    </SuspensedView>
                  } />
                  <Route path=':challengeId' loader={new ChallengesLoader(store).getChallengeIntroData} errorElement={<Navigate to={'..'} relative='path' />} 
                   handle={{
                    crumb: (data: any) => <Link to="challenge-intro">{data?.challenge?.data[0]?.challenge.name}</Link>,
                  }}
                  id='challenge'>
                    <Route index element={<ChallengeIntroPage />} />
                    <Route path='env' loader={new ChallengesLoader(store).checkIfVsCodeAvailable} handle={{
                    crumb: (data: any) => <Link to="env">Environment</Link>,
                  }} element={<ChallengeEnvironementPage />} />
                  </Route>
                </Route>
              </Route>}
          </Route>
          {userAbilities?.can('read', 'Management') && <Route path='management'>
            {userAbilities?.can('read', 'Candidate') && <Route path='candidates' handle={{
              crumb: () => <Link to="candidates">Candidates</Link>,
            }}>
              <Route index loader={new CandidatesLoader(store).listCandidates} element={
                <CandidatesPage />
              } />
              <Route path=':candidateId' id='candidateProfile' loader={new CandidatesLoader(store).getCandidate} element={
                <SuspensedView>
                  <ProfileHeaderWrapper />
                  <Outlet />
                </SuspensedView>
              } handle={{
                crumb: (data: any) => <Link to={'#'}>{`${data.candidate.firstName} ${data.candidate.lastName}`}</Link>,
              }}>
                <Route index element={<Navigate to='profile' />} />
                <Route path='profile' element={
                  <SuspensedView>
                    <CandidateProfilePage />
                  </SuspensedView>
                } handle={{
                  crumb: () => <Link to={`profile`}>Profile</Link>,
                }} />
                <Route path='exams' handle={{
                  crumb: () => <Link to={`exams`}>Exams</Link>,
                }}>
                  <Route index
                    loader={new CandidatesLoader(store).getCandidatePositions}
                    element={
                      <SuspensedView>
                        <CandidateExamsPage />
                      </SuspensedView>
                    }

                  />
                  <Route path=':examId'
                    id="exam"
                    handle={{
                      crumb: (data: any) => <Link to="exam">{data.exam.name}</Link>,
                    }}
                    loader={new CandidatesLoader(store).getCandidateExam}>
                    <Route index element={
                      <SuspensedView>
                        <CandidateExamPage />
                      </SuspensedView>
                    } />
                    {userAbilities.can('read', 'ChallengeReport') && <Route path='challenge/:challengeId/report'
                      // loader={new ExamsLoader(store).getChallengeReport}
                      handle={{
                        crumb: (data: any) => <Link to="challenge-report">{data.report.taskName}'s Report</Link>,
                      }}
                      element={
                        <SuspensedView>
                          <ChallengeReportPage />
                        </SuspensedView>
                      } />}

                  </Route>
                </Route>
              </Route>
            </Route>
            }
            {
              userAbilities?.can('read', 'Teammate') && <Route path='teammates' loader={new TeammatesLoader(store).getTeammatesPageData} element={
                <TeammatesPage />
              } />
            }
            {
              userAbilities?.can('read', 'Teammate') && <Route path='companies' element={
                <ManageCompaniesPage />
              } />
            }
            {
              userAbilities?.can('manage', 'Exam') && <Route path='exams'>
                <Route index loader={new ExamsLoader(store).getExamsPageData} element={<ManagementExamsPage />} />
                <Route path=':assessmentTemplateId' loader={new ExamsLoader(store).getExamPageData} element={<ManagementExamPage />} />
              </Route>
            }
            {
              userAbilities?.can('manage', 'Position') && <Route path='positions'>
                <Route index loader={new PositionsLoader(store).getPositionsPageData} element={<PositionsPage />} />
                <Route path=':positionId' loader={new PositionsLoader(store).getPositionPageData} element={<PositionPage />} />
              </Route>
            }
            {
              userAbilities?.can('manage', 'User') && <Route path='users'>
                <Route index loader={new UsersLoader(store).getUsersPageData} element={<ManagementUsersPage />} />
              </Route>
            }
            {
            <Route path='drushim'>
                <Route index loader={new DrushimLoader(store).listCandidates} element={<ManageDrushimCandidatesPage />} />
                <Route path=':candidateId' id='candidate' loader={new DrushimLoader(store).getCandidate} element={ <SuspensedView>
                  <Outlet />
                </SuspensedView>}>
                  <Route index element={<CandidateJobsPage />} />
                  <Route path='report/:jobId' loader={new DrushimLoader(store).getCandidateJobReport} element={<CandidateJobReportPage />} />
                </Route>
            </Route>
            }
            {
            <Route path='jobs'>
                <Route index loader={new DrushimLoader(store).listJobs} element={<ManageJobsPage />} />
                <Route path=':jobId' id='job' loader={new DrushimLoader(store).getJob} element={ <SuspensedView>
                  <Outlet />
                </SuspensedView>}>
                  <Route index element={<JobCandidatesPage />} />
                  <Route path='report/:candidateId' loader={new DrushimLoader(store).getJobCandidateReport} element={<JobCandidateReportPage />} />
                </Route>
            </Route>
            }
            {
              userAbilities?.can('manage', 'Organization') && <Route path='organizations'>
                <Route index loader={new OrganizationsLoader(store).getOrganizationsData} element={<OrganizationsPage />} />
              </Route>
            }
          </Route>
          }

          {/* <Route path='dashboard' element={<DashboardWrapper />} />
          <Route index element={<Navigate to='/dashboard' />} /> */}
          <Route index element={<Navigate to='/dashboard' />} />
          <Route path='builder' element={<BuilderPageWrapper />} />
          <Route path='menu-test' element={<MenuTestPage />} />
          {/* Lazy Modules */}
          {/* <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          /> */}
          <Route
            path='crafted/pages/wizards/*'
            element={
              <SuspensedView>
                <WizardsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/widgets/*'
            element={
              <SuspensedView>
                <WidgetsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/chat/*'
            element={
              <SuspensedView>
                <ChatPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Route>
    ), {
    basename: PUBLIC_URL
  }
  );

  return (
    <Provider store={store}>
      <AbilityContext.Provider value={userAbilities}>
        <RouterProvider router={router(store)} />
      </AbilityContext.Provider>
    </Provider>
  )
}



export { AppRoutes }
