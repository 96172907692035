import { dimentions } from 'src/app/libs/config';
import { Stack } from '@chakra-ui/react';
import React from 'react';

const Container = ({ children, ...props }) => {
	return (
		<Stack
			bg='var(--bs-body-bg)'
			borderRadius={dimentions.borderRadius.lg}
			py={7}
			px={8}
			{...props}>
			{children}
		</Stack>
	);
};

export default Container;
