import {BaseLoader} from "../../common/base.loader"
import { assessmentApi } from '../../rtk/assessments.api'
import { positionApi } from "src/rtk/positions.api";
import { departmentApi } from "src/rtk/departments.api";
import { defer } from "react-router-dom";


export class TeammatesLoader extends BaseLoader {
    getTeammatesPageData = async ({ request, params }) => {
        
        const getTeammatesDepartmentsLoader = this._loader(
            departmentApi.endpoints.getTeammatesDepartments,
            request
        );

        const getPositionsOverviewLoader = this._loader(
            positionApi.endpoints.getPositionsOverview,
            request
        );

        const getTeammatesLoader = this._loader(
            positionApi.endpoints.getTeammates,
            request,
            {
                page: 1,
                pageSize: 10,
                roles: ['Recruiter', 'Interviewer']
            }
        );

        const [ departments, positionsOverview, teammates ] = await Promise.all([getTeammatesDepartmentsLoader, getPositionsOverviewLoader, getTeammatesLoader])
        return { departments, positionsOverview, teammates }
    };

}