import React, { useEffect, useRef, useState } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Flex,
	Stack,
	Grid,
	Box,
	Checkbox,
} from "@chakra-ui/react";
import { dimentions } from "src/app/libs/config";
import { useAddTeammatesMutation } from "src/rtk/positions.api";
import {
	useAddOrganizationMutation,
	useDeleteOrganizationMutation,
	useUpdateOrganizationMutation
} from "src/rtk/organizations.api";
import { useForm } from "react-hook-form";
import TextField from "src/app/components/form-inputs/TextField";
import ImageField from "src/app/components/form-inputs/ImageField";
import ActiveField from "src/app/components/form-inputs/ActiveField";
import EmailField from "src/app/components/form-inputs/EmailField";
import PhoneField from "src/app/components/form-inputs/PhoneField";
import CheckField from "src/app/components/form-inputs/CheckField";
import TextAreaField from "src/app/components/form-inputs/TextAreaField";
import Row from "src/app/components/temp/Row";
import Label from "src/app/components/temp/Label";


const ManageOrganization = ({ organization }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const form = useForm({
		mode: 'all',
		defaultValues: organization ?? {
			logoUrl: null
		}
	});

	const { handleSubmit, reset } = form

	const [updateOrganization, updateResult] = useUpdateOrganizationMutation();
	const [deleteOrganization, deleteResult] = useDeleteOrganizationMutation();
	const [createOrganization, createResult] = useAddOrganizationMutation();
	
	const close = () => {
		reset({})
		onClose();
	};

	useEffect(() => {
		if ((deleteResult?.isSuccess && !deleteResult?.isLoading) ||
			(updateResult?.isSuccess && !updateResult?.isLoading) ||
			(createResult?.isSuccess && !createResult?.isLoading)) {
			close();
		}
	}, [deleteResult?.isLoading, updateResult?.isLoading, createResult?.isLoading]);

	return (
		<>
			<Button size='sm' color='#A1A5B7' onClick={onOpen}>
				{organization ? 'Edit' : 'Add Organization'}
			</Button>

			<Modal
				closeOnOverlayClick={false}
				isOpen={isOpen}
				onClose={close}
				size='2xl'
				trapFocus={false}
			>
				<ModalOverlay />
				<ModalContent p={4} borderRadius={dimentions.borderRadius.lg}>
					<ModalHeader>{organization ? 'Edit Organization' : 'Add Organization'}</ModalHeader>
					<ModalCloseButton />
					<ModalBody borderRadius={dimentions.borderRadius.lg}>
						<Stack w='100%' spacing={6} as='form' onSubmit={handleSubmit((data) => organization? updateOrganization(data) : createOrganization(data))}>
							<Row>
								<Flex flex={1} alignItems='flex-end'>
									<TextField form={form} fieldName={'name'} label={'Organization Name'} required={true} />
								</Flex>
								<Flex flex={1} alignItems='flex-end' justify='center'>
									<ImageField width={'100px'} fieldName={'logoUrl'} form={form} />
								</Flex>
							</Row>

							<Row>
								<TextField form={form} fieldName={'taxId'} label={'Tax ID'} required={true} />
								<ActiveField form={form} fieldName={'active'} label={'Status'} />
							</Row>
							<Row>
							<TextField
										form={form}
										registerOptions={{
											maxLength: {
												value: 20,
												message: 'Max length: 20'
											},
											required: 'Contact Name is required!'
										}}
										fieldName={'contactName'}
										label='Contact Name'
									/>
									<EmailField
									form={form}
									registerOptions={
										{
											required: {
												value: true,
												message: "Please enter your email address",
											}
										}
									}
									fieldName={'email'}
									label={'Contact Email'}
								/>
								
							</Row>
							<Row>
							<PhoneField
									form={form}
									registerOptions={{
										required: {
											value: true,
											message: "Please enter your phone Number",
										}
									}}
									fieldName={'phoneNumber'}
									label='Contact Number'
								/>
							</Row>

							<Row>
								<Label>Overuse Notifications</Label>
								<Flex align='center' gap={4}>
									<CheckField form={form} fieldName={'emailNotifications'} label={'Email'} />
									<CheckField form={form} fieldName={'phoneNotifications'} label={'Phone'} />
								</Flex>
							</Row>
							<Flex>
								<TextAreaField form={form} label={'About'} fieldName={'about'} registerOptions={{}} />
							</Flex>
							<Flex justify='space-between'>
								<Button
									size='md'
									borderRadius={dimentions.borderRadius.sm}
									colorScheme='blue'
									type='submit'
								>
									{organization? 'Update' : 'Create'}
								</Button>
								{organization && 
								<>
								<Button
									size='md'
									borderRadius={dimentions.borderRadius.sm}
									colorScheme='red'
									onClick={(e) =>
										deleteOrganization({
											id: organization?.id,
										})
									}
								>
									Delete
								</Button>
								</>
								}
								
							</Flex>
						</Stack>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ManageOrganization;
