/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Checkbox,
	Flex,
	Stack,
	Box,
} from "@chakra-ui/react";
import { dimentions } from "src/app/libs/config";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import { useForm } from "react-hook-form";
import SelectField from "src/app/components/form-inputs/SelectField";
import TextAreaField from "src/app/components/form-inputs/TextAreaField";
import TextField from "src/app/components/form-inputs/TextField";
import DynamicForm from "src/app/components/DynamicForm";


const ManageEntity = ({ entity, title, config, updateMutation, createMutation, ...props }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const form = useForm({
		mode: 'all',
		defaultValues : {
		}
	});

	const { handleSubmit, reset } = form;

	let [trigger, result] = [];

	if (entity && updateMutation) {
		[trigger, result] = updateMutation();
	} else {
		[trigger, result] = createMutation();
	}


	const submit = (data) => {
		trigger(data);
	}

	const close = () => {
		reset({});
		onClose();
	};

	useEffect(() => {
		if (result?.isSuccess && !result?.isLoading) {
			close();
		}
	}, [result?.isLoading]);


	return (
		<>
			<Button colorScheme='green' px={8} onClick={onOpen}>
				{entity ? `Edit ${title}` : `Add ${title}`}
			</Button>

			<Modal isOpen={isOpen} onClose={close} size='2xl'>
				<ModalOverlay />
				<ModalContent p={4} borderRadius={dimentions.borderRadius.lg}>
					<ModalHeader>{entity ? `Edit ${title}` : `Add ${title}`}</ModalHeader>
					<ModalCloseButton />
					<ModalBody borderRadius={dimentions.borderRadius.lg}>
						<DynamicForm config={config} onSubmit={submit} defaultValues={entity} />
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ManageEntity;
