import { colors, dimentions } from "src/app/libs/config";
import {
    Button,
    Flex,
    Text
} from "@chakra-ui/react";
import { React } from "react";
import { useCheckIfReportAvailableQuery } from "src/rtk/challenges.api";
import { Link, useLocation } from "react-router-dom";
import { Can } from "src/app/routing/AppRoutes";
import { useUserAbilities } from "src/app/libs/ability";
import { skipToken } from "@reduxjs/toolkit/query";



const Challenge = ({ candidateId, examId, challenge }) => {

    const { state } = useLocation()
    
    const userAbilities = useUserAbilities()

    const { data: reportStatusCheck, isLoading: reportStatusCheckLoading } = useCheckIfReportAvailableQuery(userAbilities.can('read', 'ChallengeReport') ? {
        examId,
        challengeId: challenge.id
    } : skipToken);

    return (
        <Flex
            w='full'
            border='1px solid var(--bs-border-color)'
            borderRadius={dimentions.borderRadius.sm}
            align='center'
            justify='space-between'
            px={4}
            py={2}
        >
            <Text
                flex={8}
                fontWeight='medium'
                color={colors.textLight}
                fontSize='18px'
                marginBottom={0}
            >
                {challenge?.challenge.name}
            </Text>
            <Can I={'read'} a={'ChallengeReport'}>
                <Link
                    to={`challenge/${challenge.id}/report`}
                >
                    <Button isLoading={reportStatusCheckLoading} loadingText={`Checking`} isDisabled={!(challenge?.status === "Finished" ||
                        challenge?.status === "Closed" || reportStatusCheck?.status === "Succeeded")} colorScheme="blue">{reportStatusCheck?.status === 'Succeeded' ? `View Report` : reportStatusCheck?.status}</Button>
                </Link>
            </Can>
            <Can I={'solve'} a={'Challenge'}>
                {challenge?.status === "Finished" ||
                    challenge?.status === "Closed" || (
                        state && state[challenge.id] === 'submitted'
                    ) ? (
                    <Button className="btn btn-success" size='md' isDisabled={true}>
                        Submitted
                    </Button>
                ) : (
                    <Link
                        to={challenge?.id}
                    >
                        <Button className="btn btn-light">{`Let's Go`}</Button>
                    </Link>
                )}
            </Can>
        </Flex>
    );
};

export default Challenge;
