/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData, useLocation, useNavigate, useNavigation, useRouteLoaderData } from "react-router-dom"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button } from '@chakra-ui/react';
import { colors } from 'src/app/libs/config'
import { ProfileDetails } from 'src/app/modules/profile/components/account/ProfileDetails'
import { ProfilePage } from 'src/app/modules/profile/ProfilePage'
import { useUpdateUserMutation } from 'src/rtk/users.api'
import { useAuth } from 'src/app/modules/auth'
import { Preferences } from './Preferences'

const columnHelper = createColumnHelper()

export const AccountProfilePage = ({ }) => {
    
    const { currentUser } = useAuth()

    const breadCrumbs: Array<PageLink> = [
        {
            title: 'Account',
            path: '#',
            isSeparator: false,
            isActive: false
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false
        },
    ]

    const { hash, pathname } = useLocation()
    
    const scroll = (selector) => {
        const section = document?.getElementById(selector.split('#')[1]);
        section?.scrollIntoView();
    };

    useEffect(() => {
        if (hash)
            scroll(hash)
    }, [hash])

    return (
        <>
            <ProfilePage user={currentUser}>
                <section>
                    <ProfileDetails viewOnly={false} updateMutation={useUpdateUserMutation} user={currentUser} />
                </section>
                <section id={'preferences'}>
                    <Preferences viewOnly={false} updateMutation={useUpdateUserMutation} user={currentUser} />
                </section>
            </ProfilePage>
        </>
    )
}
