import React, { useEffect, useCallback, useState } from "react";
import SelectField from './SelectField';
import { useGetContactsQuery, useCreateContactMutation } from "src/rtk/contacts.api";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	useDisclosure,
	Checkbox,
	Flex,
	Stack,
	Box
} from "@chakra-ui/react";
import { useModal } from "../ModalContext";
import DynamicForm from "../DynamicForm";

const config = {
    "title": "Contact Form",
    "layout": {
      "columns": 2,
      "fields": [
        ["name", "email"],
        ["phone", "title"],
        ["linkedIn", "notes"]
      ]
    },
    "fields": {
      "name": {
        "type": "string",
        "title": "Name",
        "validation": {
          "required": "Name is required"
        }
      },
      "email": {
        "type": "string",
        "title": "Email",
        "validation": {
          "required": "Email is required",
          "pattern": {
            "value": "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
            "message": "Enter a valid email address"
          }
        }
      },
      "phone": {
        "type": "string",
        "title": "Phone",
        "validation": {
          "required": "Phone number is required",
          "pattern": {
            "value": "^\\d{10}$",
            "message": "Enter a valid 10-digit phone number"
          }
        }
      },
      "title": {
        "type": "string",
        "title": "Title"
      },
      "linkedIn": {
        "type": "string",
        "title": "LinkedIn",
        "validation": {
          "pattern": {
            "value": "^https?://([\\w-]+.)+([\\w-]+/?)\\S*$",
            "message": "Enter a valid LinkedIn URL"
          }
        }
      },
      "notes": {
        "type": "textarea",
        "title": "Notes"
      }
    }
  };

const ContactField = ({ label, h, form, fieldName, registerOptions, ...props }) => {
    const { openModal, closeModal } = useModal();

    const onCreateCallback = (inputValue, createTrigger) => {
            const handleSubmit = (data) => {
                createTrigger(data);
            };

            openModal('Contact', 
              <>
                  <DynamicForm config={config} onSubmit={handleSubmit} defaultValues={{name: inputValue}} />
              </>
            );
	}

	return (
		<SelectField form={form} fieldName={fieldName} label={label ?? 'Contact'} {...props} registerOptions={registerOptions} query={useGetContactsQuery} keyName={'name'} multiselect={true} valueName={'id'} onCreateCallback={onCreateCallback} createable={true} queryFilters={['name','email','phone']} mutation={useCreateContactMutation} />
	);
};

export default ContactField;
