/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG, toAbsoluteUrl } from 'src/_metronic/helpers'
import { PageTitle, useLayout, ILayout, getLayoutFromLocalStorage } from 'src/_metronic/layout/core'

import { Link, useLoaderData, useRouteLoaderData } from "react-router-dom"
import DataTable from 'src/app/components/DataTable'

import { skipToken } from "@reduxjs/toolkit/query"

import {
    ColumnDef,
    createColumnHelper
} from '@tanstack/react-table'

import { Avatar, Flex, Stack, Text, Button, Tag } from '@chakra-ui/react';
import { Toolbar } from 'src/_metronic/layout/components/toolbar/Toolbar'
import { ToolbarReports } from 'src/_metronic/layout/components/toolbar/toolbars'
import { Page } from 'src/app/components/Page'
import { PositionsChart } from 'src/app/components/charts/PositionsChart'
import { colors } from 'src/app/libs/config'
import Row from 'src/app/components/temp/Row'
import { Can } from 'src/app/routing/AppRoutes'
import DepartmentTag from 'src/app/components/Tags/DepartmentTag'
import StatusTag from 'src/app/components/Tags/StatusTag'
import { useGetPositionsQuery } from 'src/rtk/positions.api'
import RoundedProgressCard from 'src/app/components/charts/RoundedProgressCard'
import moment from "moment";
import { useDeleteJobCandidatesMutation, useGetCandidateJobsQuery, useGetCandidatesQuery, useGetJobCandidatesQuery, useDeleteJobMatchMutation } from 'src/rtk/drushim.api'
import ShowCandidateResume from '../drushim/ShowCandidateResume'
import { toast } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useInviteCandidateMutation } from 'src/rtk/candidates.api'
import MatchStatusInput from 'src/app/components/MatchStatusInput'

// import UploadResume from './UploadResume'
// import ShowCandidateResume from './ShowCandidateResume'
// import ManagePosition from './components/ManagePosition'


const columnHelper = createColumnHelper()

const breadCrumbs: Array<PageLink> = [

]


export const JobCandidatesPage = ({ }) => {
    const { job, candidates } = useRouteLoaderData('job');

    const [trigger, result] = useDeleteJobCandidatesMutation();

    const removeCandidates = () => {
        trigger({ jobId: job.id })
    }

    const [inviteCandidateTrigger, inviteResult] = useInviteCandidateMutation();

    const [deleteMatchTrigger, deleteMatchResult] = useDeleteJobMatchMutation();

    const inviteCandidate = (candidateId, matchId) => {
        inviteCandidateTrigger({ candidateId, jobId: job.id, matchId: matchId })
    }

    const deleteMatch = (matchId) => {
        deleteMatchTrigger({ matchId })
    }

    useEffect(() => {
        if (result?.isSuccess && !result?.isLoading) {
            toast.success("Candidates Removed", {
                position: toast.POSITION.TOP_RIGHT,
                theme: 'colored',
                pauseOnHover: false,
                pauseOnFocusLoss: false
            })
        }
    }, [result?.isLoading]);

    const colmns = [
        columnHelper.accessor('candidateName', {
            header: 'Candidate',
            cell: (info) => {
                return (
                    <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px me-5'>
                            <img
                                src={toAbsoluteUrl('/media/avatars/male-avatar.svg')}
                                className=''
                                alt=''
                            />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-semibold text-hover-primary mb-1 fs-6'>
                                {info.getValue()}
                            </a>
                            <span className='text-muted fw-medium text-muted d-block fs-7'>
                                {info.row.original.candidateTitle}
                            </span>
                        </div>
                    </div>
                )
            }
        }),
        // columnHelper.accessor("candidateEmail", {
        //     header: 'Email'
        // }),
        columnHelper.accessor("matchScore", {
            header: 'Qualification',
            cell: (info) => {
                return (
                    <span className="fw-semibold">{info.getValue()}</span>
                )
            }
        }),
        columnHelper.accessor("trueMatchScore", {
            header: 'Attractiveness',
            cell: (info) => {
                return (
                    <span className="fw-semibold">{info.getValue()}</span>
                )
            }
        }),
        columnHelper.accessor("overallScore", {
            header: 'Score',
            // cell: (info) => {
            //     return (
            //         <div className='progress w-100px w-xl-150px w-xxl-300px h-25px bg-light-primary'>
            //             <div
            //                 className='progress-bar rounded bg-primary fs-7 fw-bold'
            //                 role='progressbar'
            //                 style={{ width: (info.getValue() / 100 * 100).toFixed(0) + '%' }}
            //                 aria-valuenow={info.getValue()}
            //                 aria-valuemin={0}
            //                 aria-valuemax={100}
            //             >
            //                 {(info.getValue() / 100 * 100).toFixed(0)}%
            //             </div>
            //         </div>
            //         // <RoundedProgressCard value={info.getValue()} />
            //     )
            // }
            cell: (info) => {
                return (
                    <span className="badge badge-info pt-3 pb-3 fs-7 w-50px d-flex align-items-center justify-content-center p-3" style={{ 'display': 'ruby-text' }}>{info.getValue().toFixed(0)}</span>
                )
            }

        }),
        columnHelper.display({
            header: 'Status',
            cell: (info) => {
                return (
                    // <Tag colorScheme={info.row.original.overallScore > 80 ? 'green' : 'yello'}>
                    //     {info.row.original.overallScore > 80 ? 'Qualified' : 'Not Qualified'}
                    // </Tag>
                    // <span className={`badge badge-square badge-${info.row.original.overallScore > 80? 'success': 'warning'}`}> {info.row.original.overallScore > 80 ? 'Qualified' : 'Not Qualified'} </span>
                    <span className={`badge d-flex align-items-center justify-content-center fs-7 p-3 w-100px badge-${info.row.original.overallScore > 80 ? 'success' : 'secondary'}`}>
                        {info.row.original.overallScore > 80 ? 'Qualified' : 'Not Qualified'}
                    </span>
                )
            }
        }),
        columnHelper.display({
            header: 'Operative Status',
            cell: (info) => {
                return (
                    // <Tag colorScheme={info.row.original.overallScore > 80 ? 'green' : 'yello'}>
                    //     {info.row.original.overallScore > 80 ? 'Qualified' : 'Not Qualified'}
                    // </Tag>
                    // <span className={`badge badge-square badge-${info.row.original.overallScore > 80? 'success': 'warning'}`}> {info.row.original.overallScore > 80 ? 'Qualified' : 'Not Qualified'} </span>
                    <MatchStatusInput key={`status_${info.row.original.id}`} match={info.row.original} matchId={info.row.original.id} status={info.row.original.overallScore > 80 && info.row.original.status == 0 ? 100 : info.row.original.status} />
                )
            }
        }),
        columnHelper.display({
            header: 'Action',
            enableSorting: false,
            cell: (info) => {
                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%' // Ensures the parent div takes the full height of the viewport
                    }}>
                        <div style={{
                            display: 'inline-grid',
                            gridAutoFlow: 'column',
                            gap: '10px',
                            alignItems: 'center'
                        }}>
                            <Link key={info.row.original.candidateId} to={`report/${info.row.original.candidateId}`}>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={(props) => (
                                        <Tooltip id="button-tooltip" {...props}>
                                            Show Report
                                        </Tooltip>
                                    )}
                                >
                                    {/* <button className="btn btn-icon btn-bg-white btn-sm"><span className="svg-icon svg-icon-1"><img src={toAbsoluteUrl('/media/icons/qualifind/show-report.svg')} /></span></button> */}
                                    <button className="btn btn-icon btn-secondary btn-sm"><i className="fas fa-solid fa-list-check fs-6"></i></button>
                                </OverlayTrigger>
                            </Link>
                           
                            <ShowCandidateResume candidate={{ 'id': info.row.original.candidateId, 'name': info.row.original.candidateName }}>
                                {/* <button key={'button'} className="btn btn-icon btn-sm btn-bg-white"><span className="svg-icon svg-icon-1"><img src={toAbsoluteUrl('/media/icons/qualifind/show-resume.svg')} /></span></button> */}
                                <button key={'button'} className="btn btn-icon btn-sm btn-secondary"><i className="fa-regular fa-file-lines"></i></button>
                            </ShowCandidateResume>
                            
                            {/* <Link key={info.row.original.candidateId} to={`report/${info.row.original.candidateId}`}> */}
                            {/* <span className="svg-icon svg-icon-1"><img src={toAbsoluteUrl('/media/icons/qualifind/circle-up.svg')} /></span> */}
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={(props) => (
                                    <Tooltip id="button-tooltip" {...props}>
                                        Invite Candidate
                                    </Tooltip>
                                )}
                            >
                            <button key={'button'} className="btn btn-icon btn-info btn-sm" onClick={() => inviteCandidate(info.row.original.candidateId, info.row.original.id)}><i className="fa-regular fa-square-plus"></i></button>
                            </OverlayTrigger>

                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={(props) => (
                                    <Tooltip id="button-tooltip" {...props}>
                                        Unmatch
                                    </Tooltip>
                                )}
                            >
                                <button className="btn btn-icon btn-danger btn-sm" data-bs-toggle="modal"
                                    data-bs-target={`#kt_modal_${info.row.original.id}`}><i className="fas fa-solid fa-eraser fs-6"></i></button>
                            </OverlayTrigger>
                            <div className="modal fade" tabIndex={-1} id={`kt_modal_${info.row.original.id}`}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header bg-danger text-white">
                                            <h5 className="modal-title">Remove Match</h5>
                                            <div
                                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <KTSVG
                                                    path="media/icons/duotune/arrows/arr061.svg"
                                                    className="svg-icon svg-icon-2x"
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-body">
                                            <p>Are you sure you want to remove the match for {info.row.original.candidateName}?</p>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-light"
                                                data-bs-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                data-bs-dismiss="modal"
                                                onClick={() => deleteMatch(info.row.original.id)}
                                            >
                                                Yes, Remove
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    // <div style={{ display: 'inline-grid', gridAutoFlow: 'column', gap: '10px' }}>


                    //     {/* </Link> */}
                    // </div>
                )
            }
        }),
        // columnHelper.accessor("link", {
        //     header: 'Link',
        //     cell: (info) => {
        //         return (
        //             <a href={info.getValue()} target='_blank'>
        //                 {/* <Button size='sm' colorScheme='orange' color='white'>
        //                     Open
        //                 </Button> */}
        //                 <img src={toAbsoluteUrl('/media/logos/drushim.jpg')} style={{borderRadius: '5px', width: '80px'}}/>
        //             </a>
        //         )
        //     }
        // })
        // columnHelper.accessor('recruiterName', {
        //     header: 'Recruiter Name'
        // }),
        // columnHelper.accessor("departmentId", {
        //     header: 'Department',
        //     cell: (info) => {
        //         return (
        //             <DepartmentTag>{info.getValue()}</DepartmentTag>
        //         )
        //     },
        // }),
        // columnHelper.accessor('candidates', {
        //     header: 'Associated Candidates',
        //     cell: (info) => `${info.getValue().length}`,
        //     enableSorting: false
        // }),
        // columnHelper.accessor('engagement', {
        //     header: 'Engagement',
        //     cell: (info) => `${info.getValue()??0}%`,
        //     enableSorting: false
        // }),
        // columnHelper.accessor('status', {
        //     header: 'Job Status',
        //     cell: (info) => (
        //         <Tag colorScheme='blue'>
        //             {info.getValue()}
        //         </Tag>
        //     )
        // }),
        // columnHelper.accessor('id', {
        //     header: 'Details',
        //     enableSorting: false,
        //     cell: (info) => {
        //         return (
        //             <Link key={info.getValue()} to={info.getValue()}>
        //                 <Button size='sm' color='#A1A5B7'>
        //                     Go to Position
        //                 </Button>
        //             </Link>
        //         )
        //     }
        // })
    ];

    // console.log(candidate, jobs)


    return (
        <>
            <Page breadcrumbs={breadCrumbs} title={`${job.title}`} subheader={job.companyName} columns={colmns} dataSourceQueryFn={useGetJobCandidatesQuery} queryParams={{ jobId: job.id, page: 1, pageSize: 30 }}>
                <div key='toolbar'>
                    <a href={job.link ? job.link : `https://drushim.co.il/job/${job.externalId}/${job.externalSubId}/`} className="btn btn-info fs-6" target='_blank'><i className="bi bi-app-indicator fs-3 me-2"></i> Link to Job</a>
                </div>
                <div key={'card'}>

                </div>
            </Page>
        </>
    )
}


export default JobCandidatesPage