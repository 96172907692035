import React from 'react';
import { Button } from '@chakra-ui/react';

const WhiteButton = ({ children, ...props }) => {
	return (
		<Button
			colorScheme='green'
			{...props}
			h='34px'
			letterSpacing='-2%'>
			{children}
		</Button>
	);
};

export default WhiteButton;
