import {BaseLoader} from "../../common/base.loader"
import { assessmentApi } from '../../rtk/assessments.api'
import { positionApi } from "src/rtk/positions.api";
import { defer } from "react-router-dom";
import { departmentApi } from "src/rtk/departments.api";
import { examsApi } from "src/rtk/exams.api";
import { challengesApi } from "src/rtk/challenges.api";


export class ExamsLoader extends BaseLoader {
    getChallengeReport = async ({ request, params }) => {
        const getChallengeReportLoader = this._loader(
            challengesApi.endpoints.getReport,
            request,
            {
                examId: params.examId,
                challengeId: params.challengeId,
            }
        );

        const [ report ] = await Promise.all([getChallengeReportLoader])
        return { report: report }
    };


    getExamsPageData = async ({ request, params }) => {
        const getExamsLoader = this._loader(
            examsApi.endpoints.getExams,
            request, 
            {
                page: 1,
                pageSize: 10
            }
        );

        const getClonedExamsLoader = this._loader(
            examsApi.endpoints.getClones,
            request,
            {
                
            }
        );

        const getAssessmentsDepartmentsLoader = this._loader(
            departmentApi.endpoints.getAssessmentDepartments,
            request
        );

        const getOverviewLoader = this._loader(
            examsApi.endpoints.getOverview,
            request
        );

        const [ exams, copies, departments, overview ] = await Promise.all([getExamsLoader, getClonedExamsLoader, getAssessmentsDepartmentsLoader, getOverviewLoader])
        return { exams, copies, departments, overview }
    };

    getExamPageData = async ({ request, params }) => {
        const getExamLoader = this._loader(
            examsApi.endpoints.getExams,
            request, 
            {
                ids: params.assessmentTemplateId
            }
        );

        const getClonedExamsLoader = this._loader(
            examsApi.endpoints.getClones,
            request, 
            {
                page: 1,
                pageSize: 10,
                parentExamId: params.assessmentTemplateId
            }
        );

        const getAssessmentsDepartmentsLoader = this._loader(
            departmentApi.endpoints.getAssessmentDepartments,
            request
        );

        const getExamOverviewLoader = this._loader(
            examsApi.endpoints.getClonesOverview,
            request,
            {
                parentExamId: params.assessmentTemplateId
            }
        );

        const [ exam, exams, departments, overview ] = await Promise.all([getExamLoader, getClonedExamsLoader, getAssessmentsDepartmentsLoader, getExamOverviewLoader])
        return { exam, exams, departments, overview }
    };
}