import { colors } from "src/app/libs/config";
import {
	Box,
	Button,
	ButtonGroup,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverFooter,
	PopoverHeader,
	PopoverTrigger,
	Spacer,
	Stack,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { IoInformation } from "react-icons/io5";
import ReactMarkdown from "react-markdown"
import * as FaIcons from 'react-icons/fa';
import * as CgIcons from 'react-icons/cg';
import * as IoIcons from 'react-icons/io';
import * as BsIcons from 'react-icons/bs';
import { KTCard, KTCardBody } from "src/_metronic/helpers";


const ALL_ICONS = {
	...FaIcons,
	...CgIcons,
	...IoIcons,
	...BsIcons
};



const GenericPopover = ({ data, iconName, children }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const initialFocusRef = React.useRef();

	const renderIconByName = (iconName) => {
		const IconComponent = ALL_ICONS[iconName];
		return IconComponent ? <IconComponent onClick={onOpen} cursor={'pointer'} size='24' color={colors.textLG} /> : null;
	};

	const Icon = iconName ? renderIconByName(iconName) : <AiFillInfoCircle onClick={onOpen} cursor={'pointer'} size='24' color={colors.textLG} />;

	useEffect(() => { }, [isOpen]);
	return (
		<Popover
			initialFocusRef={initialFocusRef}
			placement='bottom'
			closeOnBlur={true}
			returnFocusOnClose={false}
			isOpen={isOpen}
			onClose={onClose}
			sx={{
				backgroundColor: 'var(--bs-body-bg)'
			}}
		>
			<PopoverTrigger>
				<div >
					{iconName ? (
						<>
							{Icon}
						</>
					) : (
						<>
							<AiFillInfoCircle onClick={onOpen} cursor={'pointer'} size='24' color={colors.textLG} />
						</>
					)}
				</div>
			</PopoverTrigger>
			<PopoverContent mt='16px' w='400px' role='tooltip' p={2} sx={{
				backgroundColor: 'var(--bs-body-bg)'
			}}>
				<PopoverHeader sx={{
					backgroundColor: 'var(--bs-body-bg)'
				}}
					pt={4}
					fontWeight='semibold'
					// borderBottom={`1px solid ${colors.placeholder}`}
					borderBottom="none"
					color={colors.text}
					alignItems='center'
				>
					<PopoverCloseButton size='lg' mr="18px" />
				</PopoverHeader>
				<PopoverBody p={3} maxH='600px' overflowY='auto' sx={{
					backgroundColor: 'var(--bs-body-bg)',
					color: 'var(--bs-gray-900)'
				}}>
					{children ? children : (
						<ReactMarkdown>{data}</ReactMarkdown>
					)}
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

export default GenericPopover;
