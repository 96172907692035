import { colors } from 'src/app/libs/config';
import { Text } from '@chakra-ui/react';
import React from 'react';

const Label = ({ children, ...props }) => {
	return (
		<Text fontSize='12px' fontWeight='600' {...props}>
			{children}
		</Text>
	);
};

export default Label;
